import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class CarbohydrateCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { carbohydrateDetail, t } = this.props;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("carbohydrate")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("total_carbohydrate")} (g)</Label>
                                <NumberFormat className="form-control" name="totalCarbohydrate" onChange={(e) => this.handleChange(e, "carbohydrateDetail")} value={carbohydrateDetail.totalCarbohydrate} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("monosaccharide")} (g)</Label>
                                <NumberFormat className="form-control" name="monosaccharide" onChange={(e) => this.handleChange(e, "carbohydrateDetail")} value={carbohydrateDetail.monosaccharide} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("fibre")} (g)</Label>
                                <NumberFormat className="form-control" name="fibre" onChange={(e) => this.handleChange(e, "carbohydrateDetail")} value={carbohydrateDetail.fibre} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={5}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("lactose")} (g)</Label>
                                <NumberFormat className="form-control" name="lactose" onChange={(e) => this.handleChange(e, "carbohydrateDetail")} value={carbohydrateDetail.lactose} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("polysaccharide")} (g)</Label>
                                <NumberFormat className="form-control" name="polysaccharide" onChange={(e) => this.handleChange(e, "carbohydrateDetail")} value={carbohydrateDetail.polysaccharide} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

CarbohydrateCard.propTypes = {
    carbohydrateDetail: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default withTranslation()(CarbohydrateCard);
