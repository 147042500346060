import React from "react";
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, Row, Col, Button, Input } from "reactstrap";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import DraggableModal from '../../components/modal/DraggableModal'
// Redux
import { connect } from 'react-redux';
import { getDiagnoses, deleteDiagnosis, searchDiagnosis } from '../../redux/actions/diagnosisActions';
// Util
import { hasRole } from "util/hasRole";

class DiagnosisList extends React.Component {

    emptyDiagnosis = {
        code: '',
        name: '',
        latinName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            diagnosis: null,
            diagnosisToDelete: this.emptyDiagnosis,
            diagnosisSearch: "",
            isLoading: true,
            importModal: false,
            selectedFile: null,
            isLoadingImport: false,
            deleteDiagnosisModal: false
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.getDiagnoses(0, 10);
        this.setState({ isLoading: false });
    }

    onChangePage = (page, size) => {
        if (this.state.diagnosisSearch !== "") {
            this.props.searchDiagnosis(page - 1, this.props.diagnosisData.size, this.state.diagnosisSearch);
        }
        else {
            this.props.getDiagnoses(page - 1, this.props.diagnosisData.size);
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if (this.state.diagnosisSearch !== "") {
            this.props.searchDiagnosis(currentPage - 1, currentRowsPerPage, this.state.diagnosisSearch);
        } else {
            this.props.getDiagnoses(currentPage - 1, currentRowsPerPage);
        }

    }

    openDeleteDialog = (row) => {
        this.setState({ deleteDiagnosisModal: !this.state.deleteDiagnosisModal, diagnosisToDelete: row, });

    }

    closeDialog = () => {
        this.setState({ deleteDiagnosisModal: !this.state.deleteDiagnosisModal });
    }

    deleteDiagnosis = () => {
        this.props.deleteDiagnosis(this.toastManager, this.state.diagnosisToDelete.id);
        this.setState({ deleteDiagnosisModal: false });
    }

    handleDiagnosisSearch = (event) => {
        this.setState({ diagnosisSearch: event.target.value });
    }

    openDialogImport = () => {
        this.setState({ importModal: !this.state.importModal });
    }

    closeFileDialog = () => {
        this.setState({ importModal: !this.state.importModal, selectedFile: null });
    }

    handleFileUpload = (event) => {
        this.setState({
            selectedFile: event.target.files[0]
        })
    }

    handleFileSubmit = () => {
        const data = new FormData()
        data.append('MKB-10', this.state.selectedFile)
        this.setState({ isLoadingImport: true })

        axios.post('/v1/diagnosis/import', data)
            .then(response => {
                this.setState({
                    importModal: false,
                    selectedFile: null,
                    isLoadingImport: false
                })
                this.props.getDiagnoses(0, this.props.diagnosisData.size)
            })
            .catch(error => {
                this.setState(
                    () => this.toastManager.add("Error " + error.response.status + " " + error.response.statusText, {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                    }));
            })
    }


    render() {
        const { diagnoses, totalElements } = this.props.diagnosisData;
        const { deleteDiagnosisModal, diagnosisToDelete, isLoading, isLoadingImport, importModal } = this.state;
        const { t } = this.props;
        const { credentials } = this.props.auth

        const deleteModal = <DraggableModal
            isOpen={deleteDiagnosisModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_diagnosis")}</strong>}
            body={<div>
                {t("delete_diagnosis_message")}{' '}<strong>{diagnosisToDelete.name}</strong>{' ?'}
            </div>}
            footer={<Button color="success" onClick={this.deleteDiagnosis}>{t("delete")}</Button>}
        />

        const diagnosisColumns = [
            {
                name: t("code"),
                selector: "code",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "10%" : "10%"
            },
            {
                name: t("name"),
                selector: "name",
                width: hasRole('ROLE_SUPER_ADMIN') ? "35%" : "42%",
                cell: row => <span>
                    {row.name}
                </span>
            },
            {
                name: t("latin_name"),
                selector: "latinName",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "25%" : "33%",
                cell: row => <span>
                    {row.latinName}
                </span>
            }
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "15%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const actions = {
            name: t("actions"),
            width: "15%",
            center: true,
            cell: row => <span>
                {(row.createdBy && row.createdBy.username === credentials.username) || hasRole("ROLE_SUPER_ADMIN") ? <div>
                    <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/diagnosis/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                    <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDeleteDialog(row)}><i className="tim-icons icon-simple-remove"></i></Button></div> : null}
            </span>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            diagnosisColumns.push(createdBy)
            diagnosisColumns.push(actions)
        } else {
            diagnosisColumns.push(actions)
        }

        const importFileModal = <DraggableModal
            isOpen={importModal}
            toggle={this.closeFileDialog}
            size={"sm"}
            title={<strong>{t("import_file")}</strong>}
            body={<Row>
                <Col md={12}>
                    <Input type="file" id="file" onChange={this.handleFileUpload} accept=".json"></Input><br />
                    <Row>
                        <Col><Button style={{ width: '131px', height: '40px' }} className="button" disabled={this.state.selectedFile === null || isLoadingImport} color="success" type="button" onClick={this.handleFileSubmit}>
                            {isLoadingImport ? <ClipLoader
                                size={20}
                                color={"#e14eca"}
                                loading={isLoadingImport}
                            /> : t("upload")}
                        </Button></Col>
                    </Row>
                </Col>
            </Row>}
        />

        return (
            <div className="content">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col sm={12} md={4}>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_diagnosis")} autoComplete="off" name="diagnosisSearch" id="diagnosisSearch" value={this.state.diagnosisSearch || ''} onChange={this.handleDiagnosisSearch}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.state.diagnosisSearch === "" ? this.props.getDiagnoses(0, 10) : this.props.searchDiagnosis(0, this.props.diagnosisData.size, this.state.diagnosisSearch)
                                                }
                                            }} />
                                    </Col>
                                    <Col xs={1} sm={1}>
                                        <Button color="success" size="sm" type="button" onClick={this.state.diagnosisSearch === "" ? () => this.props.getDiagnoses(0, 10) : () => this.props.searchDiagnosis(0, this.props.diagnosisData.size, this.state.diagnosisSearch)}><i className="fas fa-search"></i></Button>
                                    </Col>
                                    <Col sm={5} md={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/diagnosis" color="success">{t("create_diagnosis")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={diagnosisColumns}
                                        data={diagnoses}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                            {deleteModal}
                            {importFileModal}
                        </Card>
                        <div>
                            <Button color="warning" size="sm" type="button" onClick={() => this.openDialogImport()}><i className="tim-icons icon-cloud-upload-94"></i> {t("import_file")}</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

DiagnosisList.propTypes = {
    diagnosisData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    diagnosisData: state.diagnosisData,
    auth: state.auth
})

const mapActionsToProps = { getDiagnoses, deleteDiagnosis, searchDiagnosis }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(DiagnosisList)));