import React from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import { Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavLink, Nav, Container, Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { hasRole } from '../../util/hasRole';
import { withToastManager } from 'react-toast-notifications';
// Redux
import { connect } from 'react-redux';
import { logoutUser, changeCompany, changeAdminPassword } from '../../redux/actions/authActions';
import { getCompaniesForUser } from '../../redux/actions/companyActions';
import ReactFlagsSelect from 'react-flags-select';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../modal/DraggableModal'


class AdminNavbar extends React.Component {

  emptyChangePassword = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      country: localStorage.getItem('i18nextLng').toUpperCase(),
      openModal: false,
      company: null,
      changePasswordModal: false,
      changePassword: this.emptyChangePassword,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      passwordValid: false,
      passwordValidationMessage: '',
      error: undefined
    };
    this.toastManager = props.toastManager;
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    //if (window.innerWidth < 993 && this.state.collapseOpen) {
    if (window.innerWidth < 1201 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white"
      });
    } else {
      this.setState({
        color: "navbar-transparent"
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent"
      });
    } else {
      this.setState({
        color: "bg-white"
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };

  handleChange = (e) => {
    const target = e.target;
    let changePassword = { ...this.state.changePassword };
    changePassword[target.name] = target.value;
    this.setState({ changePassword: changePassword });
  }

  validPassword(currentPassword, newPassword, confirmNewPassword) {
    if (newPassword === undefined || newPassword.length === 0) { return this.props.t("new_password_required") }
    if (newPassword !== confirmNewPassword) { return this.props.t("passwords_dont_match") };
    if (currentPassword === undefined || currentPassword.length === 0) { return this.props.t("current_password_required") };
    return "";
  }

  validateLoginForm() {
    let { passwordValid, passwordValidationMessage, changePassword } = this.state;

    passwordValidationMessage = this.validPassword(changePassword.currentPassword, changePassword.newPassword, changePassword.confirmNewPassword);
    passwordValid = passwordValidationMessage.length > 0 ? false : true;

    this.setState({
      error: undefined,
      passwordValid: passwordValid,
      passwordValidationMessage: passwordValidationMessage,
    });
    if (!passwordValid) {
      return false;
    }
    return true;
  }

  changePasswordAdmin = () => {
    if (!this.validateLoginForm()) {
      return;
    }
    this.props.changeAdminPassword(this.toastManager, this.state.changePassword, this.props.history)
  }

  handleChangeLanguage = (languageCountry) => {
    this.props.i18n.changeLanguage(languageCountry.toLowerCase())
  }

  handleLogout = () => {
    this.props.logoutUser();
  }

  openCompanyModal = () => {
    hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
    this.setState({ openModal: true })
  }

  closeCompanyModal = () => {
    this.setState({ openModal: false })
  }

  closeChangePasswordModal = () => {
    this.setState({ changePasswordModal: false, changePassword: this.emptyChangePassword })
  }

  handleChangeCompany = (companyEntry) => {
    let company = { ...this.state.company };

    if (companyEntry.length > 0) {
      company = companyEntry[0];
    } else {
      company = null;
    }
    this.setState({ company: company })
  }

  render() {
    const { openModal, company, changePasswordModal, changePassword, passwordValid, passwordValidationMessage } = this.state;
    const { companiesForUser } = this.props.companyData
    const { t } = this.props;

    const companyChangeModal = <DraggableModal
      isOpen={openModal}
      toggle={this.closeCompanyModal}
      size={"sm"}
      title={<strong>{t("select_company")}</strong>}
      body={<Row>
        <Col md={12}>
          <FormGroup>
            <Typeahead
              selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
              id="company"
              clearButton
              labelKey="name"
              onChange={this.handleChangeCompany}
              options={companiesForUser}
              placeholder={t("select_company")}
              paginationText={t("display_additional")}
              emptyLabel={t("no_matches_found")}
            />
          </FormGroup>
          <FormGroup>
            <Button size="sm" color="success" disabled={this.props.credentials.company === company} onClick={() => this.props.changeCompany(company.id, this.props.history)}>{t("change")}</Button>
          </FormGroup>
        </Col>
      </Row>}
    />

    const changePasswordAdminModal = <DraggableModal
      isOpen={changePasswordModal}
      toggle={this.closeChangePasswordModal}
      size={"sm"}
      title={<strong>{t("change_password")}</strong>}
      body={<>
        <Row>
          <Col sm={9} md={6} lg={6} xl={9} className={'col-xxl-8 col-wxl-9'}>
            <FormGroup className={'mw-350'}>
              <Label>{t("current_password")}</Label>
              <Input
                name="currentPassword"
                type={this.state.showCurrentPassword ? 'text' : 'password'}
                onChange={this.handleChange}
                placeholder={t("current_password")}
                value={changePassword.currentPassword || ''}
                autoComplete="off"
              />
            </FormGroup>
          </Col>
          <Col sm={9} md={6} lg={6} xl={3} className={'col-xxl-4 col-wxl-3'}>
            <br />
            <Button style={{ marginTop: '10px' }} className="btn-icon btn-round" size="sm" color="success" onClick={() => this.setState({ showCurrentPassword: this.state.showCurrentPassword ? false : true })}>{this.state.showCurrentPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Button>
          </Col>
        </Row>
        <Row>
          <Col sm={9} md={6} lg={6} xl={9} className={'col-xxl-8 col-wxl-9'}>
            <FormGroup className={'mw-350'}>
              <Label>{t("new_password")}</Label>
              <Input
                name="newPassword"
                type={this.state.showNewPassword ? 'text' : 'password'}
                onChange={this.handleChange}
                placeholder={t("new_password")}
                value={changePassword.newPassword || ''}
                autoComplete="off"
              />
            </FormGroup>
          </Col>
          <Col sm={9} md={6} lg={6} xl={3} className={'col-xxl-4 col-wxl-3'}>
            <br />
            <Button style={{ marginTop: '10px' }} className="btn-icon btn-round" size="sm" color="success" onClick={() => this.setState({ showNewPassword: this.state.showNewPassword ? false : true })}>{this.state.showNewPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Button>
          </Col>
        </Row>
        <Row>
          <Col sm={9} md={6} lg={6} xl={9} className={'col-xxl-8 col-wxl-9'}>
            <FormGroup className={'mw-350'}>
              <Label>{t("confirm_new_password")}</Label>
              <Input
                name="confirmNewPassword"
                type={this.state.showConfirmNewPassword ? 'text' : 'password'}
                onChange={this.handleChange}
                placeholder={t("confirm_new_password")}
                value={changePassword.confirmNewPassword || ''}
                autoComplete="off"
              />
            </FormGroup>
            {!passwordValid && <span className="form-input-error">{passwordValidationMessage}</span>}
          </Col>
          <Col sm={9} md={6} lg={6} xl={3} className={'col-xxl-4 col-wxl-3'}>
            <br />
            <Button style={{ marginTop: '10px' }} className="btn-icon btn-round" size="sm" color="success" onClick={() => this.setState({ showConfirmNewPassword: this.state.showConfirmNewPassword ? false : true })}>{this.state.showConfirmNewPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button size="sm" color="success" onClick={() => this.changePasswordAdmin()}>{t("change")}</Button>
          </Col>
        </Row>
      </>
      }
      modalStyle={{ maxWidth: '450px' }}
    />


    return (
      <>
        <Navbar
          className={classNames("navbar-fixed", this.state.color)}
          expand="xl"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand>
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>

            <Collapse navbar isOpen={this.state.collapseOpen}>
              <div className="ml-auto">

                <ReactFlagsSelect
                  defaultCountry={this.state.country}
                  countries={["GB", "HR"]}
                  customLabels={{ "GB": "EN", "HR": "HR" }}
                  showSelectedLabel={false}
                  onSelect={this.handleChangeLanguage}
                />

                <Nav navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle
                      caret
                      color="default"
                      data-toggle="dropdown"
                      nav
                      onClick={e => e.preventDefault()}
                    >
                      <div className="photo" style={{ width: '35px', height: '35px' }}>
                        <img alt="..." src={require("assets/img/logoUser.png")} />
                      </div>
                      <b className="caret d-none d-lg-block d-xl-block" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-navbar" right tag="ul">
                      <DropdownItem disabled style={{ color: 'rgba(0,0,0,0.87)' }}><strong>{t("username")}:</strong> {this.props.credentials.username ? this.props.credentials.username : ''}</DropdownItem>
                      <DropdownItem disabled style={{ color: 'rgba(0,0,0,0.87)' }}><strong>{t("email")}:</strong> {this.props.credentials.email ? this.props.credentials.email : ''}</DropdownItem>
                      {hasRole("ROLE_SUPER_ADMIN") && <NavLink style={{ paddingLeft: '15px' }} tag="li">
                        <Button style={{ maxWidth: '100px' }} size="sm" color="success" tag={Link} to={"/admin/user/" + this.props.credentials.username}>{t("edit")}</Button>
                      </NavLink>}

                      {hasRole("ROLE_ADMIN") && <NavLink tag="li">
                        <Button /* style={{ maxWidth: '100px' }} */ size="sm" color="success" onClick={() => this.setState({ changePasswordModal: true })}>{t("change_password")}</Button>
                      </NavLink>}
                      {hasRole("ROLE_SUPER_ADMIN") && <DropdownItem divider tag="li" />}
                      {hasRole("ROLE_SUPER_ADMIN") && <NavLink tag="li">
                        <DropdownItem disabled style={{ color: 'rgba(0,0,0,0.87)' }}><strong>{t("company")}:</strong> {this.props.credentials.company ? this.props.credentials.company : ''}</DropdownItem>
                        <DropdownItem className="nav-item" onClick={this.openCompanyModal}>{t("change_company")}</DropdownItem>
                      </NavLink>}
                      <DropdownItem divider tag="li" />
                      <NavLink tag="li">
                        <DropdownItem className="nav-item" onClick={this.handleLogout}>{t("log_out")}</DropdownItem>
                      </NavLink>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <li className="separator d-lg-none" />
                </Nav>
              </div>
            </Collapse>
            {companyChangeModal}
            {changePasswordAdminModal}
          </Container>
        </Navbar>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  credentials: state.auth.credentials,
  role: state.auth.role,
  companyData: state.companyData
})

AdminNavbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  companyData: PropTypes.object.isRequired
}

export default connect(mapStateToProps, { logoutUser, changeCompany, getCompaniesForUser, changeAdminPassword })(withToastManager(withTranslation()(AdminNavbar)));
