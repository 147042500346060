import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, protectedRoute, authenticated, isLoading, ...rest }) =>
    (
        isLoading ? null :
        <Route
            {...rest}
            render={(props) => protectedRoute ? (
                authenticated === true ? <Component {...props} /> : <Redirect to='/' />
            ) : (
                authenticated === true ? <Redirect to='/admin' /> : <Component {...props} />
            )}
        />
    )

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated,
    isLoading: state.UI.isLoading
});

AuthRoute.propTypes = {
    auth: PropTypes.object,
    UI: PropTypes.object
}

export default connect(mapStateToProps)(AuthRoute);