import { SET_DIETS, CREATE_DIET, DELETE_DIET, SET_NUTRIENT_TYPES, DELETE_DIET_INFO, SET_DIET_WAS_DELETED, SET_COPY_DIET_LOADING , SET_DIET_DELETE_MODAL} from '../types';

const initialState = {
    diets: [],
    size: 10,
    totalElements: 0,
    nutrientTypes: [],
    dietInfo: [],
    dietWasDeleted: false,
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false,
    deleteDietModal: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DIETS:
            return {
                ...state,
                diets: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_DIET:
            return {
                ...state,
                diets: [
                    action.payload,
                    ...state.diets
                ]
            }
        case DELETE_DIET:
            let index = state.diets.findIndex((diet) => diet.id === action.payload)
            state.diets.splice(index, 1);
            return {
                ...state
            }
        case DELETE_DIET_INFO:
            return {
                ...state,
                dietInfo: action.payload
            }
        case SET_NUTRIENT_TYPES: {
            return {
                ...state,
                nutrientTypes: action.payload
            }
        }
        case SET_DIET_WAS_DELETED: {
            return {
                ...state,
                dietWasDeleted: action.payload
            }
        }
        case SET_COPY_DIET_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        case SET_DIET_DELETE_MODAL:
            return {
                ...state,
                deleteDietModal : action.payload
            }
        default:
            return state;
    }
}
