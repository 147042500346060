import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import CarbohydrateCard from '../../components/food/CarbohydrateCard';
import EnergyCard from '../../components/food/EnergyCard';
import FatCard from '../../components/food/FatCard';
import ProteinCard from '../../components/food/ProteinCard';
import MineralCard from '../../components/food/MineralCard';
import VitaminCard from '../../components/food/VitaminCard';
import AlcoholCard from '../../components/food/AlcoholCard';
import InfoCard from '../../components/food/InfoCard';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Alert, Button, Card, FormGroup, Form, Input, Row, Col, Label, CardHeader, CardBody, InputGroup, InputGroupText, CardTitle } from "reactstrap";
import axios from 'axios';
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
import NumberFormat from 'react-number-format';
// Redux
import { connect } from 'react-redux';
import { createFood, updateFood, updateFoodApproved, getCategories, getPreparationTypes, getENumbers, getUnitsOfMeasurement } from '../../redux/actions/foodActions';
// Util
import { hasRole } from '../../util/hasRole';

class Food extends React.Component {

    emptyFood = {
        name: '',
        category: '',
        ediblePart: 0,
        waterContent: 0,
        sourceOfInformation: '',
        preparationType: '',
        manufacturer: '',
        defaultUnitOfMeasurement: null,
        gramsByPiece: 0,
        eNumbers: [],
        sweeteners: '',
        approved: null,
        energyDetail: {
            kilojoules: 0,
            kcal: 0
        },
        carbohydrateDetail: {
            monosaccharide: 0,
            polysaccharide: 0,
            fibre: 0,
            lactose: 0,
            totalCarbohydrate: 0
        },
        mineralDetail: {
            na: 0,
            k: 0,
            ca: 0,
            mg: 0,
            p: 0,
            fe: 0,
            zn: 0,
            cu: 0,
            cl: 0,
            mn: 0,
            cr: 0,
            mo: 0,
            f: 0,
            sulphates: 0,
            carbonates: 0,
            se: 0,
            i: 0
        },
        fatDetail: {
            saturated: 0,
            monounsaturated: 0,
            polyunsaturated: 0,
            linoleicAcid: 0,
            cholesterol: 0,
            totalFat: 0
        },
        proteinDetail: {
            plant: 0,
            animal: 0,
            phenylalanine: 0,
            purine: 0,
            histamine: 0,
            totalProtein: 0
        },
        vitaminDetail: {
            retinol: 0,
            betaCarotene: 0,
            d: 0,
            d3: 0,
            e: 0,
            b1: 0,
            b2: 0,
            b3: 0,
            b5: 0,
            b6: 0,
            b7: 0,
            b9: 0,
            b12: 0,
            c: 0,
            k: 0,
        },
        alcoholDetail: {
            ethanol: 0
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            food: this.emptyFood,
            initialFood: this.emptyFood,
            approvedModal: false
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.getFoodById(this.props.match.params.id);
        this.props.getCategories();
        this.props.getENumbers();
        this.props.getUnitsOfMeasurement();
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let food = { ...this.state.food };
        food[name] = value;

        this.setState({ food: food });
    }

    handleChangeCategory = (category) => {
        let food = { ...this.state.food };

        if (category.length > 0) {
            food.category = category[0].categoryEnum;
        } else {
            food.category = '';
        }
        this.setState({ food: food })
    }

    handleChangeENumbers = (numbers) => {
        let food = { ...this.state.food };

        if (numbers.length <= 0) {
            food.eNumbers = [];
        } else {
            food.eNumbers = numbers;
        }
        this.setState({ food: food });
    }

    handleChangeDetails = (e, detailObjectName) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        let food = { ...this.state.food };
        let detailObject = food[detailObjectName];
        if (name === "kilojoules") {
            detailObject["kilojoules"] = Number(value);
            detailObject["kcal"] = Number(value) * 0.239006;
        } else if (name === "kcal") {
            detailObject["kcal"] = Number(value);
            detailObject["kilojoules"] = Number(value) * 4.184;
        } else {
            detailObject[name] = value;
        }
        food[detailObjectName] = detailObject
        this.setState({ food: food });
    }

    async getFoodById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/food/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    let food = {
                        ...response.data.food,
                        ediblePart: response.data.food.ediblePart * 100
                    }
                    let initialFood = {
                        ...response.data.food,
                        ediblePart: response.data.food.ediblePart * 100
                    }
                    this.setState({
                        food: food,
                        initialFood: JSON.parse(JSON.stringify(initialFood))
                    });
                })
                .catch(error => {
                    this.toastManager.add(this.props.t("get_food_by_id_error"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                    });
                })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { food } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialFood: this.state.food });
        let foodData = {
            ...food,
            ediblePart: food.ediblePart / 100
        }

        if (this.props.match.params.id) {
            this.props.updateFood(this.toastManager, this.props.match.params.id, foodData, this.props.history);
        } else {
            this.props.createFood(this.toastManager, foodData, this.props.history);
        }
    }

    closeApprovedModalDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleApprovedDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    handleSubmitApproved = (e) => {
        e.preventDefault();
        const { food } = this.state;

        Promise.all([this.props.updateFoodApproved(this.props.match.params.id, !food.approved, this.toastManager)])
            .then(() => {
                this.setState({ approvedModal: false })
                this.getFoodById()
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    render() {

        const { food, initialFood, approvedModal } = this.state;
        const { categories, eNumbers, unitsOfMeasurement } = this.props.foodData;
        const { t } = this.props;

        const disabledButton = food.name && food.category && food.defaultUnitOfMeasurement

        let unitOfMeasurementOptions;
        unitOfMeasurementOptions = unitsOfMeasurement.map((unitOfMeasurement, key) => {
            return <option value={unitOfMeasurement.unitOfMeasurement} key={key}>{t(unitOfMeasurement.unitOfMeasurement)}</option>
        })

        let message = food.approved ? t("unapprove_message") + food.name + "? " + t("reset_message") : t("approve_message") + food.name + "? " + t("reset_message")
        const changeApprovedModal = <DraggableModal
            isOpen={approvedModal}
            toggle={this.closeApprovedModalDialog}
            size={"sm"}
            title={<strong>{t("change_approved")}</strong>}
            body={message}
            footer={<div>
                <Button color="warning" onClick={this.closeApprovedModalDialog}>{t("no")}</Button>{' '}
                <Button color="success" onClick={this.handleSubmitApproved}>{t("yes")}</Button>
            </div>}
            className={this.props.className}
        />

        let foodCategoryOptions = categories.map(category => {
            return {
                categories: t(category),
                categoryEnum: category
            }
        })
        const withValueCap = (inputObj) => {
            const { value } = inputObj;
            if (value <= 100) return true;
            return false;
        };

        return (
            <>
                <div className="content content-food">
                    <Prompt when={JSON.stringify(food) !== JSON.stringify(initialFood)} message={t("warning_message")} />
                    {(hasRole('ROLE_STUDENT') && food.approved) && <Row>
                        <Col md={12}>
                            <Alert color="warning">{t("food_approved_message")}</Alert>
                        </Col>
                    </Row>}
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col sm={10} md={12} lg={10} xl={10} className={'col-xxl-9 col-wl-8 col-wxl-7'}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col style={{ textAlign: 'left' }}>
                                                <CardTitle tag="h3"><strong>{t("food")}</strong></CardTitle>
                                            </Col>
                                            <Col style={{ textAlign: 'right' }}>
                                                <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md={5}>
                                                <FormGroup className={'mw-320'}>
                                                    <Label>{t("name")}</Label>
                                                    <Input
                                                        name="name"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        placeholder={t("name")}
                                                        value={food.name}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>

                                                <FormGroup className={'mw-320'}>
                                                    <Label>{t("food_category")}</Label>
                                                    <Typeahead
                                                        selected={foodCategoryOptions && foodCategoryOptions.filter(c => c.categoryEnum === food.category)}
                                                        id="category"
                                                        clearButton
                                                        labelKey="categories"
                                                        onChange={this.handleChangeCategory}
                                                        options={foodCategoryOptions}
                                                        placeholder={t("select_food_category")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={'mw-250'}>
                                                    <Label>{t("source_of_information")}</Label>
                                                    <Input
                                                        type="text"
                                                        name="sourceOfInformation"
                                                        value={food.sourceOfInformation}
                                                        placeholder={t("source_of_information")}
                                                        onChange={this.handleChange}>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup className={'mw-180'}>
                                                    <Label>{t("edible_part")}</Label>
                                                    <InputGroup>
                                                        <NumberFormat className="form-control" name="ediblePart" onChange={this.handleChange} value={food.ediblePart} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} isAllowed={withValueCap} />
                                                        <InputGroupText><strong>%</strong></InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup className={'mw-180'}>
                                                    <Label>{t("water_content")}</Label>
                                                    <InputGroup>
                                                        <NumberFormat className="form-control" name="waterContent" onChange={this.handleChange} value={food.waterContent} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                                                        <InputGroupText><strong>g</strong></InputGroupText>
                                                    </InputGroup>
                                                </FormGroup>
                                                <FormGroup className={'mw-350'}>
                                                    <Label>{t("manufacturer")}</Label>
                                                    <Input
                                                        name="manufacturer"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        value={food.manufacturer || ''}
                                                        placeholder={t("manufacturer")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup className={'mw-250'}>
                                                    <Label>{t("unit_of_measurement")}</Label>
                                                    <Input
                                                        type="select"
                                                        name="defaultUnitOfMeasurement"
                                                        value={food.defaultUnitOfMeasurement === null ? t("select_unit") : food.defaultUnitOfMeasurement}
                                                        onChange={this.handleChange}>
                                                        <option disabled>{t("select_unit")}</option>
                                                        {unitOfMeasurementOptions}
                                                    </Input>
                                                </FormGroup>
                                                <FormGroup className={'mw-180'}>
                                                    {food.defaultUnitOfMeasurement === "PIECE" && <Label>{t("grams_by_piece")}</Label>}
                                                    {food.defaultUnitOfMeasurement === "PIECE" && <Input type="number" name="gramsByPiece" placeholder={t("grams_by_piece")} min={0} value={food.gramsByPiece} onChange={this.handleChange}></Input>}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className={'mt-3'}>
                                                    {this.props.match.params.id ?
                                                        <Button disabled={(JSON.stringify(food) === JSON.stringify(initialFood))} type="submit" color="success"> {t("update")}</Button> :
                                                        <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>}
                                                    {(hasRole('ROLE_SUPER_ADMIN') && this.props.match.params.id) &&
                                                        (food.approved ? <Button type="button" color="warning" onClick={this.toggleApprovedDialog}>{t("unapprove")}</Button>
                                                            : <Button type="button" color="info" onClick={this.toggleApprovedDialog}>{t("approve")}</Button>)}
                                                    {changeApprovedModal}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className={'mt-3'}>
                                <h2>{t("macronutrients")}</h2>
                            </Col>
                            <Col xl={12} className={'col-xxl-11 col-wl-10 col-wxl-8'}>
                                <Row className={'even-height-row'}>
                                    <Col sm={6}>
                                        <CarbohydrateCard carbohydrateDetail={food.carbohydrateDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                    <Col sm={6}>
                                        <EnergyCard energyDetail={food.energyDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                    <Col sm={6} >
                                        <FatCard fatDetail={food.fatDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                    <Col sm={6}>
                                        <ProteinCard proteinDetail={food.proteinDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className={'mt-3'}>
                                <h2>{t("micronutrients")}</h2>
                            </Col>
                            <Col xl={12} className={'col-xxl-11 col-wl-10 col-wxl-8'}>
                                <Row className={'even-height-row'}>
                                    <Col sm={6}>
                                        <MineralCard mineralDetail={food.mineralDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                    <Col sm={6}>
                                        <VitaminCard vitaminDetail={food.vitaminDetail} handleChange={this.handleChangeDetails} />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className={'mt-3'}>
                                <h2>{t("other")}</h2>
                            </Col>
                            <Col sm={6} className={'col-xxl-3 col-wxl-2'}>
                                <AlcoholCard alcoholDetail={food.alcoholDetail} handleChange={this.handleChangeDetails} />
                            </Col>
                            <Col sm={6} className={'col-xxl-5 col-wxl-4'}>
                                <InfoCard sweeteners={food.sweeteners} eNumbersInput={food.eNumbers} handleChange={this.handleChange} eNumbers={eNumbers} handleChangeENumbers={this.handleChangeENumbers} />
                            </Col>
                        </Row>
                    </Form>
                </div>
            </>
        );
    }
}

Food.propTypes = {
    foodData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    foodData: state.foodData
})

const mapActionsToProps = { createFood, updateFood, updateFoodApproved, getCategories, getPreparationTypes, getENumbers, getUnitsOfMeasurement }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Food)))