import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Card, FormGroup, Input, Row, Col, Label, InputGroup, InputGroupText, CardBody, CardHeader, CardTitle, Table, UncontrolledTooltip } from "reactstrap";
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../modal/DraggableModal'
import PerfectScrollbar from 'react-perfect-scrollbar'
import NumberFormat from 'react-number-format';

class UserDetailCard extends Component {

    emptyNewMetaData = {
        id: null,
        timestamp: null,
        height: '',
        weight: '',
        waistCircumference: '',
        hipCircumference: ''
    }

    state = {
        newMetadata: this.emptyNewMetaData,
        initialMetaData: this.emptyNewMetaData,
        editMetaData: {},
        metadataModalOpen: false,
        deleteModalOpen: false,
        indexToDelete: -1,
        editModalOpen: false,
        waistIsValid: true
    }

    deleteMetadata = (index) => {
        this.props.deleteMetadata(index);
        this.setState({
            deleteModalOpen: false,
            indexToDelete: -1
        })
    }

    toggleLineGraph = () => {
        this.props.toggleLineGraph();
    }

    addNewMetadata = () => {
        this.props.addNewMetadata(this.state.newMetadata);
        this.toggleMetadataModal();
        this.setState({ newMetadata: this.state.initialMetaData })
    }

    editMetadata = () => {
        this.props.editMetaData(this.state.editMetaData);
        this.setState({ editModalOpen: !this.state.editModalOpen, editMetaData: {} })
    }

    handleChangeNewMetadata = (e) => {
        let { newMetadata, waistIsValid } = this.state;
        let { user } = this.props

        if (e.target.name === "waistCircumference") {
            if (user.sex === "MALE") {
                if (e.target.value === '' || e.target.value === 0 || (e.target.value >= 94 && e.target.value <= 102)) {
                    waistIsValid = true;
                } else { waistIsValid = false; }
            }
            else {
                if (e.target.value === '' || e.target.value === 0 || (e.target.value >= 80 && e.target.value <= 88)) {
                    waistIsValid = true;
                } else { waistIsValid = false; }
            }
        }
        newMetadata[e.target.name] = e.target.value;
        this.setState({ newMetadata: newMetadata, waistIsValid: waistIsValid });
    }

    handleChangeEditMetadata = (e) => {
        let { editMetaData } = this.state
        editMetaData[e.target.name] = Number(e.target.value)
        this.setState({ editMetaData: editMetaData });
    }

    handleChangeNewMetadataTimestamp = (date) => {
        this.setState({ newMetadata: { ...this.state.newMetadata, timestamp: date } });
    }

    toggleMetadataModal = () => {
        this.setState({ metadataModalOpen: !this.state.metadataModalOpen })
    }

    toggleMetadataModalEdit = (userMetadata, index) => {
        this.setState({ editModalOpen: !this.state.editModalOpen, editMetaData: userMetadata, editableIndex: index })
    }

    toggleDeleteModal = (index) => {
        this.setState({
            deleteModalOpen: !this.state.deleteModalOpen,
            indexToDelete: index
        })
    }

    toggleEditable = (index) => {
        this.setState({ editableIndex: index, editModalOpen: !this.state.editModalOpen });
    }

    render() {

        const { metadata, t, user, initialUser } = this.props;
        const { newMetadata, metadataModalOpen, deleteModalOpen, indexToDelete, editModalOpen, editMetaData } = this.state;

        const disabledAddButton = user.sex !== "" && user.dateOfBirth
        const addButtonDisabled = newMetadata.timestamp === null || newMetadata.height <= 0 || newMetadata.weight <= 0
        const years = moment(user.dateOfBirth).diff(moment(), 'years')
        const age = Math.abs(years)
        let tableData = metadata && metadata.map((userMetadata, index) => {

            this.calculateBmi = (bmiIndex, weightCategoryFromBmi) => {
                if (weightCategoryFromBmi === "UNDERWEIGHT") {
                    return <div><Input style={{ background: "linear-gradient(269deg, orange 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'orange' }}> {t("underweight")}</b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "NORMAL_WEIGHT") {
                    return <div><Input style={{ background: "linear-gradient(269deg, green 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'green' }}> {t("normal_weight")}</b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "OVERWEIGHT") {
                    return <div><Input style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}>{t("overweight")} </b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "OBESITY") {
                    return <div><Input style={{ background: "linear-gradient(269deg, #E5383B 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: '#E5383B' }}>{t("obesity")} </b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "OBESITY_STAGE_I") {
                    return <div><Input style={{ background: "linear-gradient(269deg, #E5383B 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: '#E5383B' }}>{t("obesity_stage_I")} </b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "OBESITY_STAGE_II") {
                    return <div><Input style={{ background: "linear-gradient(269deg, #BA181B 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"><b style={{ color: '#BA181B' }}>{t("obesity_stage_II")} </b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "OBESITY_STAGE_III") {
                    return <div><Input style={{ background: "linear-gradient(269deg, #A4161A 0%, #ffffff 15%)" }} disabled name={"bmi"} value={bmiIndex + " kg/m\u00B2"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"> <b style={{ color: '#A4161A' }}>{t("obesity_stage_III")} </b></UncontrolledTooltip></div>
                }
                else if (weightCategoryFromBmi === "UNKNOWN") {
                    return <div><Input disabled name={"bmi"} value={0} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBMI" + index} className="uTooltip uTooltip-input"> <b style={{ color: 'red' }}>{t("wrong_parametars")} </b></UncontrolledTooltip></div>
                }
            }

            this.calculateEnergyNeeds = (energyNeeds) => {
                return <div><Input disabled style={{ background: "white" }} name={"energyNeeds"} value={energyNeeds} />
                    <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"energyNeeds" + index} className="uTooltip uTooltip-input"><b style={{ color: 'grey' }}> {t("energy_needs_details")}</b></UncontrolledTooltip></div>
            }

            this.calculateWHR = (WHR, whrCategory) => {
                if (whrCategory === "LOW" && WHR === 0) {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("no_values")}</b></UncontrolledTooltip></div>
                } else if (whrCategory === "LOW") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, grey 0%, #ffffff 15%)" }} name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b> {t("low")}</b></UncontrolledTooltip></div>
                } else if (whrCategory === "MEDIUM") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #f5b52c 0%, #ffffff 15%)" }} name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b style={{ color: '#f5b52c' }}> {t("medium")}</b></UncontrolledTooltip></div>
                } else if (whrCategory === "HIGH") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #f58a2c 0%, #ffffff 15%)" }} name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b style={{ color: '#f58a2c' }}> {t("high")}</b></UncontrolledTooltip></div>
                } else if (whrCategory === "VERY_HIGH") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("very_high")}</b></UncontrolledTooltip></div>
                } else if (whrCategory === "UNKNOWN") {
                    return <div><Input disabled name={"WHR"} value={WHR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHR" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("not_processed")}</b></UncontrolledTooltip></div>
                }
            }

            this.calculateWhtR = (WHtR, whtrCategory) => {
                if (whtrCategory === "LOW" && WHtR === 0) {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} name={"WHtR"} value={WHtR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHtR" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("no_values")}</b></UncontrolledTooltip></div>
                }
                else if (whtrCategory === "LOW") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #fac170 0%, #ffffff 15%)" }} name={"WHtR"} value={WHtR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHtR" + index} className="uTooltip uTooltip-input"><b style={{ color: '#fac170' }}> {t("low")}</b></UncontrolledTooltip></div>
                }
                else if (whtrCategory === "INCREASED") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #faa070 0%, #ffffff 15%)" }} name={"WHtR"} value={WHtR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHtR" + index} className="uTooltip uTooltip-input"><b style={{ color: '#faa070' }}> {t("increased")}</b></UncontrolledTooltip></div>
                }
                else if (whtrCategory === "HIGH") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #fa7070 0%, #ffffff 15%)" }} name={"WHtR"} value={WHtR} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHtR" + index} className="uTooltip uTooltip-input"><b style={{ color: '#fa7070' }}> {t("high")}</b></UncontrolledTooltip></div>
                }
                else {
                    return <div><Input disabled name={"WHtR"} value={0} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetWHtR" + index} className="uTooltip uTooltip-input"> <b style={{ color: 'red' }}>{t("wrong_parametars")} </b></UncontrolledTooltip></div>
                }
            }

            this.calculateBAI = (BAI, baiCategory) => {
                if (baiCategory === "UNDERWEIGHT" && BAI === 0) {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} name={"BAI"} value={BAI + " %"} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("no_values")}</b></UncontrolledTooltip></div>
                }
                else if (baiCategory === "UNDERWEIGHT") { return <div><Input disabled style={{ background: "linear-gradient(269deg, orange 0%, #ffffff 15%)" }} name={"BAI"} value={BAI + " %"} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'orange' }}> {t("underweight")}</b></UncontrolledTooltip></div> }
                else if (baiCategory === "NORMAL_WEIGHT") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, green 0%, #ffffff 15%)" }} name={"BAI"} value={BAI + " %"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'green' }}> {t("normal_weight")}</b></UncontrolledTooltip></div>
                }
                else if (baiCategory === "OVERWEIGHT") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, #cccc12 0%, #ffffff 15%)" }} name={"BAI"} value={BAI + " %"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: '#cccc12' }}> {t("overweight")}</b></UncontrolledTooltip></div>
                }
                else if (baiCategory === "OBESITY") {
                    return <div><Input disabled style={{ background: "linear-gradient(269deg, red 0%, #ffffff 15%)" }} name={"BAI"} value={BAI + " %"} />
                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("obesity")}</b></UncontrolledTooltip></div>
                }
                else if (baiCategory === "UNKNOWN") {
                    return <div><Input disabled name={"BAI"} value={BAI + " %"} /><UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"targetBAI" + index} className="uTooltip uTooltip-input"><b style={{ color: 'red' }}> {t("not_processed")}</b></UncontrolledTooltip></div>
                }
            }

            return <tr key={index}>
                <td><Input disabled style={{ background: "white" }} type="text" name={"timestamp"} value={moment(userMetadata.timestamp).format("DD.MM.yyyy.")} /></td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"targetBMI" + index}>
                        {this.calculateBmi(userMetadata.bmi, userMetadata.weightCategoryFromBmi)}
                    </span>
                </td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"energyNeeds" + index}>
                        {this.calculateEnergyNeeds(userMetadata.energyNeeds)}
                    </span>
                </td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"targetWHR" + index}>
                        {this.calculateWHR(userMetadata.whr, userMetadata.whrCategory)}
                    </span>
                </td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"targetWHtR" + index}>
                        {this.calculateWhtR(userMetadata.whtr, userMetadata.whtrCategory)}
                    </span>
                </td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"targetBAI" + index}>
                        {this.calculateBAI(userMetadata.bai, userMetadata.weightCategoryFromBai)}
                    </span>
                </td>
                <td>
                    <span className={"action-button"}>
                        <span id={"info" + index}>
                            <Button className="btn-icon btn-round" type="button" color="primary" size="sm" ><i className="fas fa-info"></i></Button>{' '}
                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"info" + index} className="uTooltip uTooltip-info">
                                <p>{t("height")}: <span style={{ color: 'red', fontWeight: 'bold' }}>{userMetadata.height}</span></p>
                                <p>{t("weight")}: <span style={{ color: 'red', fontWeight: 'bold' }}>{userMetadata.weight}</span></p>
                                <p>{t("waist_circumference")}: <span style={{ color: 'red', fontWeight: 'bold' }}>{userMetadata.waistCircumference}</span></p>
                                <p>{t("hip_circumference")}: <span style={{ color: 'red', fontWeight: 'bold' }}>{userMetadata.hipCircumference}</span></p>
                            </UncontrolledTooltip>
                        </span>
                        <Button className="btn-icon btn-round" type="button" color="info" size="sm" disabled={!initialUser.company} onClick={() => this.toggleMetadataModalEdit(userMetadata, index)}><i className="tim-icons icon-settings-gear-63"></i></Button>{' '}
                        <Button className="btn-icon btn-round" type="button" color="warning" size="sm" onClick={() => this.toggleDeleteModal(index)}><i className="tim-icons icon-simple-delete"></i></Button>
                    </span>
                </td>
            </tr>
        })

        const metadataModal = <DraggableModal
            isOpen={metadataModalOpen}
            toggle={this.toggleMetadataModal}
            size={"sm"}
            title={<strong>{t("metadata_entry")}</strong>}
            body={<Row>
                <Col>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("timestamp")}</Label>
                        <DatePicker
                            className={"form-control"}
                            selected={newMetadata.timestamp}
                            onChange={this.handleChangeNewMetadataTimestamp}
                            dateFormat="DD.MM.yyyy."
                            showMonthDropdown
                            timeCaption="Time"
                            showYearDropdown
                            minDate={user.dateOfBirth}
                            maxDate={moment()}
                            dropdownMode="select"
                            placeholderText={t("timestamp")}
                        />
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("height")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="height" placeholder={t("height")} onChange={this.handleChangeNewMetadata} value={newMetadata.height} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("weight")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="weight" placeholder={t("weight")} onChange={this.handleChangeNewMetadata} value={newMetadata.weight} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>kg</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("waist_circumference")}{' '}
                            <span id={"infoButton"}>
                                <Button className="btn-icon btn-round" type="button" color="primary" size="sm" ><i className="fas fa-info"></i></Button>{' '}
                                <UncontrolledTooltip style={{ fontSize: '12px' }} placement="right" target={"infoButton"} className="uTooltip uTooltip-info">
                                    {user.sex === "MALE" ? <p> {t("waist_range")}<span style={{ color: 'red', fontWeight: 'bold' }}>{t("waist_range_male")}</span></p> : <p> {t("waist_range")}<span style={{ color: 'red', fontWeight: 'bold' }}>{t("waist_range_female")}</span></p>}
                                </UncontrolledTooltip>
                            </span>
                        </Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="waistCircumference" placeholder={t("waist_circumference")} onChange={this.handleChangeNewMetadata} value={newMetadata.waistCircumference} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1}/>
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                        {!this.state.waistIsValid && <span style={{ color: 'red', fontSize: '12px' }}>{t("waist_range_error")}</span>}
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("hip_circumference")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="hipCircumference" placeholder={t("hip_circumference")} onChange={this.handleChangeNewMetadata} value={newMetadata.hipCircumference} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>}
            footer={
                <Button disabled={addButtonDisabled} color="success" onClick={this.addNewMetadata}>{t("add")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}

        />

        const deleteModal = <DraggableModal
            isOpen={deleteModalOpen}
            toggle={this.toggleDeleteModal}
            size={"sm"}
            title={<strong>{t("delete_metadata")}</strong>}
            body={<span>{t("alert_message_metadata_delete")}?</span>}
            footer={
                <Button color="danger" onClick={() => this.deleteMetadata(indexToDelete)}>{t("delete")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        const editModal = <DraggableModal
            isOpen={editModalOpen}
            toggle={this.toggleMetadataModalEdit}
            size={"sm"}
            title={<strong>{t("edit_metadata")}</strong>}
            body={<Row>
                <Col>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("height")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="height" placeholder={t("height")} onChange={this.handleChangeEditMetadata} value={editMetaData.height} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("weight")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="weight" placeholder={t("weight")} onChange={this.handleChangeEditMetadata} value={editMetaData.weight} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>kg</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("waist_circumference")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="waistCircumference" placeholder={t("waist_circumference")} onChange={this.handleChangeEditMetadata} value={editMetaData.waistCircumference} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className={'mw-200'}>
                        <Label>{t("hip_circumference")}</Label>
                        <InputGroup>
                            <NumberFormat className="form-control" name="hipCircumference" placeholder={t("hip_circumference")} onChange={this.handleChangeEditMetadata} value={editMetaData.hipCircumference} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            <InputGroupText><strong>cm</strong></InputGroupText>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>}

            footer={
                <Button disabled={(JSON.stringify(user) === JSON.stringify(this.props.initialUser))} color="success" onClick={this.editMetadata}>{t("add")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col>
                            <CardTitle tag="h3">
                                <strong>{t("metadata")}</strong>
                            </CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button disabled={!disabledAddButton} className="btn-icon btn-round" type="button" color="success" size="sm" onClick={() => this.toggleMetadataModal()}>
                                <i className="fas fa-plus"></i>
                            </Button>
                            <Button disabled={metadata && metadata.length === 0} className="btn-icon btn-round" type="button" color="success" size="sm" onClick={() => this.toggleLineGraph()}>
                                <i className="fas fa-chart-line"></i>
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="meta-table">
                    {metadata && metadata.length > 0 &&
                        <PerfectScrollbar style={{ height: 'auto', maxHeight: '422px' }}>
                            <Table className="tableFixHead">
                                <thead>
                                    <tr>
                                        <th width="20%" style={{ textAlign: 'center' }}>{t("timestamp")}</th>
                                        {/* Body Mass Index */}

                                        {age >= 18 ? <React.Fragment><th width="19%" id={"target"} style={{ textAlign: 'center' }}>BMI</th>
                                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"target"} className="uTooltip uTooltip-title">
                                                {t("bmi")}
                                            </UncontrolledTooltip></React.Fragment> :
                                            <React.Fragment><th width="19%" id={"target"} style={{ textAlign: 'center' }}>BMI</th>
                                                <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"target"} className="uTooltip uTooltip-title">
                                                    {t("bmi_kids")}
                                                </UncontrolledTooltip></React.Fragment>}

                                        {/* Energy needs */}
                                        <th width="15%" id={"target2"} style={{ textAlign: 'center' }} >{t("energy_needs")}</th>
                                        {/* Waist-hip ratio  */}
                                        <th width="8%" id={"target3"} style={{ textAlign: 'center' }}>WHR</th>
                                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"target3"} className="uTooltip uTooltip-title">
                                            {t("WHR")}
                                        </UncontrolledTooltip>
                                        {/* Waist-height ratio  */}
                                        <th width="8%" id={"target4"} style={{ textAlign: 'center' }}>WHtR</th>
                                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"target4"} className="uTooltip uTooltip-title">
                                            {t("WHtR")}
                                        </UncontrolledTooltip>
                                        {/* Hip circumference to height ratio  */}
                                        <th width="15%" id={"targetBAI"} style={{ textAlign: 'center' }}>BAI</th>
                                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"targetBAI"} className="uTooltip uTooltip-title">
                                            {t("BAI")}
                                        </UncontrolledTooltip>
                                        <th width="16%">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData}
                                </tbody>
                            </Table>
                        </PerfectScrollbar>}
                </CardBody>
                {metadataModal}
                {deleteModal}
                {editModal}
            </Card>
        )
    }
}

UserDetailCard.propTypes = {
    user: PropTypes.object.isRequired,
    initialUser: PropTypes.object.isRequired,
    metadata: PropTypes.array,
    handleChangeMetadata: PropTypes.func.isRequired,
    addNewMetadata: PropTypes.func.isRequired,
    editMetaData: PropTypes.func.isRequired,
    deleteMetadata: PropTypes.func.isRequired,
    toggleLineGraph: PropTypes.func
}

export default withTranslation()(UserDetailCard)
