import { SET_USER_SESSIONS } from '../types';

const initialState = {
    sessions: [],
    isLoading: true
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_SESSIONS:
            return {
                ...state,
                sessions: action.payload,
                isLoading: false
            }
        default:
            return state;
    }
}
