import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Card, Form, FormGroup, Row, Col, Table, Button, Label, Input, CardHeader, CardBody, CardTitle, Badge, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { Line } from 'react-chartjs-2';
import { Typeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import DatePicker from 'react-datepicker';
import UserDetailsCard from '../../components/user/UserDetailsCard';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
import DataTable from 'react-data-table-component';
import { Pie, Bar } from 'react-chartjs-2';
// Redux
import { connect } from 'react-redux';
import { getCompaniesForUser } from '../../redux/actions/companyActions';
import { updateUser, getDiets, getRoles, getDiagnoses, createUser, getUserMenus, getAllergens, getFodmaps } from '../../redux/actions/userActions';
import { getDishNormatives, getMealNormatives } from '../../redux/actions/menuEditorActions';
import { getExportUserMenus, getExportUserMenu, getExportUser } from '../../redux/actions/reportsActions';
// Util
import { hasRole } from "util/hasRole";

const inactiveStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff #ffffff #e3e3e3'
};

const activeStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#e3e3e3 #e3e3e3 #ba54f5'
};


class User extends React.Component {

    emptyUser = {
        active: false,
        firstName: '',
        lastName: '',
        role: '',
        username: '',
        email: '',
        password: '',
        diet: null,
        dateOfBirth: null,
        phone: '',
        sex: '',
        note: '',
        personsMultiplier: 1,
        diagnosisNote: '',
        diagnoses: [],
        metadata: [],
        company: null,
        allergenId: [],
        fodmapId: []
    }

    constructor(props) {
        super(props);
        let roleName = ''
        if (props.location.state)
            roleName = props.location.state.roleName
        this.state = {
            user: this.emptyUser,
            initialUser: this.emptyUser,
            modalDietOpen: false,
            dietExists: false,
            validEmail: false,
            diagnosisToAdd: [],
            diagnosisExists: false,
            modalDiagnosisOpen: false,
            lineGraph: false,
            userDetailsTable: true,
            roleName: roleName || '',
            diagnosisNoteOpen: false,
            infoModalDish: false,
            infoModalMeal: false,
            mealName: '',
            dishName: '',
            startDate: null,
            endDate: null,
            openExportModal: false,
            macronutrientData: {
                macronutrientChartData: {},
                macronutrientBarChart: {}
            },
            activeTab: '1',
            customizedExportDialog: false,
            allergenToAdd: [],
            allergenExists: false,
            fodmapToAdd: [],
            fodmapExists: false
        }
        this.toastManager = props.toastManager;
        this.passwordGenerator = require('generate-password')
    }

    async componentDidMount() {
        this.props.getDiets();
        this.props.getRoles();
        this.props.getDiagnoses();
        this.props.getAllergens();
        this.props.getFodmaps();
        if (this.props.match.params.username === undefined) { this.props.userData.userMenus = [] }
        this.getUserByUsername();
        hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
    }

    onChangePage = (page, size) => {
        this.props.getUserMenus(page - 1, this.props.userData.sizeUserMenus, this.state.user.username);
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        this.props.getUserMenus(currentPage - 1, currentRowsPerPage, this.state.user.username);
    }

    toggleModalDiet = () => {
        this.setState({ modalDietOpen: !this.state.modalDietOpen });
    }

    generatePassword = () => {
        let user = { ...this.state.user };

        user.password = this.passwordGenerator.generate({
            length: 14,
            numbers: true
        });

        this.setState({ user: user })
    }

    toggleDiagnosisOpen = () => {
        this.setState({ modalDiagnosisOpen: !this.state.modalDiagnosisOpen });
    }

    toggleAddDiagnosisNote = () => {
        this.setState({ diagnosisNoteOpen: !this.state.diagnosisNoteOpen });
    }

    toggleLineGraph = () => {
        this.setState({ lineGraph: !this.state.lineGraph, userDetailsTable: !this.state.userDetailsTable })
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        let user = { ...this.state.user };
        if (name === "personsMultiplier") { user.personsMultiplier = Number(value) }
        else { user[name] = value; }
        if (name === "role") {
            user.role = value
            this.setState({ roleName: '' })
        } else { user[name] = value; }

        this.setState({ user: user });
        this.testEmail(user.email);
    }

    handleChangeDateBirth = (date) => {
        this.setState({ user: { ...this.state.user, dateOfBirth: date } });
    }

    addNewMetadata = (newMetadata) => {
        let { user } = this.state

        const requestBody = {
            sex: user.sex,
            dateOfBirth: user.dateOfBirth.format('YYYY-MM-DD'),
            timestamp: newMetadata.timestamp.format('YYYY-MM-DD'),
            height: newMetadata.height,
            weight: newMetadata.weight,
            waistCircumference: newMetadata.waistCircumference,
            hipCircumference: newMetadata.hipCircumference
        }

        axios.post(`/v1/user/metadata`, requestBody)
            .then(response => {
                let dataObject = {
                    ...response.data,
                    timestamp: newMetadata.timestamp.format('YYYY-MM-DD'),
                    height: newMetadata.height,
                    weight: newMetadata.weight,
                    waistCircumference: newMetadata.waistCircumference,
                    hipCircumference: newMetadata.hipCircumference
                }
                user.metadata.push(dataObject)
                this.setState({ user: user })
            })
            .catch(error => {
                this.toastManager.add(this.props.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })

            })
    }

    editMetaData = (newMetadata) => {
        let { user } = this.state;
        user.metadata.push(newMetadata)
        this.setState({ user: user })

        const metadata = user.metadata.map(userMetadata => {
            return {
                ...userMetadata,
                timestamp: moment(userMetadata.timestamp).format('YYYY-MM-DD')
            }
        })
        const requestBody = {
            ...user,
            dateOfBirth: user.dateOfBirth === null ? null : user.dateOfBirth.format('YYYY-MM-DD'),
            metadata: metadata,
            firstName: user.firstName.trim(),
            lastName: user.lastName.trim(),
            username: user.username.trim(),
            email: user.email.trim(),
            company: user.company.id,
            diet: user.diet === '' ? null : user.diet
        }

        axios.patch(`/v1/user/${encodeURI(this.props.match.params.username)}`, requestBody)
            .then(response => {
                this.toastManager.add(this.props.t("user_updated_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                });
                this.getMetaData()
            })
            .catch(error => {
                if (error.response.data.code === 1000) {
                    this.toastManager.add(this.props.t("already_exists_username"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                    })
                } else {
                    this.toastManager.add(this.props.t("unable_perform_action"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                    })
                }
            })
    }


    handleChangeMetadata = (e, index) => {
        let user = { ...this.state.user };
        user.metadata[index][e.target.name] = e.target.value;
        this.setState({ user: user });
    }

    deleteMetadata = (index) => {
        let { user } = this.state;

        user.metadata.splice(index, 1);

        this.setState({ user: user });
    }

    handleChangeDiet = (diet) => {
        let user = { ...this.state.user };

        if (diet.length > 0) {
            user.diet = diet[0];
        } else {
            user.diet = '';
        }
        this.setState({ user: user })
    }

    handleChangeDiagnosis = (diagnoses) => {
        const { user } = this.state;
        let diagnosisToAdd = { ...this.state.diagnosisToAdd };

        let diagnosisExists = false;
        if (user.diagnoses.length > 0 && diagnoses.length > 0) {
            user.diagnoses.forEach(d => {
                if (d.id === diagnoses[0].id)
                    diagnosisExists = true;
            })
        }

        if (!diagnoses[0]) {
            this.setState({ diagnosisToAdd: [] })
            return;
        }
        diagnosisToAdd = diagnoses;


        this.setState({ diagnosisToAdd: diagnosisToAdd, diagnosisExists: diagnosisExists });
    }

    addDiagnosis = () => {
        let { diagnosisToAdd, user } = this.state;
        diagnosisToAdd.forEach(diagnosis => {
            user.diagnoses.push(diagnosis)
        })
        this.setState({ user: user, diagnosisToAdd: [] })
        this.toggleDiagnosisOpen();
    }

    removeDiagnosis = (index) => {
        let { user } = this.state;

        user.diagnoses.splice(index, 1);

        this.setState({ user: user });
    }

    handleChangeAllergen = (allergen) => {
        const { user } = this.state;
        let allergenToAdd = { ...this.state.allergenToAdd };

        let allergenExists = false;
        if (user.allergenId && user.allergenId.length > 0 && allergen.length > 0) {
            user.allergenId.forEach(d => {
                if (d.id === allergen[0].id)
                    allergenExists = true;
            })
        }
        if (!allergen[0]) {
            this.setState({ allergenToAdd: [] })
            return;
        }
        allergenToAdd = allergen;

        let array = []
        allergenToAdd.forEach(a => {
            array.push(a.id)
        })
        user.allergenId = array

        this.setState({ allergenToAdd: allergenToAdd, allergenExists: allergenExists, user: user });
    }

    handleChangeFodmap = (fodmap) => {
        const { user } = this.state;
        let fodmapToAdd = { ...this.state.fodmapToAdd };

        let fodmapExists = false;
        if (user.fodmapId && user.fodmapId.length > 0 && fodmap.length > 0) {
            user.fodmapId.forEach(d => {
                if (d.id === fodmap[0].id)
                    fodmapExists = true;
            })
        }
        if (!fodmap[0]) {
            this.setState({ fodmapToAdd: [] })
            return;
        }
        fodmapToAdd = fodmap;

        let array = []
        fodmapToAdd.forEach(a => {
            array.push(a.id)
        })
        user.fodmapId = array

        this.setState({ fodmapToAdd: fodmapToAdd, fodmapExists: fodmapExists, user: user });
    }

    testEmail(email) {
        if (email === undefined || email.length === 0) return false;
        let emailregex = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailregex.test(email)) this.setState({ validEmail: true });
        else this.setState({ validEmail: false });
    }

    handleChangeRole = (role) => {
        let { user } = this.state;
        user.role = role === undefined ? [] : this.props.t(role[0]);

        this.setState({ user: user })
    }

    async getUserByUsername() {
        if (this.props.match.params.username !== undefined) {
            await axios.get(`/v1/user/${encodeURI(this.props.match.params.username)}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    response.data.user.diagnosisNote === null || response.data.user.diagnosisNote === "" ? this.setState({ diagnosisNoteOpen: false }) : this.setState({ diagnosisNoteOpen: true })
                    let allergens = []
                    response.data.user.allergenIdList.map(allergen => {
                        let t = this.props.userData.allergens.find(a => a.id === allergen)

                        return allergens.push(t)
                    })
                    let fodmaps = []
                    response.data.user.fodmapIdList !== null && response.data.user.fodmapIdList.map(fodmap => {
                        let t = this.props.userData.fodmaps.find(f => f.id === fodmap)
                        return fodmaps.push(t)
                    })
                    let user = {
                        ...response.data.user,
                        dateOfBirth: response.data.user.dateOfBirth === null ? null : moment(response.data.user.dateOfBirth)
                    }

                    this.testEmail(response.data.user.email)
                    this.setState({
                        user: user,
                        initialUser: JSON.parse(JSON.stringify(user)),
                        allergenToAdd: allergens,
                        fodmapToAdd: fodmaps
                    });
                    this.getMetaData()
                    this.props.getUserMenus(0, 10, response.data.user.username)

                })
                .catch(error => {
                    if (error.response.data.code === 400) {
                        this.props.history.push("/admin/dashboard")
                    } else {
                        this.setState(
                            () => this.toastManager.add(this.props.t("get_client_by_username_error"), {
                                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                            }));
                    }
                })
        }
    }

    async getMetaData() {
        if (this.props.match.params.username !== undefined) {
            await axios.get(`/v1/user/${encodeURI(this.props.match.params.username)}/metadata`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    let arrayMetaData = []
                    response.data.metadata.forEach(data => {
                        arrayMetaData.push(data)
                    })
                    let user = {
                        ...this.state.user,
                        metadata: arrayMetaData
                    }
                    this.setState({
                        user: user,
                        initialUser: JSON.parse(JSON.stringify(user))
                    });
                })
                .catch(error => {
                    this.setState(
                        () => this.toastManager.add(this.props.t("get_client_by_username_error") + error.response + " " + error.response, {
                            appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                        }));
                })
        }
    }

    handlePrintPdf = (macronutrients, id) => {
        const macronutrientData = {
            macronutrientRatio: {
                carbohydrateRatio: macronutrients && macronutrients.carbohydrateRatio,
                fatRatio: macronutrients && macronutrients.fatRatio,
                proteinRatio: macronutrients && macronutrients.proteinRatio
            },
            macronutrientChartData: {
                labels: [
                    this.props.t('carbohydrate'),
                    this.props.t('fat'),
                    this.props.t('protein')
                ],
                datasets: [{
                    data: [
                        macronutrients && macronutrients.carbohydratesKcal,
                        macronutrients && macronutrients.fatKcal,
                        macronutrients && macronutrients.proteinKcal,
                    ],
                    backgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ],
                    hoverBackgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ]
                }]
            },
            macronutrientBarChart: {
                labels: [
                    this.props.t('ratio')
                ],
                datasets: [{
                    label: this.props.t("monosaccharide") + " / " + this.props.t("polysaccharide") + ", " + this.props.t("value") + ": " + macronutrients.carbohydrateRatio,
                    backgroundColor: '#FCF955',
                    borderColor: ['#FCF955'],
                    data: [macronutrients.carbohydrateRatio]
                }, {
                    label: this.props.t("saturated") + " / " + this.props.t("unsaturated") + " " + this.props.t("fatty_acid") + ", " + this.props.t("value") + ": " + macronutrients.fatRatio,
                    backgroundColor: '#F76A6E',
                    borderColor: ['#F76A6E'],
                    data: [macronutrients.fatRatio]
                }, {
                    label: this.props.t("animal") + " / " + this.props.t("plant") + " " + this.props.t("proteins") + ", " + this.props.t("value") + ": " + macronutrients.proteinRatio,
                    backgroundColor: '#68A2EB',
                    borderColor: ['#68A2EB'],
                    data: [macronutrients.proteinRatio]
                }],
                options: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            fontColor: "green",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            },
        }
        this.setState({ macronutrientData: macronutrientData }, () => {
            setTimeout(() => { this.print(id) }, 1000)
        })
    }

    print = (id) => {
        const canvasSave = document.getElementById('chart')
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "")
        const canvasSaveBar = document.getElementById('bar')
        const img64Bar = canvasSaveBar.toDataURL('image/png')
        const base64ImgBar = img64Bar.replace("data:image/png;base64,", "")

        const request = {
            menuId: id,
            username: this.state.user.username,
            image: base64Img,
            imageBarChart: base64ImgBar
        }
        this.props.getExportUserMenu(request, this.toastManager)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let { user } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialUser: user });

        const metadata = user.metadata.map(userMetadata => {
            return {
                ...userMetadata,
                timestamp: moment(userMetadata.timestamp).format('YYYY-MM-DD')
            }
        })
        const requestBody = {
            ...user,
            dateOfBirth: user.dateOfBirth === null ? null : user.dateOfBirth.format('YYYY-MM-DD'),
            metadata: metadata,
            firstName: user.firstName.trim(),
            lastName: user.lastName.trim(),
            username: user.username.trim(),
            email: user.email.trim(),
            diet: user.diet === '' ? null : user.diet,
            company: user.company === null ? null : user.company.id,
            role: this.state.roleName === '' ? user.role : this.state.roleName
        }

        if (this.props.match.params.username) {
            this.props.updateUser(this.toastManager, encodeURI(this.props.match.params.username), requestBody, this.props.history);
        } else {
            this.props.createUser(this.toastManager, requestBody, this.props.history);
        }

    }

    handleChangeCompany = (company) => {
        let user = { ...this.state.user };

        if (company.length > 0) {
            user.company = company[0];
        } else {
            user.company = null;
        }
        this.setState({ user: user })
    }

    exportToPdf = () => {
        this.props.exportUserData()
    }

    openInfoModalDish = (id, name) => {
        this.props.getDishNormatives(id, this.toastManager)
        this.setState({ dishName: name, infoModalDish: true })
    }

    closeInfoModalDish = () => {
        this.setState({ infoModalDish: false })
    }

    openInfoModalMeal = (id, name) => {
        this.props.getMealNormatives(id, this.toastManager)
        this.setState({ mealName: name, infoModalMeal: true })
    }

    closeInfoModalMeal = () => {
        this.setState({ infoModalMeal: false })
    }

    handleChangeDateStartFrom = (date) => {
        this.setState({ startDate: date });
    }

    handleChangeDateEndTo = (date) => {
        this.setState({ endDate: date });
    }

    openExportModal = () => {
        this.setState({ openExportModal: true })
    }

    closeExportModal = () => {
        this.setState({ openExportModal: false, startDate: null, endDate: null })
    }

    toggleActiveTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleCustomizedExportDialog = () => {
        this.setState({ customizedExportDialog: !this.state.customizedExportDialog })
    }

    printCustomizedExport = () => {
        const canvasSave = document.getElementById('line-chart');
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "")
        const request = {
            username: this.state.user.username,
            image: base64Img
        }
        this.props.getExportUser(request, this.toastManager)
    }

    render() {

        const { user, initialUser, validEmail, diagnosisToAdd, modalDiagnosisOpen, diagnosisExists, lineGraph, diagnosisNoteOpen, userDetailsTable, infoModalDish, infoModalMeal, mealName, dishName, openExportModal, startDate, endDate, roleName, macronutrientData, customizedExportDialog, allergenToAdd, fodmapToAdd } = this.state;
        const { companiesForUser } = this.props.companyData
        const { isLoading, diets, diagnoses, roles, userMenus, allergens, fodmaps } = this.props.userData;
        const { dishNormatives, mealNormatives } = this.props.menuEditorData;
        const { t } = this.props;

        const validEmailAddress = (validEmail && user.email.length > 0) ? { valid: true } : { valid: false };
        const className = validEmailAddress.valid === true ? "has-success" : ""

        let roleClient = "ROLE_CLIENT"
        let roleStudent = "ROLE_STUDENT"
        let roleAdmin = "ROLE_ADMIN"

        let roleOptions = roles.map((role, index) => {
            if (hasRole(roleAdmin) && role === roleClient) {
                return <option key={index} value={role}> {t(roleClient)}</option>
            } else if (hasRole(roleAdmin) && role !== roleClient) {
                return null
            } else {
                return <option key={index} value={role}>{t(role)}</option>
            }
        })

        const diagnosesItems = user.diagnoses.map((diagnoses, index) => {
            return <tr key={index}>
                <td>
                    <Input disabled type="text" name={"code" + index} id={"code" + index} value={diagnoses.code} />
                </td>
                <td>
                    <Input disabled type="text" name={"name" + index} id={"name" + index} value={diagnoses.name} />
                </td>
                <td>
                    <Button className="btn-icon btn-round" type="button" color="warning" size="sm" onClick={() => this.removeDiagnosis(index)}><i className="tim-icons icon-simple-delete"></i></Button>
                </td>
            </tr>
        });
        const personsMultiplierDisabled = user.personsMultiplier === '1' || user.personsMultiplier === 1
        const buttonDisabled = user.sex && user.username && user.password && (user.personsMultiplier > 1 ? !user.dateOfBirth : user.dateOfBirth) && (hasRole("ROLE_SUPER_ADMIN") ? user.company : !user.company)

        const buttonDisabledFromTo = startDate && endDate

        const disabledAddDiagnosis = diagnosisExists || diagnosisToAdd.length === 0;
        const diagnosesModal = <DraggableModal
            isOpen={modalDiagnosisOpen}
            toggle={this.toggleDiagnosisOpen}
            size={"lg"}
            title={<strong>{t("add_diagnosis")}</strong>}
            body={<Col md={12}>
                <Typeahead
                    selected={diagnosisToAdd}
                    id="diagnosis"
                    clearButton
                    multiple
                    labelKey={option => `${option.code} ${option.name}`}
                    onChange={this.handleChangeDiagnosis}
                    options={diagnoses}
                    placeholder={t("choose_diagnosis")}
                    renderMenuItemChildren={(option) => (
                        <div className="custom-render-menu-data">
                            <strong>{option.code}</strong>
                            <div>
                                <small>{option.name}</small>
                            </div>
                        </div>
                    )}
                    paginationText={t("display_additional")}
                    emptyLabel={t("no_matches_found")}
                />
            </Col>}
            footer={
                <Button disabled={disabledAddDiagnosis} color="success" onClick={this.addDiagnosis}>{t("add")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        const progressLoading = <div style={{ textAlign: 'center' }}>
            <ClipLoader
                size={60}
                color={"#e14eca"}
                loading={isLoading}
            />
        </div>

        let bmiData = null;
        let timestampData = null;
        let weightData = null;
        let hipData = null;
        let waistData = null;
        let whrData = null;
        let whtrData = null;
        let baiData = null;
        let energyNeedsData = null;
        /* let fatTissueData = null */
        if (user.metadata) {
            bmiData = user.metadata.map(data => {
                return data.bmi
            })
            timestampData = user.metadata.map(data => {
                return moment(data.timestamp).format("DD.MM.YYYY.")
            })
            weightData = user.metadata.map(data => {
                return data.weight
            })
            hipData = user.metadata.map(data => {
                return data.hipCircumference
            })
            waistData = user.metadata.map(data => {
                return data.waistCircumference
            })
            whrData = user.metadata.map(data => {
                return data.whr
            })
            whtrData = user.metadata.map(data => {
                return data.whtr
            })
            baiData = user.metadata.map(data => {
                return data.bai
            })
            energyNeedsData = user.metadata.map(data => {
                return data.energyNeeds
            })
            /* fatTissueData = user.metadata.map(data => {
                return data.fatTissue
            }) */
        }

        const customizedExportGraph = {
            labels: timestampData,
            datasets: [{
                label: t("weight") + " (kg)",
                data: weightData,
                fill: false,
                borderColor: 'rgb(177, 216, 119)',
                tension: 0.1
            },
            {
                label: t("waist_circumference") + " (cm)",
                data: hipData,
                fill: false,
                borderColor: 'rgb(140, 220, 218)',
                tension: 0.1
            },
            {
                label: t("hip_circumference") + " (cm)",
                data: waistData,
                fill: false,
                borderColor: '#FFC857',
                tension: 0.1
            },
            {
                label: t("bmi") + " (kg/m\u00B2)",
                data: bmiData,
                fill: false,
                borderColor: 'rgb(241, 106, 113)',
                tension: 0.1
            },
            {
                label: t("WHR") + " (%)",
                data: whrData,
                fill: false,
                borderColor: '#D8A47F',
                tension: 0.1
            },
            {
                label: t("WHtR") + " (%)",
                data: whtrData,
                fill: false,
                borderColor: '#3FA7D6',
                tension: 0.1
            },
            {
                label: t("BAI") + " (%)",
                data: baiData,
                fill: false,
                borderColor: '#A01A7D',
                tension: 0.1
            },
            {
                label: t("energy_needs"),
                data: energyNeedsData,
                fill: false,
                borderColor: '#775954',
                tension: 0.1
            }]
        }

        const dataGraphTab1 = {
            labels: timestampData,
            datasets: [{
                label: t("weight") + " (kg)",
                data: weightData,
                fill: false,
                borderColor: 'rgb(177, 216, 119)',
                tension: 0.1
            }]
        };

        const dataGraphTab2 = {
            labels: timestampData,
            datasets: [{
                label: t("bmi") + " (kg/m\u00B2)",
                data: bmiData,
                fill: false,
                borderColor: 'rgb(241, 106, 113)',
                tension: 0.1
            }]
        };

        const dataGraphTab3 = {
            labels: timestampData,
            datasets: [{
                label: t("WHR") + " (%)",
                data: whrData,
                fill: false,
                borderColor: '#D8A47F',
                tension: 0.1
            },
            {
                label: t("WHtR") + " (%)",
                data: whtrData,
                fill: false,
                borderColor: '#3FA7D6',
                tension: 0.1
            }]
        };

        const dataGraphTab4 = {
            labels: timestampData,
            datasets: [{
                label: t("energy_needs"),
                data: energyNeedsData,
                fill: false,
                borderColor: '#775954',
                tension: 0.1
            }]
        };

        const dataGraphTab5 = {
            labels: timestampData,
            datasets: [{
                label: t("waist_circumference") + " (cm)",
                data: hipData,
                fill: false,
                borderColor: 'rgb(140, 220, 218)',
                tension: 0.1
            },
            {
                label: t("hip_circumference") + " (cm)",
                data: waistData,
                fill: false,
                borderColor: '#FFC857',
                tension: 0.1
            }]
        };

        const dataGraphTab6 = {
            labels: timestampData,
            datasets: [{
                label: t("BAI") + " (%)",
                data: baiData,
                fill: false,
                borderColor: '#A01A7D',
                tension: 0.1
            }]
        };

        const options = {
            tooltips: {
                backgroundColor: 'rgba(0,0,0,0.8)',
                titleFontSize: 16,
                titleFontColor: '#fff',
                bodyFontColor: '#eaeaea',
                bodyFontSize: 14,
                displayColors: true
            }
        }

        const userMenusColumns = [

            {
                name: t("date"),
                selector: "date",
                width: "15%",
                center: true,
                cell: row => moment(row.date).format('DD.MM.YYYY.')
            },
            {
                name: t("dishes"),
                selector: "dishes",
                sortable: true,
                width: "40%",
                cell: row =>
                    <span>
                        {row.dishes.map((data, index) => {
                            return <Badge style={{ cursor: 'pointer', margin: '1px' }} key={index} className="meal-item dish" color="info" onClick={() => this.openInfoModalDish(data.id, data.name, this.toastManager)}>{data.name}</Badge>
                        })}
                    </span>
            },
            {
                name: t("meals"),
                selector: "meals",
                sortable: true,
                width: "40%",
                cell: row =>
                    <span >
                        {row.meals.map((data, index) => {
                            return <Badge style={{ cursor: 'pointer', margin: '1px' }} key={index} className="meal-item meal" color="info" onClick={() => this.openInfoModalMeal(data.id, data.name, this.toastManager)}>{data.name}</Badge>
                        })}
                    </span>
            },
            {
                name: t("actions"),
                width: "5%",
                center: true,
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.handlePrintPdf(row.macronutrients, row.id)}><i className="fas fa-file-pdf"></i></Button>
                </span>
            }
        ]

        let infoDetailsDish = null
        if (dishNormatives.length > 0) {
            infoDetailsDish = <React.Fragment>
                <Table>
                    <thead>
                        <tr>
                            <th><strong>{t("food")}</strong></th>
                            <th><strong>{t("quantity")}</strong></th>
                            <th><strong>{t("unit")}</strong></th>
                            <th><strong>{t("grams_by_piece")}</strong></th>
                            <th><strong>{t("preparation_type")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dishNormatives.map((data, index) => {
                            return <tr key={index}>
                                <td>
                                    {data.foodName}
                                </td>
                                <td>
                                    {data.quantity}
                                </td>
                                <td>
                                    {t(data.unitOfMeasurementWithSymbol.unitOfMeasurement)}
                                </td>
                                <td>
                                    {data.gramsByPiece}
                                </td>
                                <td>
                                    {t(data.preparationType)}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table >
            </React.Fragment>
        }

        const modalInfoDish = <DraggableModal
            isOpen={infoModalDish}
            toggle={this.closeInfoModalDish}
            size={"sm"}
            title={<strong>{t("details_dish")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <span>{t("dish_name")}{": "}<strong>{dishName}</strong></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {infoDetailsDish}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        let infoMealData = null
        if (mealNormatives.length > 0) {
            infoMealData = <React.Fragment>
                {mealNormatives.map((meal, index) => {
                    return <div key={index}><span>{t("dish")}{": "}<strong>{meal.name}</strong></span>
                        <Table>
                            <thead>
                                <tr>
                                    <th><strong>{t("food")}</strong></th>
                                    <th><strong>{t("quantity")}</strong></th>
                                    <th><strong>{t("unit")}</strong></th>
                                    <th><strong>{t("grams_by_piece")}</strong></th>
                                    <th><strong>{t("preparation_type")}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {meal.foods.map((data, index) => {
                                    return <tr key={index}>
                                        <td>
                                            {data.foodName}
                                        </td>
                                        <td>
                                            {data.quantity}
                                        </td>
                                        <td>
                                            {t(data.unitOfMeasurementWithSymbol.unitOfMeasurement)}
                                        </td>
                                        <td>
                                            {data.gramsByPiece}
                                        </td>
                                        <td>
                                            {t(data.preparationType)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <hr /></div>
                })}
            </React.Fragment>
        }

        const modalInfoMeal = <DraggableModal
            isOpen={infoModalMeal}
            toggle={this.closeInfoModalMeal}
            size={"sm"}
            title={<strong>{t("details_meal")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <span>{t("meal_name")}{": "}<strong>{mealName}</strong></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {infoMealData}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        const exportCustomizedModal = <DraggableModal
            isOpen={customizedExportDialog}
            toggle={this.toggleCustomizedExportDialog}
            size={"sm"}
            title={<strong>{t("export")}</strong>}
            body={<div>
                <Row>
                    <Col md={12} align={'center'}>
                        <div className={'chart-container'}>
                            <Line
                                id="line-chart"
                                data={customizedExportGraph}
                                options={options}
                                legend={{ position: 'bottom' }}
                                width={450}
                                height={200} />
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Button style={{ float: 'right' }} size="sm" color="success" onClick={() => this.printCustomizedExport()}>{t("export")}</Button>
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '1400px' }}
        />

        const exportModal = <DraggableModal
            isOpen={openExportModal}
            toggle={this.closeExportModal}
            size={"sm"}
            title={<strong>{t("export")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <FormGroup className={'datepicker-wrapper mw-150'}>
                            <Label>{t("from")}</Label>
                            <DatePicker
                                className={"form-control"}
                                selected={startDate}
                                onChange={this.handleChangeDateStartFrom}
                                dateFormat="DD.MM.yyyy."
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText={t("from")}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup className={'datepicker-wrapper mw-150'}>
                            <Label>{t("to")}</Label>
                            <DatePicker
                                className={"form-control"}
                                selected={endDate}
                                onChange={this.handleChangeDateEndTo}
                                dateFormat="DD.MM.yyyy."
                                showMonthDropdown
                                showYearDropdown
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                dropdownMode="select"
                                placeholderText={t("to")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{ float: 'right' }} size="sm" color="success" disabled={!buttonDisabledFromTo} onClick={() => this.props.getExportUserMenus({ username: this.state.user.username, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') }, this.toastManager)}>{t("export")}</Button>
                    </Col>
                </Row></div>}
        />

        return (
            <div className="content content-client">
                <Prompt when={JSON.stringify(user) !== JSON.stringify(initialUser)} message={t("warning_message")} />
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={9} md={6} lg={6} xl={5} className={'col-xxl-4 col-wxl-3'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("user")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup check inline>
                                        <Label check>
                                            <Input type="checkbox" name="active" id="active" onChange={this.handleChange} style={{ marginLeft: "20px" }}
                                                checked={user.active || false} /> {t("activeFlag")}
                                            <span className="form-check-sign">
                                                <span className="check" />
                                            </span>
                                        </Label>
                                    </FormGroup>
                                    <Row className={'mt-3'}>
                                        <Col>
                                            <FormGroup check inline className="form-check-radio">
                                                <Label className="form-check-label">
                                                    <Input type="radio" name="sex" id="MALE" value="MALE" onChange={this.handleChange} checked={this.state.user.sex === "MALE"} />
                                                    {t("male")}
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check inline className="form-check-radio">
                                                <Label className="form-check-label">
                                                    <Input type="radio" name="sex" id="FEMALE" value="FEMALE" onChange={this.handleChange} checked={this.state.user.sex === "FEMALE"} />
                                                    {t("female")}
                                                    <span className="form-check-sign"></span>
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className={'mt-3'}>
                                        <Col>
                                            <FormGroup className={'mw-250'}>
                                                <Label>{t("first_name")}</Label>
                                                <Input
                                                    name="firstName"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    placeholder={t("first_name")}
                                                    value={user.firstName || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-250'}>
                                                <Label>{t("last_name")}</Label>
                                                <Input
                                                    name="lastName"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    placeholder={t("last_name")}
                                                    value={user.lastName || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-250'}>
                                                <Label>{t("username")}</Label>
                                                {hasRole("ROLE_SUPER_ADMIN") || !this.props.match.params.username ? <Input
                                                    name="username"
                                                    type="text"
                                                    onChange={this.handleChange}
                                                    placeholder={t("username")}
                                                    value={user.username}
                                                    autoComplete="off"
                                                /> : <Input
                                                    disabled
                                                    name="username"
                                                    type="text"
                                                    value={user.username}
                                                />}
                                            </FormGroup>
                                            <div className={'mw-320'}>
                                                <FormGroup className={className}>
                                                    <Label>{t("email")}</Label>
                                                    <Input {...validEmailAddress} type="text" name="email" id="email" placeholder={t("email")} autoComplete="off" onChange={this.handleChange} value={user.email} />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {(hasRole("ROLE_SUPER_ADMIN") || !this.props.match.params.username) && <Fragment>
                                            <Col className={'mw-250'}>
                                                <FormGroup>
                                                    <Label>{t("password")}</Label>
                                                    <Input
                                                        name="password"
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        placeholder={t("password")}
                                                        value={user.password || ''}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <br />
                                                <Button onClick={this.generatePassword} size="sm" color="success">{t("generate")}</Button>
                                            </Col>
                                        </Fragment>}
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className={'datepicker-wrapper mw-150'}>
                                                <Label>{t("date_of_birth")}</Label>
                                                <DatePicker
                                                    className={"form-control"}
                                                    selected={user.dateOfBirth}
                                                    onChange={this.handleChangeDateBirth}
                                                    minDate={moment().subtract(130, 'years')}
                                                    maxDate={moment(new Date())}
                                                    dateFormat="DD.MM.yyyy."
                                                    showMonthDropdown
                                                    timeCaption="Time"
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText={t("date_of_birth")}
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-220'}>
                                                <Label>{t("phone")}</Label>
                                                <Input className="phone" type="text" name="phone" id="phone" placeholder={t("phone")} onChange={this.handleChange} value={user.phone || ''} autoComplete="off" />
                                            </FormGroup>
                                            {this.props.match.params.username && <FormGroup className={'mw-150'}>
                                                <Label>{t("joined_on")}</Label>
                                                <Input
                                                    disabled
                                                    name="joinedOn"
                                                    type="text"
                                                    value={moment.unix(user.joinedOn).format('DD.MM.YYYY.')}
                                                />
                                            </FormGroup>}
                                            <FormGroup className={'mw-320'}>
                                                <Label>{t("note")}</Label>
                                                <Input
                                                    name="note"
                                                    type="textarea"
                                                    rows="4"
                                                    onChange={this.handleChange}
                                                    placeholder={t("note")}
                                                    value={user.note || ''}
                                                    autoComplete="off"
                                                />
                                            </FormGroup>
                                            <FormGroup className={'mw-250'}>
                                                <Label>{t("role")}</Label>
                                                <Input
                                                    type="select"
                                                    name="role"
                                                    value={user.role === '' ? roleName === '' ? t("select_role") : roleName : user.role}
                                                    onChange={(e) => this.handleChange(e)}>
                                                    <option disabled>{t("select_role")}</option>
                                                    {roleOptions}
                                                </Input>
                                            </FormGroup>

                                            {(user.role === roleClient || roleName === roleClient) && <FormGroup className={'mw-150'}>
                                                <Label>{t("persons_multiplier")}</Label>
                                                <Input
                                                    name="personsMultiplier"
                                                    type="number"
                                                    min={1}
                                                    step={1}
                                                    onChange={this.handleChange}
                                                    value={Number(user.personsMultiplier) || 1}
                                                />
                                            </FormGroup>}

                                            {hasRole("ROLE_SUPER_ADMIN") && ((user.role === roleClient || roleName === roleClient) || (user.role === roleAdmin || roleName === roleAdmin) || (user.role === roleStudent || roleName === roleStudent)) && <FormGroup className={'mw-150'}>
                                                <Label>{t("company")}</Label>
                                                <Typeahead
                                                    selected={user.company === null ? [] : companiesForUser.filter(c => c.name === user.company.name)}
                                                    id="company"
                                                    clearButton
                                                    labelKey="name"
                                                    onChange={this.handleChangeCompany}
                                                    options={companiesForUser}
                                                    placeholder={t("select_company")}
                                                    paginationText={t("display_additional")}
                                                    emptyLabel={t("no_matches_found")}
                                                />
                                            </FormGroup>}

                                            {(user.role === roleClient || roleName === roleClient) && <FormGroup className={'mw-320'}>
                                                <Label>{t("diet")}</Label>
                                                <Typeahead
                                                    selected={user.diet === null ? [] : diets.filter(d => d.name === user.diet.name)}
                                                    id="diet"
                                                    clearButton
                                                    labelKey="name"
                                                    onChange={this.handleChangeDiet}
                                                    options={diets}
                                                    placeholder={t("select_diet")}
                                                    paginationText={t("display_additional")}
                                                    emptyLabel={t("no_matches_found")}
                                                />
                                            </FormGroup>}
                                            {(user.role === roleClient || roleName === roleClient) && <FormGroup className={'mw-320'}>
                                                <Label>{t("allergens")}</Label>
                                                <Typeahead
                                                    selected={allergenToAdd}
                                                    id="allergen"
                                                    clearButton
                                                    multiple
                                                    labelKey={"name"}
                                                    onChange={this.handleChangeAllergen}
                                                    options={allergens}
                                                    placeholder={t("choose_allergens")}
                                                    paginationText={t("display_additional")}
                                                    emptyLabel={t("no_matches_found")}
                                                />
                                            </FormGroup>}
                                            {(user.role === roleClient || roleName === roleClient) && <FormGroup className={'mw-320'}>
                                                <Label>{t("fodmaps")}</Label>
                                                <Typeahead
                                                    selected={fodmapToAdd}
                                                    id="fodmap"
                                                    clearButton
                                                    multiple
                                                    labelKey={"name"}
                                                    onChange={this.handleChangeFodmap}
                                                    options={fodmaps}
                                                    placeholder={t("choose_fodmaps")}
                                                    paginationText={t("display_additional")}
                                                    emptyLabel={t("no_matches_found")}
                                                />
                                            </FormGroup>}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        {(user.role === roleClient || roleName === roleClient) && <Col md={6} lg={6} xl={8} className={'col-xxl-9 col-wl-8 col-wxl-8'}>
                            <Row className={'even-height-row'}>
                                {personsMultiplierDisabled && userDetailsTable && <Col sm={12} md={12} xl={12} className={'col-xxl-12 col-wl-12 col-wxl-12'}>
                                    <UserDetailsCard
                                        user={user}
                                        metadata={user && user.metadata}
                                        handleChangeMetadata={this.handleChangeMetadata}
                                        addNewMetadata={this.addNewMetadata}
                                        editMetaData={this.editMetaData}
                                        deleteMetadata={this.deleteMetadata}
                                        toggleLineGraph={this.toggleLineGraph}
                                        initialUser={initialUser} />
                                </Col>}
                                {lineGraph && personsMultiplierDisabled && <Col sm={12} md={12} xl={12} className={'col-xxl-12 col-wl-12 col-wxl-12'}>
                                    <Card className={'even-height-card'}>
                                        <CardHeader>
                                            <Row>
                                                <Col>
                                                    <CardTitle tag="h3">
                                                        <strong>{t("metadata")}</strong>
                                                    </CardTitle>
                                                </Col>
                                                <Col style={{ textAlign: 'right' }}>
                                                    <Button disabled={user && user.metadata.length === 0} className="btn-icon btn-round" type="button" color="success" size="sm" onClick={() => this.toggleLineGraph()}>
                                                        <i className="fas fa-table"></i>
                                                    </Button>
                                                    <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.toggleCustomizedExportDialog()}><i className="fas fa-file-pdf"></i></Button>
                                                </Col>
                                                {exportCustomizedModal}
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Nav tabs className={'nav-tabs-custom'}>
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '1'}
                                                        style={this.state.activeTab === '1' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('1')}>
                                                        {t("weight")}
                                                    </NavLink>
                                                </NavItem>}
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '2'}
                                                        style={this.state.activeTab === '2' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('2')}>
                                                        {t("bmi")}
                                                    </NavLink>
                                                </NavItem>}
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '3'}
                                                        style={this.state.activeTab === '3' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('3')}>
                                                        {t("ratio_waist_hip")}
                                                    </NavLink>
                                                </NavItem>}
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '4'}
                                                        style={this.state.activeTab === '4' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('4')}>
                                                        {t("graph_title")}
                                                    </NavLink>
                                                </NavItem>}
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '5'}
                                                        style={this.state.activeTab === '5' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('5')}>
                                                        {t("body_adiposity_index")}
                                                    </NavLink>
                                                </NavItem>}
                                                {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <NavItem>
                                                    <NavLink
                                                        active={this.state.activeTab === '6'}
                                                        style={this.state.activeTab === '6' ? activeStyle : inactiveStyle}
                                                        onClick={() => this.toggleActiveTab('6')}>
                                                        {t("energy_needs")}
                                                    </NavLink>
                                                </NavItem>}
                                            </Nav>
                                            <TabContent activeTab={this.state.activeTab}>
                                                <TabPane tabId="1">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab1}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab2}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="3">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab5}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="4">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab3}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="5">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab6}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    <Row>
                                                        <Col>
                                                            <CardBody>
                                                                {isLoading ? progressLoading :
                                                                    <Row>
                                                                        <Col md={12} align={'center'}>
                                                                            <div className={'chart-container'}>
                                                                                <Line
                                                                                    data={dataGraphTab4}
                                                                                    options={options}
                                                                                    legend={{ position: 'bottom' }}
                                                                                    width={450}
                                                                                    height={200} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>}
                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </CardBody>
                                    </Card>
                                </Col>}
                            </Row>
                            <Row>
                                <Col sm={10} md={12} xl={9} className={'col-xxl-8 col-wl-8'}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h3"><strong>{t("diagnosis")}</strong></CardTitle>
                                        </CardHeader>
                                        <CardBody>
                                            <FormGroup row inline>
                                                <Col>
                                                    <Button type="button" color="success" size="sm" onClick={this.toggleDiagnosisOpen}>{t("add_diagnosis")}</Button>
                                                    <Button type="button" color="success" size="sm" onClick={this.toggleAddDiagnosisNote}>{t("add_diagnosis_note")}</Button>
                                                    {diagnosisNoteOpen && <FormGroup className={'mw-400'}>
                                                        <Label>{t("note_diagnosis")}</Label>
                                                        <Input
                                                            name="diagnosisNote"
                                                            type="textarea"
                                                            rows="2"
                                                            onChange={this.handleChange}
                                                            placeholder={t("note_diagnosis")}
                                                            value={user.diagnosisNote || ''}
                                                            autoComplete="off"
                                                        />
                                                    </FormGroup>}
                                                    {user.diagnoses.length === 0 ? null :
                                                        <PerfectScrollbar style={{ height: 'auto', maxHeight: '310px' }}>
                                                            <Table borderless>
                                                                <thead>
                                                                    <tr>
                                                                        <th width="20%">{t("code")}</th>
                                                                        <th width="65%">{t("name")}</th>
                                                                        <th width="15%">&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {diagnosesItems}
                                                                </tbody>
                                                            </Table>
                                                        </PerfectScrollbar>}
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                        {diagnosesModal}
                                    </Card>
                                </Col>
                            </Row>

                            {userMenus.length > 0 && <Row>
                                <Col sm={12} md={12} xl={12} className={'col-xxl-12 col-wl-12'}>
                                    <Card>
                                        <CardHeader>
                                            <Row>
                                                <Col style={{ textAlign: 'left' }}>
                                                    <CardTitle tag="h3"><strong>{t("history_user")}</strong></CardTitle>
                                                </Col>
                                                <Col style={{ textAlign: 'right' }}>
                                                    <Button type="button" color="danger" size="sm" disabled={userMenus.length === 0} onClick={() => this.openExportModal()}><i className="fas fa-file-pdf"></i></Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <Col>
                                                <DataTable
                                                    columns={userMenusColumns}
                                                    data={userMenus}
                                                    noHeader={true}
                                                    highlightOnHover
                                                    pagination
                                                    paginationServer
                                                    paginationRowsPerPageOptions={[5, 10, 15]}
                                                    paginationTotalRows={this.props.userData.totalElementsUserMenus}
                                                    paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                                    noDataComponent={t("no_records")}
                                                    onChangePage={(page) => this.onChangePage(page)}
                                                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                                />
                                            </Col>
                                        </CardBody>
                                    </Card>
                                    {modalInfoDish}
                                    {modalInfoMeal}
                                    {exportModal}
                                </Col>
                            </Row>}
                            <div style={{ height: '0', overflow: 'hidden', position: 'absolute', zIndex: '-5000', top: '0', left: '0' }}>
                                <Row>
                                    <Col align={'center'}>
                                        <div className={'chart-container'}>
                                            <Pie
                                                id="chart"
                                                data={macronutrientData.macronutrientChartData}
                                                width={500} height={350} legend={{ position: 'bottom' }} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ height: '0', overflow: 'hidden', position: 'absolute', zIndex: '-5000', top: '0', left: '0' }}>
                                <Row>
                                    <Col align={'center'}>
                                        <div className={'chart-container'} >
                                            <Bar
                                                id="bar"
                                                data={macronutrientData && macronutrientData.macronutrientBarChart}
                                                width={500} height={350} legend={{ position: 'bottom' }}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>}
                    </Row>
                    {this.props.match.params.username ? <Button disabled={(JSON.stringify(user) === JSON.stringify(initialUser))} type="submit" color="success"> {t("update")}</Button>
                        : <Button disabled={!buttonDisabled} type="submit" color="success">{t("create")}</Button>}
                </Form>
            </div>
        );
    }
}

User.propTypes = {
    userData: PropTypes.object.isRequired,
    companyData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    companyData: state.companyData,
    menuEditorData: state.menuEditorData
})

const mapActionsToProps = { updateUser, getDiets, getRoles, getDiagnoses, createUser, getUserMenus, getDishNormatives, getMealNormatives, getExportUserMenus, getExportUserMenu, getCompaniesForUser, getExportUser, getAllergens, getFodmaps }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(User)));