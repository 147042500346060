import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Row, Col, CardBody, CardTitle, Table, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';

export class MacronutrientsChartAndRatioTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            barChart: true,
            tableData: false
        }
    }

    render() {

        const { data, height, options, t, tableData } = this.props;

        let macronutrientsRatio = null;

        if (tableData) {
            macronutrientsRatio =
                <Table className="tableFixHead">
                    <thead>
                        <tr>
                            <th><strong>{t("macronutrients")}</strong></th>
                            <th><strong>{t("value")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(tableData).map((field, index) => {
                            return <tr key={index}>
                                <td style={field[0] === "carbohydrateRatio" ? { backgroundColor: '#FCF955' } : field[0] === "fatRatio" ? { backgroundColor: '#F76A6E' } : field[0] === "proteinRatio" ? { backgroundColor: '#68A2EB' } : null} >
                                    {field[0] === "carbohydrateRatio" ? <span>{t("monosaccharide") + " / " + t("polysaccharide")} </span> : null}
                                    {field[0] === "fatRatio" ? <span>{t("saturated") + " / " + t("unsaturated") + " " + t("fatty_acid")}</span> : null}
                                    {field[0] === "proteinRatio" ? <span>{t("animal") + " / " + t("plant") + " " + t("proteins")}</span> : null}
                                </td>
                                <td>
                                    {field[1]}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table >
        }

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("macronutrients_ratios")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            {this.state.barChart ?
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ barChart: false, tableData: true })}>{t("details")}</Button> :
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ barChart: true, tableData: false })}>{t("details")}</Button>}
                        </Col>
                    </Row>
                </CardHeader>

                <CardBody>
                    {this.state.barChart && <Row>
                        <Col md={12} align={'center'}>
                            <div className={'chart-container'}>
                                <Bar
                                    data={data}
                                    height={height}
                                    options={options}
                                />
                            </div>
                        </Col>
                    </Row>}
                    {this.state.tableData && <Row>
                        <Col md={12}>
                            {macronutrientsRatio}
                        </Col>
                    </Row>}
                </CardBody>
            </Card>
        )
    }
}

MacronutrientsChartAndRatioTable.propTypes = {
    data: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    options: PropTypes.object.isRequired
}

export default withTranslation()(MacronutrientsChartAndRatioTable);
