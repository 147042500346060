import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class ProteinCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { proteinDetail, t } = this.props;

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("protein")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("total_protein")} (g)</Label>
                                <NumberFormat className="form-control" name="totalProtein" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.totalProtein} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("plant")} (g)</Label>
                                <NumberFormat className="form-control" name="plant" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.plant} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("animal")} (g)</Label>
                                <NumberFormat className="form-control" name="animal" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.animal} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>

                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("phenylalanine")} (g)</Label>
                                <NumberFormat className="form-control" name="phenylalanine" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.phenylalanine} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("purine")} (g)</Label>
                                <NumberFormat className="form-control" name="purine" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.purine} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("histamine")} (g)</Label>
                                <NumberFormat className="form-control" name="histamine" onChange={(e) => this.handleChange(e, "proteinDetail")} value={proteinDetail.histamine} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

ProteinCard.propTypes = {
    proteinDetail: PropTypes.object.isRequired
}

export default withTranslation()(ProteinCard)
