import { SET_CALENDAR_MEALS, SET_LOADING_CALENDAR_ENTRIES, SET_MENU_BY_DIET, SET_MENU_DISHES, SET_LATEST_DIET_NAME, SET_DISH_NORMATIVES, SET_MEAL_NORMATIVES, SET_DIET_CLIENTS, SET_DIET_CLIENTS_COPY, SET_MENU_NUTRIENTS_OUT_OF_BOUNDS, SET_MODAL_ISSUE, SET_FFQ } from '../types';

const initialState = {
    menus: [],
    meals: [],
    isLoading: false,
    menuByDiet: [],
    dishes: [],
    diet: '',
    date: '',
    client: null,
    dishNormatives: [],
    mealNormatives: [],
    clients: [],
    clientsForCopy: [],
    nutrientsOutOfBounds: [],
    modalIssue: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CALENDAR_MEALS:
            return {
                ...state,
                meals: action.payload
            }
        case SET_LOADING_CALENDAR_ENTRIES:
            return {
                ...state,
                isLoading: true
            }
        case SET_MENU_BY_DIET:
            return {
                ...state,
                menuByDiet: action.payload
            }
        case SET_MENU_DISHES:
            return {
                ...state,
                dishes: action.payload
            }
        case SET_LATEST_DIET_NAME:
            return {
                ...state,
                diet: action.payload.diet,
                date: action.payload.date,
                client: action.payload.client
            }
        case SET_DISH_NORMATIVES:
            return {
                ...state,
                dishNormatives: action.payload
            }
        case SET_FFQ:
            return {
                ...state,
                ffqFactor: action.ffqFactor
            }
        case SET_MEAL_NORMATIVES:
            return {
                ...state,
                mealNormatives: action.payload
            }
        case SET_DIET_CLIENTS:
            return {
                ...state,
                clients: action.payload
            }
        case SET_DIET_CLIENTS_COPY:
            return {
                ...state,
                clientsForCopy: action.payload
            }
        case SET_MENU_NUTRIENTS_OUT_OF_BOUNDS:
            return {
                ...state,
                nutrientsOutOfBounds: action.payload
            }
        case SET_MODAL_ISSUE:
            return {
                ...state,
                modalIssue: action.payload
            }
        default:
            return state;
    }
}
