import { SET_COMPANIES, CREATE_COMPANY, DELETE_COMPANY, SET_COMPANIES_FOR_USER } from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createCompany = (toastManager, newCompany, history) => (dispatch) => {
    axios.post('/v1/company', newCompany)
        .then(response => {
            dispatch({
                type: CREATE_COMPANY,
                payload: response.data.company
            });
            history.push('/admin/companyList')
            toastManager.add(i18next.t("company_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateCompany = (toastManager, id, updatedCompany,history) => (dispatch) => {
    axios.patch(`/v1/company/${id}`, updatedCompany)
        .then(response => {
            history.push('/admin/companyList')
            toastManager.add(i18next.t("company_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const getCompanies = (page, size) => (dispatch) => {
    axios.get(`/v1/company?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_COMPANIES,
                payload: response.data.companies
            })
        })
        .catch(error => {
            dispatch({
                type: SET_COMPANIES,
                payload: []
            })
        })
}

export const getCompaniesForUser = () => (dispatch) => {
    axios.get(`/v1/company/forUser`)
        .then(response => {
            dispatch({
                type: SET_COMPANIES_FOR_USER,
                payload: response.data.companies
            })
        })
        .catch(error => {
            dispatch({
                type: SET_COMPANIES_FOR_USER,
                payload: []
            })
        })
}

export const deleteCompany = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/company/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_COMPANY,
                payload: id
            })
            toastManager.add(i18next.t("company_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}