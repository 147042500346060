import {
    CREATE_FODMAP,
    SET_FODMAPS,
    DELETE_FODMAP,
    SET_FODMAP_ALERT,
    SET_FODMAP_ALERT_DISHES,
    SET_FODMAPS_ON_MENU,
    SET_COPY_FODMAP_LOADING
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createFodmap = (toastManager, request) => (dispatch) => {
    axios.post('/v1/fodmap', request)
        .then(response => {
            dispatch({
                type: CREATE_FODMAP,
                payload: response.data.fodmap
            });
            toastManager.add(i18next.t("fodmap_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateFodmap = (toastManager, id, request) => () => {
    axios.patch(`/v1/fodmap/${id}`, request)
        .then(() => {
            toastManager.add(i18next.t("fodmap_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(() => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getFodmaps = (page, size) => (dispatch) => {
    axios.get(`/v1/fodmap?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_FODMAPS,
                payload: response.data.fodmaps
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FODMAPS,
                payload: []
            })
        })
}

export const searchFodmaps = (page, size, search) => (dispatch) => {
    axios.get(`/v1/fodmap/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_FODMAPS,
                payload: response.data.fodmaps
            });
        })
        .catch(error => {
            dispatch({
                type: SET_FODMAPS,
                payload: []
            })
        })
}

export const deleteFodmap = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/fodmap/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_FODMAP,
                payload: id
            })
            toastManager.add(i18next.t("fodmap_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getFodmaps(0, 10))
        })
        .catch((error) => {
            if (error.response.data && error.response.data.code === 4002) {
                toastManager.add(i18next.t("delete_fodmap_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const getFodmapIssue = (request, toastManager) => (dispatch) => {
    axios.post(`/v1/fodmap/foodWithFodmaps`, { idList: request })
        .then(response => {
            dispatch({
                type: SET_FODMAP_ALERT,
                payload: response.data.foodWithFodmaps
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("detail_fodmaps_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getFodmapIssueDishes = (request, toastManager) => (dispatch) => {
    axios.post(`/v1/fodmap/dishesWithFodmaps`, { idList: request })
        .then(response => {
            dispatch({
                type: SET_FODMAP_ALERT_DISHES,
                payload: response.data.dishesWithFodmaps
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("detail_fodmaps_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getFodmapsIssue = (menuId, toastManager) => (dispatch) => {
    axios.get(`/v1/fodmap/${menuId}/menu`)
        .then(response => {
            dispatch({
                type: SET_FODMAPS_ON_MENU,
                payload: response.data.dishesWithFodmaps
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("fodmaps_issue_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const copySelectedFodmaps = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_FODMAP_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/fodmap/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("fodmaps_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_FODMAP_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("fodmaps_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_FODMAP_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}

export const copyAllFodmaps = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_FODMAP_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post(`/v1/fodmap/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("fodmaps_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_FODMAP_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("fodmaps_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_FODMAP_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}