import { SET_COMPANIES, CREATE_COMPANY, DELETE_COMPANY, SET_COMPANIES_FOR_USER } from '../types';

const initialState = {
    companies: [],
    size: 10,
    totalElements: 0,
    companiesForUser: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_COMPANIES:
            return {
                ...state,
                companies: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_COMPANY:
            return {
                ...state,
                companies: [
                    action.payload,
                    ...state.companies
                ]
            }
        case SET_COMPANIES_FOR_USER:
            return {
                ...state,
                companiesForUser: action.payload
            }
        case DELETE_COMPANY:
            let index = state.companies.findIndex((company) => company.id === action.payload)
            state.companies.splice(index, 1);
            return {
                ...state
            }
        default:
            return state;
    }
}
