import { SET_DISHES, SET_DISH_TYPES, SET_PREPARATION_TYPES, SET_DISH_FOODS, SET_MACRONUTRIENTS, SET_MICRONUTRIENTS, CREATE_DISH, DELETE_DISH, RESET_DISH_DATA, SET_MINERALS, SET_DISH_LIST_PAGE, SET_COPY_DISH_LOADING, SET_IMPORT_LOADING } from '../types';

const initialState = {
    dishes: [],
    dishTypes: [],
    preparationTypes: [],
    foods: [],
    macronutrientChartData: {},
    macronutrientChartOptions: {},
    macronutrientTableData: {},
    macronutrientsRatio: {},
    macronutrientBarChartOptions: {},
    macronutrientBarChart: {},
    micronutrients: [],
    minerals: [],
    size: 10,
    totalElements: 0,
    page: 0,
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false,
    isLoadingImport: false,
    importModal: false,
    selectedFiles: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DISHES:
            return {
                ...state,
                dishes: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements,
                isLoading: false
            }
        case SET_DISH_LIST_PAGE:
            return {
                ...state,
                page: action.payload.page,
                size: action.payload.size
            }
        case SET_DISH_TYPES:
            return {
                ...state,
                dishTypes: action.payload
            }
        case SET_PREPARATION_TYPES:
            return {
                ...state,
                preparationTypes: action.payload
            }
        case SET_DISH_FOODS:
            return {
                ...state,
                foods: action.payload
            }
        case SET_MACRONUTRIENTS:
            return {
                ...state,
                macronutrientChartData: action.payload.macronutrientChartData,
                macronutrientChartOptions: action.payload.macronutrientChartOptions,
                macronutrientTableData: action.payload.macronutrientTableData,
                macronutrientRatio: action.payload.macronutrientRatio,
                macronutrientBarChart: action.payload.macronutrientBarChart,
                macronutrientBarChartOptions: action.payload.macronutrientBarChartOptions
            }
        case SET_MICRONUTRIENTS:
            return {
                ...state,
                micronutrients: action.payload
            }
        case SET_MINERALS:
            return {
                ...state,
                minerals: action.payload
            }
        case CREATE_DISH:
            return {
                ...state,
                dishes: [
                    action.payload,
                    ...state.dishes
                ]
            }
        case DELETE_DISH:
            let index = state.dishes.findIndex((dish) => dish.id === action.payload)
            state.dishes.splice(index, 1);
            return {
                ...state
            }
        case RESET_DISH_DATA:
            return {
                ...state,
                dishTypes: [],
                foods: [],
                macronutrientChartData: {},
                macronutrientChartOptions: {},
                micronutrients: [],
                macronutrientTableData: {}
            }
        case SET_COPY_DISH_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        case SET_IMPORT_LOADING:
            return {
                ...state,
                isLoadingImport: action.payload.isLoadingImport,
                importModal: action.payload.importModal,
                selectedFiles: action.payload.selectedFiles
            }
        default:
            return state;
    }
}
