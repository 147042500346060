import React from "react";
import PropTypes from 'prop-types';
import { Button } from "reactstrap";

class CalendarButton extends React.Component {

    render() {

        const { selectedDiet} = this.props;

        return (
            <Button disabled={!selectedDiet} className="btn-icon btn-round" color="info" size="sm" onClick={this.props.onClick}>
                <i className="fas fa-calendar" />
            </Button>
        );
    }
}

CalendarButton.propTypes = {
    onClick: PropTypes.func,
    selectedDiet : PropTypes.string
}

export default CalendarButton