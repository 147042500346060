import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class FatCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { fatDetail, t } = this.props;


        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("fat")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("total_fat")} (g)</Label>
                                <NumberFormat className="form-control" name="totalFat" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.totalFat} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("saturated")} (g)</Label>
                                <NumberFormat className="form-control" name="saturated" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.saturated} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("monounsaturated")} (g)</Label>
                                <NumberFormat className="form-control" name="monounsaturated" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.monounsaturated} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>

                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("polyunsaturated")} (g)</Label>
                                <NumberFormat className="form-control" name="polyunsaturated" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.polyunsaturated} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("linoleic_acid")} (g)</Label>,
                                <NumberFormat className="form-control" name="linoleicAcid" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.linoleicAcid} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("cholesterol")} (mg)</Label>
                                <NumberFormat className="form-control" name="cholesterol" onChange={(e) => this.handleChange(e, "fatDetail")} value={fatDetail.cholesterol} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

FatCard.propTypes = {
    fatDetail: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default withTranslation()(FatCard)
