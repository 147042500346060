import {
    SET_USER,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_LOGIN_ERROR,
    SET_COMPANIES_ON_LOGIN,
    LOGIN_USER_DATA,
    SET_CONFIGURATION_SETTINGS
} from '../types';

const initialState = {
    authenticated: false,
    credentials: {},
    role: '',
    loginError: null,
    companies : [],
    userData : null,
    usingClientSpecificMenus: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true
            };
        case SET_UNAUTHENTICATED:
            return initialState;
        case SET_USER:
            return {
                authenticated: true,
                ...action.payload
            }
        case SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.payload
            }
        case SET_COMPANIES_ON_LOGIN:
            return {
                ...state,
                companies : action.payload
            }
        case LOGIN_USER_DATA:
            return {
                ...state,
                userData : action.payload
            }
        case SET_CONFIGURATION_SETTINGS:
            return {
                ...state,
                usingClientSpecificMenus: action.payload
            }
        default:
            return state;
    }
}