import React from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// Redux
import { connect } from 'react-redux';
import { getAuditLogs, getAuditLogDetails } from '../redux/actions/auditLogActions';
import DraggableModal from "components/modal/DraggableModal";

class AuditLogs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            auditLogdetailModal: false,
            selectedParams: null
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.getAuditLogs(0, 20);
        this.setState({ isLoading: false });
    }

    onChangePage = (page, size) => {
        this.props.getAuditLogs(page - 1, this.props.auditLogData.size);
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        this.props.getAuditLogs(currentPage - 1, currentRowsPerPage);
    }

    openDetailsDialog = (id) => {
        this.setState({ auditLogdetailModal: !this.state.auditLogdetailModal, auditLogId: id }, () => this.getAuditLogDetails(id));
    }

    getAuditLogDetails = (id) => {
        this.props.getAuditLogDetails(id).then(response => {
            this.getParamDetails()
        })
    }

    closeDetailModal = () => {
        this.setState({ auditLogdetailModal: !this.state.auditLogdetailModal });
    }

    getParamDetails = () => {
        const { auditDetails } = this.props.auditLogData;
        let selectedParams = auditDetails;
        if (this.isJsonString(selectedParams)) {
            let rawResponse = JSON.parse(selectedParams);
            selectedParams = rawResponse;
        }
        this.setState({ selectedParams: selectedParams });
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const { auditLogs, totalElements, size } = this.props.auditLogData;
        const { isLoading, auditLogdetailModal } = this.state;
        const { t } = this.props;

        const auditLogColumns = [
            {
                name: t("action"),
                selector: "action",
                width: "12%",
                cell: (row) => <span>
                    {row.action}
                </span>
            },
            {
                name: t("description"),
                selector: "description",
                width: "32%"
            },
            {
                name: t("status"),
                selector: "status",
                width: "8%",
                cell: (row) => <span>
                    {row.status === "SUCCESS" ? <span style={{ color: "green", fontWeight: 'bold' }}>{row.status}</span> : <span style={{ color: "red", fontWeight: 'bold' }}>{row.status}</span>}
                </span>
            },
            {
                name: t("timestamp"),
                selector: "timestamp",
                width: "12%",
                cell: row => moment.unix(row.timestamp).format('DD.MM.YYYY. HH:mm')
            },
            {
                name: t("user"),
                selector: "user",
                width: "15%"
            },
            {
                name: t("company"),
                selector: "companyName",
                width: "15%",
                cell: (row) => <span>
                    {row.user !== "superadmin" && <span>{row.companyName}</span>}
                </span>
            },
            {
                name: t("details"),
                width: "5%",
                center: "true",
                cell: row =>
                    <span style={{ height: '39px' }}>
                        {row.action !== "LOGIN" && <Button className="btn-icon btn-round" color={"light"} size="sm" onClick={() => this.openDetailsDialog(row.id)}>{<i className="fas fa-bars" />}</Button>}
                    </span>
            }
        ]

        const detailsModal = <DraggableModal
            isOpen={auditLogdetailModal}
            toggle={this.closeDetailModal}
            size={"md"}
            title={<strong>{t("details")}</strong>}
            body={
                <pre>
                    {JSON.stringify(this.state.selectedParams, null, 2)}
                </pre>
            }
        />

        return (
            <div className="content content-company">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={auditLogColumns}
                                        data={auditLogs}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationPerPage={size}
                                        paginationRowsPerPageOptions={[10, 20, 30, 40]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                    {detailsModal}
                </Row>
            </div>
        );
    }
}

AuditLogs.propTypes = {
    auditLogData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auditLogData: state.auditLogData
})

const mapActionsToProps = { getAuditLogs, getAuditLogDetails }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(AuditLogs)));