
import axios from 'axios';
import i18next from 'i18next';
import store from 'redux/store';
import { SET_USER_SESSIONS } from 'redux/types';

export const getUserSessions = (data) => async (dispatch) => {
    await axios.post('/v1/auth/session/sessions', data)
        .then(response => {
            dispatch({
                type: SET_USER_SESSIONS,
                payload: response.data.sessions
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_SESSIONS,
                payload: []
            })
        })
}

export const deleteUserSession = (toastManager, data) => async (dispatch) => {
    await axios.delete('/v1/auth/session/sessions/delete', { data })
        .then(response => {
            store.dispatch(getUserSessions(data))
            toastManager.add(i18next.t("delete_user_session_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("delete_user_session_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}

export const deleteAllUserSessions = (toastManager, data) => async (dispatch) => {
    return await axios.delete('/v1/auth/session/sessions/delete-all', { data })
        .then(response => {
            toastManager.add(i18next.t("delete_all_user_sessions_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
            return response.status
        })
        .catch(error => {
            toastManager.add(i18next.t("delete_all_user_sessions_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}