import {
    SET_DIETS,
    CREATE_DIET,
    DELETE_DIET,
    SET_NUTRIENT_TYPES,
    DELETE_DIET_INFO,
    SET_DIET_WAS_DELETED,
    SET_COPY_DIET_LOADING,
    SET_DIET_DELETE_MODAL
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createDiet = (toastManager, newDiet, history) => (dispatch) => {
    axios.post('/v1/diet', newDiet)
        .then(response => {
            dispatch({
                type: CREATE_DIET,
                payload: response.data.diet
            });
            history.push('/admin/dietList')
            toastManager.add(i18next.t("diet_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateDiet = (toastManager, id, updatedDiet, history) => () => {
    axios.patch(`/v1/diet/${id}`, updatedDiet)
        .then(response => {
            history.push('/admin/dietList')
            toastManager.add(i18next.t("diet_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateDietApproved = (id, approved, toastManager) => () => {
    axios.put(`/v1/diet/${id}?approved=${approved}`)
        .then(response => {
            toastManager.add(i18next.t("diet_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getDiets = (page, size) => (dispatch) => {
    axios.get(`/v1/diet?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_DIETS,
                payload: response.data.diets
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DIETS,
                payload: []
            })
        })
}

export const searchDiet = (page, size, search) => (dispatch) => {
    axios.get(`/v1/diet/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_DIETS,
                payload: response.data.diets
            });
        })
        .catch(error => {
            dispatch({
                type: SET_DIETS,
                payload: []
            })
        })
}

export const deleteDiet = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/diet/${id}`)
        .then((response) => {
            if (response.data.info.code === 400) {
                dispatch({
                    type: DELETE_DIET_INFO,
                    payload: {
                        users: response.data.users,
                        menus: response.data.menus
                    }
                })
                toastManager.add(i18next.t("error_detele_diet"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                dispatch({
                    type: DELETE_DIET,
                    payload: id
                })
                dispatch({
                    type: SET_DIET_WAS_DELETED,
                    payload: true
                })
                dispatch(getDiets(0, 10))
                toastManager.add(i18next.t("diet_deleted_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                })
                dispatch({
                    type: SET_DIET_DELETE_MODAL,
                    payload: false
                })
            }
        })
        .catch(error => {
            if (error.response.data.code === 400) {
                toastManager.add(i18next.t("error_detele_diet"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 5000
                })
            }

        })
}

export const getNutrientTypes = () => (dispatch) => {
    axios.get(`/v1/diet/nutrientTypes`)
        .then(response => {
            dispatch({
                type: SET_NUTRIENT_TYPES,
                payload: response.data.nutrientTypes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_NUTRIENT_TYPES,
                payload: []
            })
        })
}

export const copySelectedDiets = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_DIET_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/diet/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("diets_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_DIET_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("diets_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_DIET_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })

        })
}

export const copyAllDiets = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_DIET_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post(`/v1/diet/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("diets_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_DIET_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("diets_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_DIET_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}