import { SET_AUDIT_LOGS, SET_AUDIT_LOG_DETAILS } from '../types';

const initialState = {
    auditLogs: [],
    size: 20,
    totalElements: 0,
    auditDetails: null
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUDIT_LOGS:
            return {
                ...state,
                auditLogs: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case SET_AUDIT_LOG_DETAILS:
            return {
                ...state,
                auditDetails: action.payload
            }
        default:
            return state;
    }
}
