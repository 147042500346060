import { SET_MEALS, CREATE_MEAL, DELETE_MEAL, SET_MEAL_DISHES, SET_MACRONUTRIENTS_ON_MEAL, SET_MICRONUTRIENTS_ON_MEAL, SET_MINERAL_TABLE_ON_MEAL, SET_COPY_MEAL_LOADING } from '../types';

const initialState = {
    meals: [],
    dishes: [],
    size: 10,
    totalElements: 0,
    macronutrientChartData: {},
    macronutrientChartOptions: {},
    micronutrientsChartData: {},
    micronutrientsChartOptions: {},
    macronutrientRatio: [],
    macronutrients: {},
    micronutrients: [],
    minerals: [],
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_MEALS:
            return {
                ...state,
                meals: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_MEAL:
            return {
                ...state,
                meals: [
                    action.payload,
                    ...state.meals
                ]
            }
        case SET_MEAL_DISHES:
            return {
                ...state,
                dishes: action.payload
            }
        case SET_MACRONUTRIENTS_ON_MEAL:
            return {
                ...state,
                macronutrientChartData: action.payload.macronutrientChartData,
                macronutrientChartOptions: action.payload.macronutrientChartOptions,
                macronutrients: action.payload.macronutrients,
                macronutrientRatio: action.payload.macronutrientRatio
            }
        case SET_MICRONUTRIENTS_ON_MEAL:
            return {
                ...state,
                micronutrientsChartData: action.payload.micronutrientsChartData,
                micronutrientsChartOptions: action.payload.micronutrientsChartOptions,
                micronutrients: action.payload.vitaminTable
            }
        case SET_MINERAL_TABLE_ON_MEAL:
            return {
                ...state,
                minerals: action.payload
            }
        case DELETE_MEAL:
            let index = state.meals.findIndex((meal) => meal.id === action.payload)
            state.meals.splice(index, 1);
            return {
                ...state
            }
        case SET_COPY_MEAL_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        default:
            return state;
    }
}
