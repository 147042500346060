import {
    SET_MEALS,
    CREATE_MEAL,
    DELETE_MEAL,
    SET_MEAL_DISHES,
    SET_MACRONUTRIENTS_ON_MEAL,
    SET_MICRONUTRIENTS_ON_MEAL,
    SET_MINERAL_TABLE_ON_MEAL,
    SET_COPY_MEAL_LOADING
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createMeal = (toastManager, newMeal, history) => (dispatch) => {
    axios.post('/v1/meal', newMeal)
        .then(response => {
            dispatch({
                type: CREATE_MEAL,
                payload: response.data.meal
            });
            history.push('/admin/mealList')
            toastManager.add(i18next.t("meal_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateMeal = (toastManager, id, updatedMeal, history) => () => {
    axios.patch(`/v1/meal/${id}`, updatedMeal)
        .then(response => {
            history.push('/admin/mealList')
            toastManager.add(i18next.t("meal_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateMealApproved = (id, approved, toastManager) => () => {
    axios.put(`/v1/meal/${id}?approved=${approved}`)
        .then(response => {
            toastManager.add(i18next.t("meal_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.status === 400) {
                toastManager.add(i18next.t("update_meal_error_message"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getMeals = (page, size) => (dispatch) => {
    axios.get(`/v1/meal?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_MEALS,
                payload: response.data.meals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MEALS,
                payload: []
            })
        })
}

export const deleteMeal = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/meal/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_MEAL,
                payload: id
            })
            toastManager.add(i18next.t("meal_delete_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getMeals(0, 10))
        })
        .catch(error => {
            if (error.response.status === 400) {
                toastManager.add(i18next.t("delete_meal_error_message"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const searchMeal = (page, size, search) => (dispatch) => {
    axios.get(`/v1/meal/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_MEALS,
                payload: response.data.meals
            });
        })
        .catch(error => {
            dispatch({
                type: SET_MEALS,
                payload: []
            })
        })
}

export const getDishes = () => (dispatch) => {
    axios.get(`/v1/dish/forMeal`)
        .then(response => {
            dispatch({
                type: SET_MEAL_DISHES,
                payload: response.data.dishes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MEAL_DISHES,
                payload: []
            })
        })
}

export const getMacronutrients = (dishData) => (dispatch) => {
    axios.post(`/v1/meal/macronutrients`, dishData)
        .then(response => {
            const nutrientSum = response.data.macronutrients.carbohydratesKcal + response.data.macronutrients.fatKcal + response.data.macronutrients.proteinKcal;
            const macronutrientData = {
                macronutrients: { carbohydrates: response.data.macronutrients.carbohydrates, fat: response.data.macronutrients.fat, protein: response.data.macronutrients.protein, energy: response.data.macronutrients.energy, fibre: response.data.macronutrients.fibre },
                macronutrientRatio: { carbohydrateRatio: response.data.macronutrients.carbohydrateRatio, fatRatio: response.data.macronutrients.fatRatio, proteinRatio: response.data.macronutrients.proteinRatio },
                macronutrientChartData: {
                    labels: [
                        i18next.t('carbohydrate'),
                        i18next.t('fat'),
                        i18next.t('protein')
                    ],
                    datasets: [{
                        data: [
                            response.data.macronutrients.carbohydratesKcal,
                            response.data.macronutrients.fatKcal,
                            response.data.macronutrients.proteinKcal,
                        ],
                        backgroundColor: [
                            '#FCF955',
                            '#F76A6E',
                            '#68A2EB',
                        ],
                        hoverBackgroundColor: [
                            '#FCF955',
                            '#F76A6E',
                            '#68A2EB',
                        ]
                    }]
                },
                macronutrientChartOptions: {
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function (tooltipItem, data) {
                                var dataset = data['datasets'][0];
                                var percent = ((dataset['data'][tooltipItem['index']] / nutrientSum) * 100).toFixed(1);

                                return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']]} kcal (${percent}%)`;
                            }
                        },
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        titleFontSize: 16,
                        titleFontColor: '#fff',
                        bodyFontColor: '#eaeaea',
                        bodyFontSize: 14,
                        displayColors: false
                    }
                }
            }
            dispatch({
                type: SET_MACRONUTRIENTS_ON_MEAL,
                payload: macronutrientData
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MACRONUTRIENTS_ON_MEAL,
                payload: {
                    macronutrientChartData: [],
                    macronutrientChartOptions: null,
                    macronutrients: null,
                    macronutrientRatio: []
                }
            })
        })
}

export const getMicronutrients = (dishData) => (dispatch) => {
    axios.post(`/v1/meal/vitamins`, dishData)
        .then(response => {
            const data = response.data.vitamins.map(vitamins => {
                if (vitamins.vitaminName === "A") {
                    return vitamins.value / 1000
                }
                else if (vitamins.vitaminName === "D") {
                    return vitamins.value / 1000
                }
                else if (vitamins.vitaminName === "D3") {
                    return vitamins.value / 1000
                }
                else if (vitamins.vitaminName === "B9") {
                    return vitamins.value / 1000
                }
                else if (vitamins.vitaminName === "B12") {
                    return vitamins.value / 1000
                }
                else {
                    return vitamins.value
                }

            })
            const micronutrientSum = data[0] + data[1] + data[2] + data[3] + data[4] + data[5] + data[6] + data[7] + data[8] + data[9] + data[10] + data[11] + data[12] + data[13];
            const micronutrientData = {
                vitaminTable: response.data.vitamins,
                micronutrientsChartData: {
                    labels: [
                        i18next.t('A'),
                        i18next.t('E'),
                        i18next.t('D'),
                        i18next.t('D3'),
                        i18next.t('K'),
                        i18next.t('B1'),
                        i18next.t('B2'),
                        i18next.t('B3'),
                        i18next.t('B5'),
                        i18next.t('B6'),
                        i18next.t('B7'),
                        i18next.t('B9'),
                        i18next.t('B12'),
                        i18next.t('C')
                    ],
                    datasets: [{
                        data: [
                            data[0],
                            data[1],
                            data[2],
                            data[3],
                            data[4],
                            data[5],
                            data[6],
                            data[7],
                            data[8],
                            data[9],
                            data[10],
                            data[11],
                            data[12],
                            data[13]
                        ],
                        backgroundColor: [
                            '#d81b60',
                            '#8e24aa',
                            '#5e35b1',
                            '#3949ab',
                            '#00acc1',
                            '#00897b',
                            '#43a047',
                            '#c0ca33',
                            '#ffb300',
                            '#f4511e',
                            '#6d4c41',
                            '#bdbdbd',
                            '#546e7a',
                            '#ff5252'
                        ],
                        hoverBackgroundColor: [
                            '#d81b60',
                            '#8e24aa',
                            '#5e35b1',
                            '#3949ab',
                            '#00acc1',
                            '#00897b',
                            '#43a047',
                            '#c0ca33',
                            '#ffb300',
                            '#f4511e',
                            '#6d4c41',
                            '#bdbdbd',
                            '#546e7a',
                            '#ff5252'
                        ]
                    }]
                },
                micronutrientsChartOptions: {
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function (tooltipItem, data) {
                                var dataset = data['datasets'][0];
                                var percent = ((dataset['data'][tooltipItem['index']] / micronutrientSum) * 100).toFixed(1);
                                if (data['labels'][tooltipItem['index']] === i18next.t('A')) {
                                    return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']] * 1000} μg (${percent}%)`;
                                } else if (data['labels'][tooltipItem['index']] === i18next.t('D')) {
                                    return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']] * 1000} μg (${percent}%)`;
                                } else if (data['labels'][tooltipItem['index']] === i18next.t('D3')) {
                                    return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']] * 1000} μg (${percent}%)`;
                                } else if (data['labels'][tooltipItem['index']] === i18next.t('B12')) {
                                    return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']] * 1000} μg (${percent}%)`;
                                } else if (data['labels'][tooltipItem['index']] === i18next.t('B9-folic acid')) {
                                    return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']] * 1000} μg (${percent}%)`;
                                }
                                return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']]} mg(${percent}%)`;
                            }
                        },
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        titleFontSize: 16,
                        titleFontColor: '#fff',
                        bodyFontColor: '#eaeaea',
                        bodyFontSize: 14,
                        displayColors: false
                    }
                }
            }
            dispatch({
                type: SET_MICRONUTRIENTS_ON_MEAL,
                payload: micronutrientData
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MICRONUTRIENTS_ON_MEAL,
                payload: {
                    micronutrientsChartData: [],
                    micronutrientsChartOptions: null,
                    micronutrients: []
                }
            })
        })
}

export const getMinerals = (dishData) => (dispatch) => {
    axios.post(`/v1/meal/minerals`, dishData)
        .then(response => {
            dispatch({
                type: SET_MINERAL_TABLE_ON_MEAL,
                payload: response.data.minerals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MINERAL_TABLE_ON_MEAL,
                payload: []
            })
        })
}

export const copySelectedMeals = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_MEAL_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/meal/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("meals_copied_success_company"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_MEAL_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("meals_copied_error_company"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_MEAL_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}

export const copyAllMeals = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_MEAL_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post(`/v1/meal/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("meals_copied_success_company"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_MEAL_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("meals_copied_error_company"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_MEAL_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}
