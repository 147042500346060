import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Card, FormGroup, Col, Table, CardHeader, CardBody, CardTitle, UncontrolledTooltip, Alert } from "reactstrap";
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar'
import NumberFormat from 'react-number-format';
import { hasRole } from '../../util/hasRole';

class FoodsCard extends Component {

    handleChangeFoods = (index, event) => {
        this.props.handleChangeFoods(index, event)
    }

    toggleEditable = (index) => {
        this.props.toggleEditable(index);
    }

    deleteFoodFromList = (food, index) => {
        this.props.deleteFoodFromList(food, index);
    }

    toggleModal = () => {
        this.props.toggleModal();
    }

    getMacronutrients = () => {
        this.props.getMacronutrients();
    }

    getMicronutrients = () => {
        this.props.getMicronutrients();
    }

    render() {

        const { dish, editableIndex, t, unitOfMeasurementSymbolOptions, preparationTypeOptions, approved, foodWithAllergens, foodWithFodmaps } = this.props

        const foodItems = dish.foods.map((food, index) => {
            return <tr key={index}>
                <td><Input disabled type="text" name={"food" + index} id={"food" + index} value={food.foodName} /></td>
                <td>
                    <NumberFormat disabled={index !== editableIndex} className="form-control" name={"quantity" + index} id={"quantity" + index} autoComplete="off" onChange={(event) => this.handleChangeFoods(index, event)} value={food.quantity} allowedDecimalSeparators={[",", "."]} allowNegative={false} />
                </td>
                <td>{
                    <span style={{ cursor: "pointer" }} id={"target2" + index}>
                        {index === editableIndex ?
                            <Input type="select" name={"unitOfMeasurementWithSymbol" + index} id={"unitOfMeasurementWithSymbol" + index} value={food.unitOfMeasurementWithSymbol.unitOfMeasurement === '' ? t("select_unit") : food.unitOfMeasurementWithSymbol.unitOfMeasurement} onChange={(event) => this.handleChangeFoods(index, event)} ><option disabled>{t("select_unit")}</option>
                                {unitOfMeasurementSymbolOptions}</Input> :
                            <Input disabled={index !== editableIndex} type="text" name={"unitOfMeasurementWithSymbol" + index} id={"unitOfMeasurementWithSymbol" + index} value={t(food.unitOfMeasurementWithSymbol.symbol)} />}
                        {index !== editableIndex ? <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"target2" + index} className="uTooltip uTooltip-title">
                            <p style={{ color: 'red' }}>{t(food.unitOfMeasurementWithSymbol.unitOfMeasurement)}</p>
                            {food.gramsByPiece && <p style={{ color: 'red' }}>{t("grams_by_piece")}{': '}{food.gramsByPiece}</p>}
                        </UncontrolledTooltip> : null}
                    </span>
                }
                </td>
                <td>{index === editableIndex ?
                    <Input type="select" name={"preparationType" + index} id={"preparationType" + index} value={food.preparationType === '' ? t("select_preparation_type") : food.preparationType} onChange={(event) => this.handleChangeFoods(index, event)} ><option disabled>{t("select_preparation_type")}</option>
                        {preparationTypeOptions}</Input> :
                    <Input disabled={index !== editableIndex} type="text" name={"preparationType" + index} id={"preparationType" + index} value={t(food.preparationType)} />}
                </td>
                <td>
                    {index !== editableIndex ? <Button className="btn-icon btn-round" type="button" color="info" size="sm" onClick={() => this.toggleEditable(index)}><i className="tim-icons icon-settings-gear-63"></i></Button> : <Button className="btn-icon btn-round" type="button" color="success" size="sm" onClick={() => this.toggleEditable(-1)}><i className="tim-icons icon-check-2"></i></Button>}{' '}
                    <Button className="btn-icon btn-round" type="button" color="warning" size="sm" onClick={() => this.deleteFoodFromList(food, index)}><i className="tim-icons icon-simple-delete"></i></Button>
                </td>
            </tr>
        });

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <div style={{ display: 'flex' }}>
                        <CardTitle tag="h3"><strong>{t("foods")}</strong></CardTitle>
                        <Button style={{ marginLeft: '20px', height: '28px' }} type="button" color="success" size="sm" onClick={this.toggleModal}>{t("add_food")}</Button>
                    </div>
                    {foodWithAllergens.length > 0 && <div>
                        <Alert color="warning" style={{ padding: '10px', marginBottom: '2px' }}>
                            {foodWithAllergens.map((data, index) => {
                                return <li key={index}>{data.foodName + t("has_an_allergen") + data.allergens.map(a => { return a.name })} </li>
                            })}
                        </Alert>
                    </div>}
                    {foodWithFodmaps.length > 0 && <div>
                        <Alert color="light" style={{ padding: '10px', marginBottom: '2px' }}>
                            {foodWithFodmaps.map((data, index) => {
                                return <li key={index}>{data.foodName + t("has_an_fodmap") + data.fodmaps.map(a => { return a.name })} </li>
                            })}
                        </Alert>
                    </div>}
                </CardHeader>
                <CardBody>
                    <FormGroup row inline>
                        <Col>
                            {foodItems.length > 0 ?
                                <PerfectScrollbar style={{ height: '400px' }}>
                                    <Table borderless className="tableFixHead">
                                        <thead>
                                            <tr>
                                                <th width="35%">{t("food")}</th>
                                                <th width="15%">{t("quantity")}</th>
                                                <th width="15%">{t("unit")}</th>
                                                <th width="20%">{t("preparation_type")}</th>
                                                {(hasRole('ROLE_SUPER_ADMIN') || !approved) && <th width="15%">{t("actions")}</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {foodItems}
                                        </tbody>
                                    </Table>
                                </PerfectScrollbar> : null}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        )
    }
}

FoodsCard.propTypes = {
    dish: PropTypes.object.isRequired,
    handleChangeFoods: PropTypes.func.isRequired,
    toggleEditable: PropTypes.func.isRequired,
    deleteFoodFromList: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    getMacronutrients: PropTypes.func.isRequired,
    getMicronutrients: PropTypes.func.isRequired,
    editableIndex: PropTypes.number.isRequired,
    foodWithAllergens: PropTypes.array.isRequired
}

export default withTranslation()(FoodsCard);
