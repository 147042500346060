import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, FormGroup, Input, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import { hasRole } from '../../util/hasRole';

class DishCard extends Component {

    handleChange = (e) => {
        this.props.handleChange(e);
    }

    handleChangeType = (type) => {
        this.props.handleChangeType(type);
    }

    handleChangeDishPersons = (e) => {
        this.props.handleChangeDishPersons(e)
    }

    toggleApprovedDialog = () => {
        this.props.toggleApprovedDialog();
    }

    toggleSaveAsDialog = () => {
        this.props.toggleSaveAsDialog();
    }

    render() {

        const { dishTypes, preparationTypes, dish, t, initialDish, disabledButton, changeApprovedModal, editable, id } = this.props;

        let preparationTypeOptions = preparationTypes.map((preparationType, index) => {
            return <option key={index} value={preparationType}>{t(preparationType)}</option>
        })

        let dishTypeOptions = dishTypes.map(type => {
            return {
                type: t(type),
                typeEnum: type
            }
        })

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("dish")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className={'mw-350'}>
                                <Label>{t("name")}</Label>
                                <Input
                                    name="name"
                                    type="text"
                                    onChange={(e) => this.handleChange(e)}
                                    placeholder={t("name")}
                                    value={dish.name}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup className={'mw-350'}>
                                <Label>{t("types")}</Label>
                                <Typeahead
                                    selected={dish.types}
                                    id="types"
                                    clearButton
                                    labelKey="type"
                                    onChange={this.handleChangeType}
                                    options={dishTypeOptions}
                                    multiple
                                    placeholder={t("select_types")}
                                    paginationText={t("display_additional")}
                                    emptyLabel={t("no_matches_found")}
                                />
                            </FormGroup>
                            <FormGroup className={'mw-250'}>
                                <Label>{t("base_preparation_type")}</Label>
                                <Input
                                    type="select"
                                    name="basePreparationType"
                                    value={dish.basePreparationType === null ? t("select_base_preparation_type") : dish.basePreparationType}
                                    onChange={(e) => this.handleChange(e)}>
                                    <option disabled>{t("select_base_preparation_type")}</option>
                                    {preparationTypeOptions}
                                </Input>
                            </FormGroup>
                            <FormGroup className={'mw-150'}>
                                <Label>{t("persons")}</Label>
                                <Input
                                    name="persons"
                                    type="number"
                                    min={1}
                                    onChange={(e) => this.handleChangeDishPersons(e)}
                                    value={dish.persons}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} style={{ marginTop: '20px' }}>
                            {id ?
                                <Button disabled={JSON.stringify(dish) === JSON.stringify(initialDish) || editable} type="submit" color="success"> {t("update")}</Button> :
                                <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>}
                            {(hasRole('ROLE_SUPER_ADMIN') && id) &&
                                (dish.approved ? <Button type="button" color="warning" onClick={this.toggleApprovedDialog}>{t("unapprove")}</Button>
                                    : <Button type="button" color="info" onClick={this.toggleApprovedDialog}>{t("approve")}</Button>)}
                            <Button type="button" color="info" onClick={this.toggleSaveAsDialog}> {t("save_as")}</Button>
                        </Col>
                        {changeApprovedModal}
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

DishCard.propTypes = {
    dishTypes: PropTypes.array.isRequired,
    dish: PropTypes.object.isRequired,
    initialDish: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleChangeType: PropTypes.func.isRequired,
    handleChangeDishPersons: PropTypes.func.isRequired,
    disabledButton: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]).isRequired,
    changeApprovedModal: PropTypes.element.isRequired,
    toggleApprovedDialog: PropTypes.func.isRequired,
    editable: PropTypes.bool.isRequired,
    toggleSaveAsDialog: PropTypes.func.isRequired
}

export default withTranslation()(DishCard);
