import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Draggable from 'react-draggable';

class DraggableModal extends Component {

    render() {

        const { title, body, footer, isOpen, toggle, size, footerStyle, modalStyle, className , bodyStyle} = this.props;

        return (
            <Draggable handle=".modal-header">
                <Modal isOpen={isOpen} toggle={toggle} size={size} style={modalStyle} className={className}>
                    <ModalHeader toggle={toggle}>
                        {title}
                    </ModalHeader>
                    <ModalBody style={bodyStyle}>
                        {body}
                    </ModalBody>
                    <ModalFooter style={footerStyle}>
                        {footer}
                    </ModalFooter>
                </Modal>
            </Draggable>

        )
    }
}

DraggableModal.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    footer: PropTypes.element,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func.isRequired,
    size: PropTypes.string.isRequired,
    footerStyle: PropTypes.object,
    modalStyle: PropTypes.object,
    bodyStyle: PropTypes.object,
    className: PropTypes.object
}


export default DraggableModal;