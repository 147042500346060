import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class EnergyCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { energyDetail, t } = this.props;

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("energy")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("kilojoules")}</Label>
                                <NumberFormat className="form-control" name="kilojoules" onChange={(e) => this.handleChange(e, "energyDetail")} value={energyDetail.kilojoules} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("kilocalories")}</Label>
                                <NumberFormat className="form-control" name="kcal" onChange={(e) => this.handleChange(e, "energyDetail")} value={energyDetail.kcal} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

EnergyCard.propTypes = {
    energyDetail: PropTypes.object.isRequired
}

export default withTranslation()(EnergyCard)