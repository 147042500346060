import {
    CREATE_USER,
    SET_USERS,
    SET_USER_DIETS,
    SET_USER_ROLES,
    SET_USER_DIAGNOSES,
    SET_ERRORS,
    SET_STUDENTS,
    SET_ADMINS,
    SET_USER_MENUS_TABLE,
    SET_USER_ALLERGENS,
    SET_USER_FODMAPS
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createUser = (toastManager, newUser, history) => (dispatch) => {
    axios.post('/v1/user', newUser)
        .then(response => {
            dispatch({
                type: CREATE_USER,
                payload: response.data.user
            });
            toastManager.add(i18next.t("user_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
            history.push('/admin/userList')
        })
        .catch(error => {
            dispatch({
                type: SET_ERRORS,
                payload: error.response.data
            });
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists_username"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateUser = (toastManager, username, updatedUser, history) => () => {
    axios.patch(`/v1/user/${username}`, updatedUser)
        .then(response => {
            if (updatedUser.role === "ROLE_SUPER_ADMIN") {
                window.location.href = "/"
            }
            else {
                toastManager.add(i18next.t("user_updated_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                });
                history.push('/admin/userList')
            }
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists_username"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const searchClient = (page, size, search) => (dispatch) => {
    axios.get(`/v1/user/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_USERS,
                payload: response.data.users
            });
        })
        .catch(error => {
            dispatch({
                type: SET_USERS,
                payload: []
            })
        })
}

export const searchClientByActive = (page, size, search, active) => (dispatch) => {
    axios.get(`/v1/user/searchByActive?page=${page}&size=${size}&search=${search}&active=${active}`)
        .then(response => {
            dispatch({
                type: SET_USERS,
                payload: response.data.users
            });
        })
        .catch(error => {
            dispatch({
                type: SET_USERS,
                payload: []
            })
        })
}

export const searchStudents = (page, size, search) => (dispatch) => {
    axios.get(`/v1/user/students/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_STUDENTS,
                payload: response.data.users
            });
        })
        .catch(error => {
            dispatch({
                type: SET_STUDENTS,
                payload: []
            })
        })
}

export const searchStudentsByActive = (page, size, search, active) => (dispatch) => {
    axios.get(`/v1/user/students/searchByActive?page=${page}&size=${size}&search=${search}&active=${active}`)
        .then(response => {
            dispatch({
                type: SET_STUDENTS,
                payload: response.data.users
            });
        })
        .catch(error => {
            dispatch({
                type: SET_STUDENTS,
                payload: []
            })
        })
}

export const getClients = (page, size) => async (dispatch) => {
    await axios.get(`/v1/user?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_USERS,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USERS,
                payload: []
            })
        })
}

export const getStudents = (page, size) => (dispatch) => {
    axios.get(`/v1/user/students?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_STUDENTS,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_STUDENTS,
                payload: []
            })
        })
}

export const getAdmins = (page, size) => (dispatch) => {
    axios.get(`/v1/user/admins?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_ADMINS,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_ADMINS,
                payload: []
            })
        })
}

export const getClientsByActive = (page, size, active) => (dispatch) => {
    axios.get(`/v1/user/active?page=${page}&size=${size}&active=${active}`)
        .then(response => {
            dispatch({
                type: SET_USERS,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USERS,
                payload: []
            })
        })
}

export const getStudentsByActive = (page, size, active) => (dispatch) => {
    axios.get(`/v1/user/students/active?page=${page}&size=${size}&active=${active}`)
        .then(response => {
            dispatch({
                type: SET_STUDENTS,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_STUDENTS,
                payload: []
            })
        })
}

export const getRoles = () => (dispatch) => {
    axios.get(`/v1/user/roles`)
        .then(response => {
            dispatch({
                type: SET_USER_ROLES,
                payload: response.data.roles
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_ROLES,
                payload: []
            })
        })
}
export const getDiagnoses = () => (dispatch) => {
    axios.get(`/v1/diagnosis/diagnosisForClient`)
        .then(response => {
            dispatch({
                type: SET_USER_DIAGNOSES,
                payload: response.data.diagnoses
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_DIAGNOSES,
                payload: []
            })
        })
}

export const getDiets = () => (dispatch) => {
    axios.get(`/v1/diet/simple`)
        .then(response => {
            dispatch({
                type: SET_USER_DIETS,
                payload: response.data.diets
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_DIETS,
                payload: []
            })
        })
}

export const getUserMenus = (page, size, username) => (dispatch) => {
    axios.get(`/v1/user/${username}/menus?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_USER_MENUS_TABLE,
                payload: response.data.menus
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_MENUS_TABLE,
                payload: []
            })
        })
}

export const getAllergens = () => (dispatch) => {
    axios.get(`/v1/allergen/list`)
        .then(response => {
            dispatch({
                type: SET_USER_ALLERGENS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_ALLERGENS,
                payload: []
            })
        })
}

export const getFodmaps = () => (dispatch) => {
    axios.get(`/v1/fodmap/list`)
        .then(response => {
            dispatch({
                type: SET_USER_FODMAPS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_USER_FODMAPS,
                payload: []
            })
        })
}