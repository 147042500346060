import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import AdminNavbar from "components/navbars/AdminNavbar.js";
import Footer from "components/footer/Footer.js";
import Sidebar from "components/sidebar/Sidebar.js";
import logo from "assets/img/logoSidebar.png";
// Components
import Food from "views/food/Food.js"
import Dish from "views/dish/Dish.js"
import Diet from "views/diet/Diet.js"
import User from "views/user/User.js"
import Meal from "views/meal/Meal.js"
import Diagnosis from "views/diagnosis/Diagnosis.js"
import FoodList from "views/food/FoodList.js"
import DishList from "views/dish/DishList.js"
import Dashboard from "views/Dashboard.js"
import UserList from "views/user/UserList.js"
import DietList from "views/diet/DietList.js"
import MealList from "views/meal/MealList"
import DiagnosisList from "views/diagnosis/DiagnosisList"
import MenuEditor from "views/menu/MenuEditor";
import Reports from "views/Reports"
import MenuDetails from "views/menu/MenuDetails";
import Company from "views/company/Company"
import CompanyList from "views/company/CompanyList"
// Util
import { hasRole } from '../../util/hasRole';
import { withTranslation } from 'react-i18next';
import AuditLogs from "views/AuditLogs";
import AllergenList from "views/allergens/AllergenList";
import Allergen from "views/allergens/Allergen";
import FodmapList from "views/fodmap/FodmapList";
import Fodmap from "views/fodmap/Fodmap";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "green",
      sidebarOpened:
        document.documentElement.className.indexOf("nav-open") !== -1
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  getBrandText = (path, sidebarRoutes) => {
    for (let i = 0; i < sidebarRoutes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          sidebarRoutes[i].layout + sidebarRoutes[i].path
        ) !== -1
      ) {
        return sidebarRoutes[i].name;
      }
    }
    return "";
  };
  render() {

    const { t } = this.props;

    let sidebarRoutes = [];
    if (hasRole('ROLE_SUPER_ADMIN')) {
      sidebarRoutes = [
        { path: "/dashboard", name: t("dashboard"), icon: "tim-icons icon-paper", component: Dashboard, layout: "/admin" },
        { path: "/userList", name: t("user_list"), icon: "fas fa-users", component: UserList, layout: "/admin" },
        { path: "/foodList", name: t("food_list"), icon: "fas fa-utensils", component: FoodList, layout: "/admin" },
        { path: "/dishList", name: t("dish_list"), icon: "fas fa-list-alt", component: DishList, layout: "/admin" },
        { path: "/dietList", name: t("diet_list"), icon: "fab fa-nutritionix", component: DietList, layout: "/admin" },
        { path: "/mealList", name: t("meal_list"), icon: "tim-icons icon-notes", component: MealList, layout: "/admin" },
        { path: "/diagnosisList", name: t("diagnosis_list"), icon: "fas fa-stethoscope", component: DiagnosisList, layout: "/admin" },
        { path: "/allergenList", name: t("allergens"), icon: "tim-icons icon-support-17", component: AllergenList, layout: "/admin" },
        { path: "/fodmapList", name: t("fodmaps"), icon: "tim-icons icon-molecule-40", component: FodmapList, layout: "/admin" },
        { path: "/menuEditor", name: t("menu_editor"), icon: "fas fa-calendar", component: MenuEditor, layout: "/admin" },
        { path: "/reports", name: t("reports"), icon: "fas fa-file-pdf", component: Reports, layout: "/admin" },
        { path: "/companyList", name: t("company_list"), icon: "fas fa-building", component: CompanyList, layout: "/admin" },
        { path: "/audits", name: t("audit_logs"), icon: "fa-solid fa-book", component: AuditLogs, layout: "/admin" }
      ];
    } else if (hasRole('ROLE_ADMIN')) {
      sidebarRoutes = [
        { path: "/dashboard", name: t("dashboard"), icon: "tim-icons icon-paper", component: Dashboard, layout: "/admin" },
        { path: "/userList", name: t("user_list"), icon: "fas fa-users", component: UserList, layout: "/admin" },
        { path: "/foodList", name: t("food_list"), icon: "fas fa-utensils", component: FoodList, layout: "/admin" },
        { path: "/dishList", name: t("dish_list"), icon: "fas fa-list-alt", component: DishList, layout: "/admin" },
        { path: "/dietList", name: t("diet_list"), icon: "fab fa-nutritionix", component: DietList, layout: "/admin" },
        { path: "/mealList", name: t("meal_list"), icon: "tim-icons icon-notes", component: MealList, layout: "/admin" },
        { path: "/diagnosisList", name: t("diagnosis_list"), icon: "fas fa-stethoscope", component: DiagnosisList, layout: "/admin" },
        { path: "/allergenList", name: t("allergens"), icon: "tim-icons icon-support-17", component: AllergenList, layout: "/admin" },
        { path: "/fodmapList", name: t("fodmaps"), icon: "tim-icons icon-molecule-40", component: FodmapList, layout: "/admin" },
        { path: "/menuEditor", name: t("menu_editor"), icon: "fas fa-calendar", component: MenuEditor, layout: "/admin" },
        { path: "/reports", name: t("reports"), icon: "fas fa-file-pdf", component: Reports, layout: "/admin" }
      ];
    }
    else if (hasRole('ROLE_STUDENT')) {
      sidebarRoutes = [
        { path: "/dashboard", name: t("dashboard"), icon: "tim-icons icon-paper", component: Dashboard, layout: "/admin" },
        { path: "/foodList", name: t("food_list"), icon: "fas fa-utensils", component: FoodList, layout: "/admin" },
        { path: "/dishList", name: t("dish_list"), icon: "fas fa-list-alt", component: DishList, layout: "/admin" },
        { path: "/dietList", name: t("diet_list"), icon: "fab fa-nutritionix", component: DietList, layout: "/admin" },
        { path: "/mealList", name: t("meal_list"), icon: "tim-icons icon-notes", component: MealList, layout: "/admin" }
      ]
    }

    let routes = [];
    if (hasRole('ROLE_SUPER_ADMIN')) {
      routes = [
        { path: '/admin/user/:username', component: User },
        { path: '/admin/user/', component: User },
        { path: '/admin/food/:id', component: Food },
        { path: '/admin/food', component: Food },
        { path: '/admin/dish/:id', component: Dish },
        { path: '/admin/dish', component: Dish },
        { path: '/admin/diet/:id', component: Diet },
        { path: '/admin/diet', component: Diet },
        { path: '/admin/meal/:id', component: Meal },
        { path: '/admin/meal', component: Meal },
        { path: '/admin/diagnosis/:id', component: Diagnosis },
        { path: '/admin/diagnosis', component: Diagnosis },
        { path: '/admin/allergen/:id', component: Allergen },
        { path: '/admin/allergen', component: Allergen },
        { path: '/admin/fodmap/:id', component: Fodmap },
        { path: '/admin/fodmap', component: Fodmap },
        { path: '/admin/menuDetails/:id', component: MenuDetails },
        { path: '/admin/company/:id', component: Company },
        { path: '/admin/company', component: Company },
        { path: "/admin/audits", component: AuditLogs }
      ];
    } else if (hasRole('ROLE_ADMIN')) {
      routes = [
        { path: '/admin/user/:username', component: User },
        { path: '/admin/user/', component: User },
        { path: '/admin/food/:id', component: Food },
        { path: '/admin/food', component: Food },
        { path: '/admin/dish/:id', component: Dish },
        { path: '/admin/dish', component: Dish },
        { path: '/admin/diet/:id', component: Diet },
        { path: '/admin/diet', component: Diet },
        { path: '/admin/meal/:id', component: Meal },
        { path: '/admin/meal', component: Meal },
        { path: '/admin/diagnosis/:id', component: Diagnosis },
        { path: '/admin/diagnosis', component: Diagnosis },
        { path: '/admin/allergen/:id', component: Allergen },
        { path: '/admin/allergen', component: Allergen },
        { path: '/admin/fodmap/:id', component: Fodmap },
        { path: '/admin/fodmap', component: Fodmap },
        { path: '/admin/menuDetails/:id', component: MenuDetails }
      ];
    }
    else if (hasRole('ROLE_STUDENT')) {
      routes = [
        { path: '/admin/food/:id', component: Food },
        { path: '/admin/food', component: Food },
        { path: '/admin/dish/:id', component: Dish },
        { path: '/admin/dish', component: Dish },
        { path: '/admin/diet/:id', component: Diet },
        { path: '/admin/diet', component: Diet },
        { path: '/admin/meal/:id', component: Meal },
        { path: '/admin/meal', component: Meal }
      ]
    }

    return (
      <>
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={sidebarRoutes}
            bgColor={this.state.backgroundColor}
            logo={{
              text: "Nutrition Manager",
              imgSrc: logo
            }}
            toggleSidebar={this.toggleSidebar}
          />
          <div
            className="main-panel"
            ref="mainPanel"
            data={this.state.backgroundColor}

          >
            <AdminNavbar
              {...this.props}
              brandText={this.getBrandText(this.props.location.pathname, sidebarRoutes)}
              toggleSidebar={this.toggleSidebar}
              sidebarOpened={this.state.sidebarOpened}
            />
            <Switch>
              {this.getRoutes(sidebarRoutes)}
              {routes.map((route, key) => {
                return <Route path={route.path} component={route.component} key={key} />
              })}
              <Redirect from="*" to="/admin/dashboard" />
            </Switch>
            {
              this.props.location.pathname.indexOf("maps") !== -1 ? null : (
                <Footer fluid />
              )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Admin)
