import { SET_REPORTS_ENTRIES } from '../types';

const initialState = {
    reportEntries: [],
    isLoading: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_REPORTS_ENTRIES:
            return {
                ...state,
                reportEntries: action.payload,
                isLoading: false
            }
        default:
            return state;
    }
}
