import React from "react";
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Button, Label, TabContent, TabPane, Nav, NavItem, NavLink, CardBody, Input } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
// Redux
import { connect } from 'react-redux';
import { exportNutrientDataForMeal, getExportMacronutrients, getExportCalories, getExportAllData, getExportFoodCategory } from '../redux/actions/reportsActions';
import { getCategories } from '../redux/actions/foodActions';
import { getMeals, getClientsByDiet } from '../redux/actions/menuEditorActions';
import { getDiets } from '../redux/actions/userActions';

const inactiveStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff #ffffff #e3e3e3'
};

const activeStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#e3e3e3 #e3e3e3 #ba54f5'
};

class Reports extends React.Component {

    emptyReport = {
        date: moment(new Date()),
        selectExport: '',
        startDay: null,
        endDay: null,
        macronutrientsSelect: true,
        vitaminsSelect: true,
        mineralsSelect: true
    }

    emptyMeal = {
        id: null,
        name: '',
    }

    constructor(props) {
        super(props);
        this.state = {
            report: this.emptyReport,
            initialReport: this.emptyReport,
            activeTab: '1',
            startDay: moment().startOf('isoweek'),
            endDay: moment().endOf('isoweek'),
            selectedDateForPicker: moment(),
            selectedMeal: this.emptyMeal,
            selectedDiet: '',
            selectedClient: [],
            selectedFoodCategory: ''
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.getMeals();
        this.props.getDiets();
        this.props.getCategories();
    }

    toggleActiveTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
                selectedDiet: '',
                selectedClient: '',
                report: this.emptyReport
            });
        }
    }

    handleChangeDate = (date) => {
        this.setState({ report: { ...this.state.report, startDay: date, endDay: date } });
    }

    handleChangeDateStartFrom = (date) => {
        this.setState({ report: { ...this.state.report, startDay: date } });
    }

    handleChangeDateEndTo = (date) => {
        this.setState({ report: { ...this.state.report, endDay: date } });
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let report = { ...this.state.report };
        report[name] = value;

        this.setState({ report: report });
    }

    handleChangeDateWeekly = (date) => {
        if (date !== null) {
            this.setState({ report: { ...this.state.report, startDay: date.clone().startOf('isoweek'), endDay: date.clone().endOf('isoweek') } });
            this.setState({
                selectedDateForPicker: date,
                startDay: date.clone().startOf('isoweek'),
                endDay: date.clone().endOf('isoweek')
            })
        }
    }

    handleChangeMeal = (meal) => {
        let { selectedMeal } = this.state;

        if (meal[0] === undefined) {
            selectedMeal.id = null;
            selectedMeal.name = '';
        } else {
            selectedMeal.id = meal[0].id;
            selectedMeal.name = meal[0].name;
        }

        this.setState({ selectedMeal: selectedMeal });
    }

    handleChangeDiet = (diet) => {
        let selectedDiet = { ...this.state.selectedDiet };
        if (diet.length > 0) {
            selectedDiet = diet[0]
            this.props.auth.usingClientSpecificMenus && this.props.getClientsByDiet(diet[0].id)
        } else {
            selectedDiet = ''
            this.props.menuEditorData.menuByDiet = []
        }

        this.setState({ selectedDiet: selectedDiet })
    }

    handleSubmitExport = (type) => {
        const { selectedDiet, report, selectedClient, startDay, endDay, selectedFoodCategory } = this.state;
        let timeIntervalObject = {
            startDay: startDay.format("YYYY-MM-DD"),
            endDay: endDay.format('YYYY-MM-DD')
        }

        const requestData = {
            username: selectedClient ? selectedClient.username : null,
            dietId: selectedDiet.id,
            timeInterval: type === "weekly" ? timeIntervalObject : {
                startDay: report.startDay.format('YYYY-MM-DD'),
                endDay: report.endDay.format('YYYY-MM-DD')
            },
            macronutrients: report.macronutrientsSelect,
            vitamins: report.vitaminsSelect,
            minerals: report.mineralsSelect,
        }

        const foodCategoryRequest = type === "foodCategory" && {
            category: selectedFoodCategory,
            timeInterval: {
                startDay: report.startDay.format('YYYY-MM-DD'),
                endDay: report.endDay.format('YYYY-MM-DD')
            }
        }

        if (report.selectExport === "export_nutritional_data") {
            this.props.getExportMacronutrients(requestData, this.toastManager)
        }
        else if (report.selectExport === "export_calorie_data") {
            this.props.getExportCalories(requestData, this.toastManager)
        }
        else if (type === "foodCategory") {
            this.props.getExportFoodCategory(foodCategoryRequest, this.toastManager)
        }
        else {
            this.props.getExportAllData(requestData, this.toastManager)
        }
        this.setState({ report: this.emptyReport, selectedFoodCategory: '', selectedDiet: '', selectedClient: [] })
    }

    exportNutrientDataForMeal = () => {
        let { selectedMeal, report } = this.state
        let requestBody = {
            mealId: selectedMeal.id,
            macronutrients: report.macronutrientsSelect,
            vitamins: report.vitaminsSelect,
            minerals: report.mineralsSelect,

        }
        this.props.exportNutrientDataForMeal(requestBody, this.toastManager)
        this.setState({ selectedMeal: { id: null, name: '' }, report: this.emptyReport })
    }

    handleChangeClient = (client) => {
        let selectedClient = { ...this.state.selectedClient }
        if (client.length > 0) {
            selectedClient = client[0]
        } else {
            selectedClient = null;
        }
        this.setState({ selectedClient: selectedClient })
    }

    handleChangeFoodCategory = (category) => {
        let selectedFoodCategory = { ...this.state.selectedFoodCategory }
        if (category.length > 0) {
            selectedFoodCategory = category[0].categoryEnum;
        } else {
            selectedFoodCategory = '';
        }
        this.setState({ selectedFoodCategory: selectedFoodCategory });
    }

    disabledButton = (type) => {
        let { report, selectedDiet, selectedFoodCategory } = this.state
        let valid = true;
        if (type === "day") {
            if (!selectedDiet || !report.startDay || !report.selectExport) {
                valid = false
            }
        }
        if (type === "week") {
            if (!selectedDiet || !report.selectExport) {
                valid = false
            }
        }
        if (type === "custom") {
            if (!selectedDiet || !report.startDay || !report.endDay || !report.selectExport) {
                valid = false
            }
        }
        if (type === "category") {
            if (!report.startDay || !report.endDay || !selectedFoodCategory) {
                valid = false
            }
        }
        return valid;
    }

    render() {

        const { report, startDay, selectedMeal, selectedDiet, selectedClient, selectedFoodCategory } = this.state;
        const { isLoading } = this.props.reportsData;
        const { diets } = this.props.userData;
        const { categories } = this.props.foodData;
        const { usingClientSpecificMenus } = this.props.auth;
        const { meals, clients } = this.props.menuEditorData;
        const { t } = this.props;

        let foodCategoryOptions = categories.map(category => {
            return {
                categories: t(category),
                categoryEnum: category
            }
        })

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        const mutipleSelect = <>
            <FormGroup check inline>
                <Label check>
                    <Input type="checkbox" name="macronutrientsSelect" id="macronutrients" value="macronutrientsSelect" onChange={this.handleChange} checked={report.macronutrientsSelect} />
                    {t("macronutrients")}
                    <span className="form-check-sign"></span>
                </Label>
            </FormGroup>
            <FormGroup check inline>
                <Label check>
                    <Input type="checkbox" name="vitaminsSelect" id="minerals" value="vitaminsSelect" onChange={this.handleChange} checked={report.vitaminsSelect} />
                    {t("vitamins")}
                    <span className="form-check-sign"></span>
                </Label>
            </FormGroup>
            <FormGroup check inline>
                <Label check>
                    <Input type="checkbox" name="mineralsSelect" id="minerals" value="mineralsSelect" onChange={this.handleChange} checked={report.mineralsSelect} />
                    {t("minerals")}
                    <span className="form-check-sign"></span>
                </Label>
            </FormGroup></>

        return (
            <div className="content content-report">
                <Row>
                    <Col sm={8} md={7} lg={6} xl={6} className={'col-xxl-5 col-wxl-4'}>
                        <Card>
                            <CardBody>
                                <Nav tabs className={'nav-tabs-custom'}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '1'}
                                            style={this.state.activeTab === "1" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('1')}>
                                            {t("days")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '2'}
                                            style={this.state.activeTab === "2" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('2')}>
                                            {t("week")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '3'}
                                            style={this.state.activeTab === "3" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('3')}>
                                            {t("custom")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '4'}
                                            style={this.state.activeTab === "4" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('4')}>
                                            {t("meal")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '5'}
                                            style={this.state.activeTab === "5" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('5')}>
                                            {t("food_category")}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <br />
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-250'}>
                                                    <Label>{t("select_diet")}</Label>
                                                    <Typeahead
                                                        selected={diets && diets.filter(d => d.name === selectedDiet.name)}
                                                        id="diet"
                                                        labelKey="name"
                                                        clearButton
                                                        onChange={this.handleChangeDiet}
                                                        options={diets}
                                                        placeholder={t("select_diet")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {selectedDiet && usingClientSpecificMenus &&
                                            <Row>
                                                <Col>
                                                    <FormGroup className={'datepicker-wrapper mw-320'}>
                                                        <Label>{t("select_client")}</Label>
                                                        <Typeahead
                                                            selected={selectedClient === null ? '' : clients.filter(c => c.username === selectedClient.username)}
                                                            id="client"
                                                            clearButton
                                                            labelKey={option => `${option.firstName} ${option.lastName}`}
                                                            onChange={this.handleChangeClient}
                                                            options={clients}
                                                            placeholder={t("select_client")}
                                                            paginationText={t("display_additional")}
                                                            emptyLabel={t("no_matches_found")}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>}

                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-150'}>
                                                    <Label>{t("date")}</Label>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        selected={report.startDay}
                                                        onChange={this.handleChangeDate}
                                                        dateFormat="DD.MM.yyyy."
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText={t("date")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup className={'mw-250'}>
                                                    <Label>{t("select_export_data")}</Label>
                                                    <Input
                                                        type="select"
                                                        name="selectExport"
                                                        value={report.selectExport === '' ? t("select_export_data") : report.selectExport}
                                                        onChange={this.handleChange}>
                                                        <option disabled>{t("select_export_data")}</option>
                                                        <option value="export_nutritional_data" >{t("export_nutritional_data")}</option>
                                                        <option value="export_calorie_data" >{t("export_calorie_data")}</option>
                                                        <option value="all" >{t("all")}</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {report.selectExport === 'all' && <Row>
                                            <Col>
                                                {mutipleSelect}
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col>
                                                <Button style={{ float: 'right' }} type="button" color="success" disabled={!this.disabledButton("day")} onClick={() => this.handleSubmitExport("daily")}>{t("export")}</Button>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-250'}>
                                                    <Label>{t("select_diet")}</Label>
                                                    <Typeahead
                                                        selected={diets && diets.filter(d => d.name === selectedDiet.name)}
                                                        id="diet"
                                                        clearButton
                                                        labelKey="name"
                                                        onChange={this.handleChangeDiet}
                                                        options={diets}
                                                        placeholder={t("select_diet")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {selectedDiet && usingClientSpecificMenus && <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-320'}>
                                                    <Label>{t("select_client")}</Label>
                                                    <Typeahead
                                                        selected={selectedClient === null ? '' : clients.filter(c => c.username === selectedClient.username)}
                                                        id="client"
                                                        clearButton
                                                        labelKey={option => `${option.firstName} ${option.lastName}`}
                                                        onChange={this.handleChangeClient}
                                                        options={clients}
                                                        placeholder={t("select_client")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>}
                                        <Row>
                                            <Col className={'mw-180'}>
                                                <FormGroup>
                                                    <Label>{t("start_date")}</Label>
                                                    <Input type="text" value={startDay.format('DD.MM.YYYY')} readOnly></Input>
                                                </FormGroup>
                                            </Col>
                                            <Col className={'mw-100'}>
                                                <Label>{t("date")}</Label>
                                                <FormGroup className={'datepicker-wrapper'}>
                                                    <DatePicker
                                                        onChange={this.handleChangeDateWeekly}
                                                        dateFormat="DD.MM.yyyy."
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText={t("date")}
                                                        customInput={
                                                            <Button className="btn-icon btn-round" color="info" size="sm">
                                                                <i className="fas fa-calendar" />
                                                            </Button>}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className={'mw-150'}>
                                            <Label>{t("end_date")}</Label>
                                            <Input type="text" value={this.state.endDay.format('DD.MM.YYYY')} readOnly></Input>
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <FormGroup className={'mw-250'}>
                                                    <Label>{t("select_export_data")}</Label>
                                                    <Input
                                                        type="select"
                                                        name="selectExport"
                                                        value={report.selectExport === '' ? t("select_export_data") : report.selectExport}
                                                        onChange={this.handleChange}>
                                                        <option disabled>{t("select_export_data")}</option>
                                                        <option value="export_nutritional_data" >{t("export_nutritional_data")}</option>
                                                        <option value="export_calorie_data" >{t("export_calorie_data")}</option>
                                                        <option value="all" >{t("all")}</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            {report.selectExport === 'all' && <Col xs={12}>
                                                {mutipleSelect}
                                            </Col>}
                                            <Col xs={12}>
                                                <Button style={{ float: 'right' }} disabled={!this.disabledButton("week")} color="success" onClick={() => this.handleSubmitExport("weekly")}>{t("export")}</Button>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-250'}>
                                                    <Label>{t("select_diet")}</Label>
                                                    <Typeahead
                                                        selected={diets && diets.filter(d => d.name === selectedDiet.name)}
                                                        id="diet"
                                                        clearButton
                                                        labelKey="name"
                                                        onChange={this.handleChangeDiet}
                                                        options={diets}
                                                        placeholder={t("select_diet")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                                {selectedDiet && usingClientSpecificMenus && <FormGroup className={'datepicker-wrapper mw-320'}>
                                                    <Label>{t("select_client")}</Label>
                                                    <Typeahead
                                                        selected={selectedClient === null ? '' : clients.filter(c => c.username === selectedClient.username)}
                                                        id="client"
                                                        clearButton
                                                        labelKey={option => `${option.firstName} ${option.lastName}`}
                                                        onChange={this.handleChangeClient}
                                                        options={clients}
                                                        placeholder={t("select_client")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>}
                                                <FormGroup className={'datepicker-wrapper mw-150'}>
                                                    <Label>{t("from")}</Label>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        selected={report.startDay}
                                                        onChange={this.handleChangeDateStartFrom}
                                                        dateFormat="DD.MM.yyyy."
                                                        selectsStart
                                                        startDate={report.startDay}
                                                        endDate={report.endDay}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText={t("from")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={'datepicker-wrapper mw-150'}>
                                                    <Label>{t("to")}</Label>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        selected={report.endDay}
                                                        onChange={this.handleChangeDateEndTo}
                                                        dateFormat="DD.MM.yyyy."
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selectsEnd
                                                        startDate={report.startDay}
                                                        endDate={report.endDay}
                                                        minDate={report.startDay}
                                                        dropdownMode="select"
                                                        placeholderText={t("to")}
                                                    />
                                                </FormGroup>
                                                <Row>
                                                    <Col>
                                                        <FormGroup className={'mw-250'}>
                                                            <Label>{t("select_export_data")}</Label>
                                                            <Input
                                                                type="select"
                                                                name="selectExport"
                                                                value={report.selectExport === '' ? t("select_export_data") : report.selectExport}
                                                                onChange={this.handleChange}>
                                                                <option disabled>{t("select_export_data")}</option>
                                                                <option value="export_nutritional_data" >{t("export_nutritional_data")}</option>
                                                                <option value="export_calorie_data" >{t("export_calorie_data")}</option>
                                                                <option value="all" >{t("all")}</option>
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {report.selectExport === 'all' && <Row>
                                                    <Col>
                                                        {mutipleSelect}
                                                    </Col>
                                                </Row>}
                                                <Button style={{ float: 'right' }} color="success" disabled={!this.disabledButton("custom")} onClick={() => this.handleSubmitExport("custom")}>{t("export")}</Button>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-350'}>
                                                    <Label>{t("meal")}</Label>
                                                    <Typeahead
                                                        options={meals}
                                                        selected={selectedMeal.name === null ? null : meals.filter(m => m.id === selectedMeal.id)}
                                                        onChange={this.handleChangeMeal}
                                                        id="meal"
                                                        labelKey="name"
                                                        placeholder={t("choose_a_meal")}
                                                        clearButton
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                                <Row>
                                                    <Col>
                                                        {mutipleSelect}
                                                    </Col>
                                                </Row>
                                                <Button style={{ float: 'right' }} color="success" disabled={selectedMeal.id === null} onClick={() => this.exportNutrientDataForMeal()}>{t("export")}</Button>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="5">
                                        <Row>
                                            <Col>
                                                <FormGroup className={'datepicker-wrapper mw-150'}>
                                                    <Label>{t("from")}</Label>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        selected={report.startDay}
                                                        onChange={this.handleChangeDateStartFrom}
                                                        dateFormat="DD.MM.yyyy."
                                                        selectsStart
                                                        startDate={report.startDay}
                                                        endDate={report.endDay}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText={t("from")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={'datepicker-wrapper mw-150'}>
                                                    <Label>{t("to")}</Label>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        selected={report.endDay}
                                                        onChange={this.handleChangeDateEndTo}
                                                        dateFormat="DD.MM.yyyy."
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        selectsEnd
                                                        startDate={report.startDay}
                                                        endDate={report.endDay}
                                                        minDate={report.startDay}
                                                        dropdownMode="select"
                                                        placeholderText={t("to")}
                                                    />
                                                </FormGroup>
                                                <FormGroup className={'datepicker-wrapper mw-550'}>
                                                    <Label>{t("select_food_category")}</Label>
                                                    <Typeahead
                                                        selected={foodCategoryOptions && foodCategoryOptions.filter(c => c.categoryEnum === selectedFoodCategory)}
                                                        id="foodCategory"
                                                        clearButton
                                                        labelKey="categories"
                                                        onChange={this.handleChangeFoodCategory}
                                                        options={foodCategoryOptions}
                                                        placeholder={t("select_food_category")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                                <Button style={{ float: 'right' }} color="success" disabled={!this.disabledButton("category")} onClick={() => this.handleSubmitExport("foodCategory")}>{t("export")}</Button>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>

                    </Col>
                </Row >
            </div >
        );
    }
}

Reports.propTypes = {
    reportsData: PropTypes.object.isRequired,
    menuEditorData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    reportsData: state.reportsData,
    menuEditorData: state.menuEditorData,
    auth: state.auth,
    userData: state.userData,
    foodData: state.foodData
})

const mapActionsToProps = {
    getExportMacronutrients, getExportCalories, getExportAllData, exportNutrientDataForMeal, getMeals, getDiets, getClientsByDiet, getCategories, getExportFoodCategory
}

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Reports)))