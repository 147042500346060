import { SET_TOTAL_CLIENTS, SET_TOTAL_MEALS, SET_TOTAL_DIETS, SET_TOTAL_DISHES, SET_CLIENTS_WITHOUT_MEAL, SET_LATEST_CLIENTS, SET_LATEST_MEALS, SET_UNAPPROVED_FOODS, SET_UNAPPROVED_DISHES, SET_UNAPPROVED_DIETS, SET_UNAPPROVED_MEALS } from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const getTotalClients = () => (dispatch) => {
    axios.get(`/v1/user/count`)
        .then(response => {
            dispatch({
                type: SET_TOTAL_CLIENTS,
                payload: response.data.count
            })
        })
        .catch(error => {
            dispatch({
                type: SET_TOTAL_CLIENTS,
                payload: null
            })
        })
}

export const getTotalMeals = () => (dispatch) => {
    axios.get(`/v1/meal/count`)
        .then(response => {
            dispatch({
                type: SET_TOTAL_MEALS,
                payload: response.data.count
            })
        })
        .catch(error => {
            dispatch({
                type: SET_TOTAL_MEALS,
                payload: null
            })
        })
}

export const getTotalDiets = () => (dispatch) => {
    axios.get(`/v1/diet/count`)
        .then(response => {
            dispatch({
                type: SET_TOTAL_DIETS,
                payload: response.data.count
            })
        })
        .catch(error => {
            dispatch({
                type: SET_TOTAL_DIETS,
                payload: null
            })
        })
}

export const getTotalDishes = () => (dispatch) => {
    axios.get(`/v1/dish/count`)
        .then(response => {
            dispatch({
                type: SET_TOTAL_DISHES,
                payload: response.data.count
            })
        })
        .catch(error => {
            dispatch({
                type: SET_TOTAL_DISHES,
                payload: null
            })
        })
}

export const getClientsWithoutDiet = (page, size) => (dispatch) => {
    axios.get(`/v1/user/noDiet?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_CLIENTS_WITHOUT_MEAL,
                payload: response.data.users
            })
        })
        .catch(error => {
            dispatch({
                type: SET_CLIENTS_WITHOUT_MEAL,
                payload: []
            })
        })
}

export const getLatestClients = (page, size) => (dispatch) => {
    axios.get(`/v1/user/latest?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_LATEST_CLIENTS,
                payload: response.data.latestClients
            })
        })
        .catch(error => {
            dispatch({
                type: SET_LATEST_CLIENTS,
                payload: []
            })
        })
}

export const getLatestMeals = (limit) => (dispatch) => {
    axios.get(`/v1/meal/latest?limit=${limit}`)
        .then(response => {
            dispatch({
                type: SET_LATEST_MEALS,
                payload: response.data.meals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_LATEST_MEALS,
                payload: []
            })
        })
}

export const getUnapprovedFoods = (page, size) => (dispatch) => {
    axios.get(`/v1/food/unapproved?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_UNAPPROVED_FOODS,
                payload: response.data.unapprovedFoods
            })
        })
        .catch(error => {
            dispatch({
                type: SET_UNAPPROVED_FOODS,
                payload: []
            })
        })
}

export const getUnapprovedDishes = (page, size) => (dispatch) => {
    axios.get(`/v1/dish/unapproved?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_UNAPPROVED_DISHES,
                payload: response.data.dishes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_UNAPPROVED_DISHES,
                payload: []
            })
        })
}

export const getUnapprovedDiets = (page, size) => (dispatch) => {
    axios.get(`/v1/diet/unapproved?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_UNAPPROVED_DIETS,
                payload: response.data.diets
            })
        })
        .catch(error => {
            dispatch({
                type: SET_UNAPPROVED_DIETS,
                payload: []
            })
        })
}

export const getUnapprovedMeals = (page, size) => (dispatch) => {
    axios.get(`/v1/meal/unapproved?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_UNAPPROVED_MEALS,
                payload: response.data.meals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_UNAPPROVED_MEALS,
                payload: []
            })
        })
}

export const updateMealApproved = (id, approved, toastManager) => (dispatch) => {
    axios.put(`/v1/meal/${id}?approved=${approved}`)
        .then(response => {
            dispatch(getUnapprovedMeals(0, 7))
            toastManager.add(i18next.t("meal_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateDietApproved = (id, approved, toastManager) => (dispatch) => {
    axios.put(`/v1/diet/${id}?approved=${approved}`)
        .then(response => {
            dispatch(getUnapprovedDiets(0, 7))
            toastManager.add(i18next.t("diet_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateDishApproved = (id, approved, toastManager) => (dispatch) => {
    axios.put(`/v1/dish/${id}?approved=${approved}`)
        .then(response => {
            dispatch(getUnapprovedDishes(0, 7))
            toastManager.add(i18next.t("dish_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateFoodApproved = (id, approved, toastManager) => (dispatch) => {
    axios.put(`/v1/food/${id}?approved=${approved}`)
        .then(response => {
            dispatch(getUnapprovedFoods(0, 7))
            toastManager.add(i18next.t("food_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });

        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}
