import axios from "axios";
import qs from 'query-string';

//axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
//axios.defaults.baseURL = "http://localhost:8199/api"
 axios.defaults.baseURL = 'https://nutrition.medietetic.com/api';
axios.defaults.withCredentials = true;

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403) && window.location.pathname !== "/") {
        window.location.href = "/";
    } else {
        return Promise.reject(error);
    }
});

axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params);
};

export default axios;