import {
    SET_FOODS,
    SET_FOOD_CATEGORIES,
    SET_UNITS_OF_MEASUREMENT,
    SET_PREPARATION_TYPES,
    SET_FOOD_ENUMBERS,
    CREATE_FOOD,
    DELETE_FOOD,
    SET_ERRORS,
    SET_COPY_FOOD_LOADING,
    SET_IMPORT_FOOD_LOADING
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createFood = (toastManager, newFood, history) => (dispatch) => {
    axios.post('/v1/food', newFood)
        .then(response => {
            dispatch({
                type: CREATE_FOOD,
                payload: response.data.food
            });
            history.push('/admin/foodList')
            toastManager.add(i18next.t("food_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            dispatch({
                type: SET_ERRORS,
                payload: error.response.data
            });
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateFood = (toastManager, id, updatedFood, history) => (dispatch) => {
    axios.patch(`/v1/food/${id}`, updatedFood)
        .then(response => {
            history.push('/admin/foodList')
            toastManager.add(i18next.t("food_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            dispatch({
                type: SET_ERRORS,
                payload: error.response.data
            });
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateFoodApproved = (id, approved, toastManager) => () => {
    axios.put(`/v1/food/${id}?approved=${approved}`)
        .then(response => {
            toastManager.add(i18next.t("food_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getFoods = (page, size) => (dispatch) => {
    axios.get(`/v1/food?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_FOODS,
                payload: response.data.foods
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FOODS,
                payload: []
            })
        })
}

export const getFoodsByCategory = (page, size, category) => (dispatch) => {
    axios.get(`/v1/food/category?category=${category}&page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_FOODS,
                payload: response.data.foods
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FOODS,
                payload: []
            })
        })
}

export const searchFood = (page, size, search) => (dispatch) => {
    axios.get(`/v1/food/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_FOODS,
                payload: response.data.foods
            });
        })
        .catch(error => {
            dispatch({
                type: SET_FOODS,
                payload: []
            })
        })
}

export const searchFoodsByCategory = (page, size, search, category) => (dispatch) => {
    axios.get(`/v1/food/searchByCategory?page=${page}&size=${size}&search=${search}&category=${category}`)
        .then(response => {
            dispatch({
                type: SET_FOODS,
                payload: response.data.foods
            });
        })
        .catch(error => {
            dispatch({
                type: SET_FOODS,
                payload: []
            })
        })
}

export const getCategories = () => (dispatch) => {
    axios.get(`/v1/food/categories`)
        .then(response => {
            dispatch({
                type: SET_FOOD_CATEGORIES,
                payload: response.data.foodCategories
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FOOD_CATEGORIES,
                payload: []
            })
        })
}

export const getENumbers = () => (dispatch) => {
    axios.get(`/v1/food/eNumbers`)
        .then(response => {
            dispatch({
                type: SET_FOOD_ENUMBERS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_FOOD_ENUMBERS,
                payload: []
            })
        })
}

export const getUnitsOfMeasurement = () => (dispatch) => {
    axios.get(`/v1/food/unitsOfMeasurement`)
        .then(response => {
            dispatch({
                type: SET_UNITS_OF_MEASUREMENT,
                payload: response.data.unitsOfMeasurement
            })
        })
        .catch(error => {
            dispatch({
                type: SET_UNITS_OF_MEASUREMENT,
                payload: []
            })
        })
}

export const getPreparationTypes = () => (dispatch) => {
    axios.get(`/v1/food/preparationType`)
        .then(response => {
            dispatch({
                type: SET_PREPARATION_TYPES,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_PREPARATION_TYPES,
                payload: []
            })
        })
}

export const deleteFood = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/food/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_FOOD,
                payload: id
            })
            toastManager.add(i18next.t("food_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getFoods(0, 10))
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const handleFileSubmit = (toastManager, data) => (dispatch) => {
    axios.post(`/v1/food/import`, data)
        .then(response => {
            dispatch({
                type: SET_IMPORT_FOOD_LOADING,
                payload: {
                    importModal: false,
                    updateModal: false,
                    selectedFile: null,
                    isLoadingImport: false
                }
            })
            toastManager.add(i18next.t("food_import_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getFoods(0, 10))
        })
        .catch(error => {
            toastManager.add(i18next.t("error_importing_foods"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_IMPORT_FOOD_LOADING,
                payload: {
                    importModal: true,
                    isLoadingImport: false
                }
            })
        })
}

export const handleUpdateFileSubmit = (toastManager, data) => (dispatch) => {
    axios.post(`/v1/food/import/update`, data)
        .then(response => {
            dispatch({
                type: SET_IMPORT_FOOD_LOADING,
                payload: {
                    importModal: false,
                    updateModal: false,
                    selectedFile: null,
                    isLoadingImport: false
                }
            })
            toastManager.add(i18next.t("food_import_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getFoods(0, 10))
        })
        .catch(error => {
            toastManager.add(i18next.t("error_importing_foods"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_IMPORT_FOOD_LOADING,
                payload: {
                    updateModal: true,
                    isLoadingImport: false
                }
            })
        })
}

export const copySelectedFoods = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_FOOD_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/food/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("foods_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_FOOD_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })

        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("foods_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }

            dispatch({
                type: SET_COPY_FOOD_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })

        })
}

export const copyAllFoods = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_FOOD_LOADING,
        payload: {
            copyDialog: true,
            copyLoading: true
        }
    })
    axios.post(`/v1/food/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("foods_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_FOOD_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("foods_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_FOOD_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })

        })
}