import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Alert, Card, Form, FormGroup, Row, Col, Button, Label, Input, Table, UncontrolledTooltip, CardHeader, CardTitle } from "reactstrap";
import axios from 'axios';
import { Link } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { Typeahead } from 'react-bootstrap-typeahead';
import PerfectScrollbar from 'react-perfect-scrollbar'
import CardBody from "reactstrap/lib/CardBody";
import MacronutrientsCard from '../../components/dish/MacronutrientsCard';
import MicronutrientsChartCard from '../../components/dish/MicronutrientsChartCard';
import MineralsTableCard from '../../components/dish/MineralsTableCard';
import DraggableModal from '../../components/modal/DraggableModal'
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { createMeal, updateMeal, updateMealApproved, getDishes, getMacronutrients, getMicronutrients, getMinerals } from '../../redux/actions/mealActions';
import { getAllergenIssueDishes } from '../../redux/actions/allergenActions'
import { getFodmapIssueDishes } from '../../redux/actions/fodmapActions'

// Util
import { hasRole } from "util/hasRole";
import { RESET_ALERGEN_DATA } from "redux/types";
import store from "redux/store";

class Meal extends React.Component {

    emptyMeal = {
        name: '',
        description: '',
        approved: false,
        dishes: []
    }

    constructor(props) {
        super(props);
        this.state = {
            meal: this.emptyMeal,
            initialMeal: this.emptyMeal,
            modalDishesOpen: false,
            dishToAdd: {
                id: null,
                name: '',
                types: [],
                persons: null
            },
            dishExists: false,
            showMacronutrientsChart: false,
            showMicronutrientsChart: false,
            deleteDishModal: false,
            dishToDelete: {
                name: ''
            },
            dishIndex: null,
            approvedModal: false,
            mineralTable: false,
            newMealName: '',
            saveAsModal: false
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        Promise.all([this.props.getDishes(), this.getMealById()])
            .then(() => {
                this.getNutrients();
            })
            .catch(error => {
                console.log('Promise rejected:', error);
            })
    }

    async componentWillUnmount() {
        store.dispatch({ type: RESET_ALERGEN_DATA })
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let meal = { ...this.state.meal };
        meal[name] = value;

        this.setState({ meal: meal });
    }

    handleChangeNewMealName = (e) => {
        const target = e.target;
        const value = target.value;

        let newMealName = { ...this.state.newMealName };
        newMealName = value;
        this.setState({ newMealName: newMealName });
    }

    toggleDishModal = () => {
        this.setState({ modalDishesOpen: !this.state.modalDishesOpen });
    }

    async getMealById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/meal/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.setState({
                        meal: response.data.meal,
                        initialMeal: JSON.parse(JSON.stringify(response.data.meal)),
                        isLoading: false
                    });
                })
                .catch(error => {
                    this.toastManager.add(this.props.t("get_meal_by_id"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                    })
                })
        } else {
            return Promise.reject();
        }
    }

    getNutrients = () => {
        this.getMacronutrients();
        this.getMicronutrients();
        this.getMinerals();
        this.getAllergenIssue();
        this.getFodmapIssue();
    }

    removeDuplicates = (arr) => {
        return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    getAllergenIssue = () => {
        let { meal } = this.state
        let dishIds = []
        meal.dishes.forEach(dish => {
            dishIds.push(dish.id)
        })
        this.props.getAllergenIssueDishes(this.removeDuplicates(dishIds), this.toastManager)
    }

    getFodmapIssue = () => {
        let { meal } = this.state
        let dishIds = []
        meal.dishes.forEach(dish => {
            dishIds.push(dish.id)
        })
        this.props.getFodmapIssueDishes(this.removeDuplicates(dishIds), this.toastManager)
    }

    getMacronutrients = () => {
        let { meal } = this.state
        let dishIds = []
        meal.dishes.forEach(dish => {
            dishIds.push(dish.id)
        })
        Promise.all([this.props.getMacronutrients(dishIds)])
            .then(() => {
                this.setState({ showMacronutrientsChart: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getMicronutrients = () => {
        let { meal } = this.state
        let dishIds = []
        meal.dishes.forEach(dish => {
            dishIds.push(dish.id)
        })
        Promise.all([this.props.getMicronutrients(dishIds)])
            .then(() => {
                this.setState({ showMicronutrientsChart: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    getMinerals = () => {
        let { meal } = this.state
        let dishIds = []
        meal.dishes.forEach(dish => {
            dishIds.push(dish.id)
        })
        Promise.all([this.props.getMinerals(dishIds)])
            .then(() => {
                this.setState({ mineralTable: true });
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // To prevent router prompt from opening on submit
        this.setState({ initialMeal: this.state.meal });

        if (this.props.match.params.id) {
            this.props.updateMeal(this.toastManager, this.props.match.params.id, this.state.meal, this.props.history);
        } else {
            this.props.createMeal(this.toastManager, this.state.meal, this.props.history);
        }
    }

    handleChangeDish = (dish) => {
        const { meal } = this.state;
        let dishToAdd = { ...this.state.dishToAdd };

        let dishExists = false;
        if (meal.dishes.length > 0 && dish.length > 0) {
            meal.dishes.forEach(m => {
                if (m.name === dish[0].name)
                    dishExists = true;
            })
        }

        if (dish[0] === undefined) {
            dishToAdd.id = null;
            dishToAdd.name = '';
            dishToAdd.types = [];
            dishToAdd.persons = null;
        } else {
            dishToAdd.id = dish[0].id;
            dishToAdd.name = dish[0].name;
            dishToAdd.types = dish[0].types;
            dishToAdd.persons = dish[0].persons;
        }

        this.setState({ dishToAdd: dishToAdd, dishExists: dishExists });
    }

    addDish = () => {

        let { dishToAdd, meal } = this.state;

        meal.dishes.push(dishToAdd)

        const emptyDish = {
            id: null,
            name: '',
            type: [],
            persons: null
        }
        dishToAdd = emptyDish;

        this.setState({ meal: meal, dishToAdd: dishToAdd });
        this.getNutrients();
        this.toggleDishModal();
    }

    removeDish = (index) => {
        let { meal } = this.state;

        meal.dishes.splice(index, 1);

        this.setState({ meal: meal });

        if (meal.dishes.length === 0) {
            this.setState({ showMacronutrientsChart: false, mineralTable: false, showMicronutrientsChart: false })
        } else {
            this.getNutrients();
        }
        this.setState({ deleteDishModal: !this.state.deleteDishModal })
    }

    openDeleteDishDialog = (dish, index) => {
        this.setState({ deleteDishModal: !this.state.deleteDishModal, dishToDelete: dish, dishIndex: index });
    }

    closeDeleteDishDialog = () => {
        this.setState({ deleteDishModal: !this.state.deleteDishModal });
    }

    closeApprovedDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    toggleApprovedDialog = () => {
        this.setState({ approvedModal: !this.state.approvedModal });
    }

    handleSubmitApproved = (e) => {
        e.preventDefault();
        const { meal } = this.state;

        Promise.all([this.props.updateMealApproved(this.props.match.params.id, !meal.approved, this.toastManager)])
            .then(() => {
                this.setState({ approvedModal: false })
                this.getMealById()
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    handleContentDisposition = (response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const contentDisposition = response.headers["content-disposition"];
        let fileName = contentDisposition.split("filename=")[1];
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

    }

    toggleSaveAsDialog = () => {
        this.setState({ saveAsModal: !this.state.saveAsModal });
    }

    saveAsNewMeal = () => {

        const { meal, newMealName } = this.state;
        // To prevent router prompt from opening on submit
        this.setState({ initialMeal: meal });

        const newMeal = {
            ...meal,
            name: newMealName
        }

        Promise.all([this.props.createMeal(this.toastManager, newMeal)])
            .then(() => {
                this.props.history.push('/admin/mealList')
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    render() {

        const { dishes, macronutrientChartData, macronutrientChartOptions, micronutrientsChartData, micronutrientsChartOptions, isLoading, macronutrients, micronutrients, minerals, macronutrientRatio } = this.props.mealData;
        const { meal, initialMeal, modalDishesOpen, dishExists, dishToAdd, showMacronutrientsChart, showMicronutrientsChart, deleteDishModal, dishToDelete, approvedModal, mineralTable, saveAsModal, newMealName } = this.state;
        const { t } = this.props;
        const disabledButton = meal.name && meal.dishes.length > 0;
        const { dishesWithAllergens } = this.props.allergenData;
        const { dishesWithFodmaps } = this.props.fodmapData;

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        const dishesItems = meal.dishes.map((dish, index) => {
            return <tr key={index}>
                <td>
                    <Input disabled type="text" name={"name" + index} id={"name" + index} value={dish.name} />
                </td>
                <td>
                    <Input disabled type="number" name={"persons" + index} id={"persons" + index} value={dish.persons} />
                </td>
                <td>
                    <span style={{ cursor: "pointer" }} id={"target" + dish.id}>{dish.types ? <i style={{ color: "#ff8d72", fontSize: '25px' }} className="fas fa-info-circle" /> : null}
                        <UncontrolledTooltip style={{ color: '#0098f0' }} placement="bottom" target={"target" + dish.id}>
                            {dish.types.map(type => { return t(type) }).join(", ")}
                        </UncontrolledTooltip>
                    </span>
                </td>
                <td>
                    {(hasRole('ROLE_SUPER_ADMIN') || !meal.approved || hasRole('ROLE_ADMIN')) && <Button className="btn-icon btn-round" type="button" color="warning" size="sm" onClick={() => this.openDeleteDishDialog(dish, index)}><i className="tim-icons icon-simple-delete"></i></Button>}
                </td>
            </tr>
        });

        const deleteDishFromList = <DraggableModal
            isOpen={deleteDishModal}
            toggle={this.closeDeleteDishDialog}
            size={"sm"}
            title={<strong>{t("delete_dish")}</strong>}
            body={t("delete_message_dish") + " " + dishToDelete.name + " ?"}
            footer={<div>
                <Button color="warning" onClick={() => this.closeDeleteDishDialog()}>{t("no")}</Button>{' '}
                <Button color="success" onClick={() => this.removeDish(this.state.dishIndex)}>{t("yes")}</Button>
            </div>}
        />

        const disabledAddDish = dishExists || dishToAdd.name === '';
        const dishModal = <DraggableModal
            isOpen={modalDishesOpen}
            modalStyle={{ maxWidth: '500px' }}
            toggle={this.toggleDishModal}
            size={"sm"}
            title={<strong>{t("add_dish")}</strong>}
            body={<div>
                <Col md={10}>
                    <Typeahead
                        selected={dishToAdd.id === null ? [] : dishes.filter(d => d.id === dishToAdd.id)}
                        id="dish"
                        clearButton
                        labelKey="name"
                        onChange={this.handleChangeDish}
                        options={dishes}
                        placeholder={t("choose_a_dish")}
                        renderMenuItemChildren={(option) => (
                            <div>
                                <strong>{option.name}</strong>
                                <div>
                                    <small >{t("types")}: {option.types.map(type => { return t(type) }).join(", ")}</small>
                                </div>
                                <div>
                                    <small >{t("persons")}: {option.persons}</small>
                                </div>
                            </div>
                        )}
                        paginationText={t("display_additional")}
                        emptyLabel={t("no_matches_found")}
                    />
                </Col>
            </div>}
            footer={
                <Button disabled={disabledAddDish} color="success" onClick={this.addDish}>{t("add")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        let message = meal.approved ? t("unapprove_message") + meal.name + "? " + t("reset_message") : t("approve_message") + meal.name + "? " + t("reset_message")
        const changeApprovedModal = <DraggableModal
            isOpen={approvedModal}
            toggle={this.closeApprovedDialog}
            size={"sm"}
            title={<strong>{t("change_approved")}</strong>}
            body={message}
            footer={<div>
                <Button color="warning" onClick={() => this.closeApprovedDialog()}>{t("no")}</Button>{' '}
                <Button color="success" onClick={this.handleSubmitApproved}>{t("yes")}</Button>
            </div>}
        />

        const saveModal = <DraggableModal
            isOpen={saveAsModal}
            toggle={this.toggleSaveAsDialog}
            size={"sm"}
            title={<strong>{t("save_new_meal")}</strong>}
            body={
                <Row>
                    <Col md={12}>
                        <FormGroup className={'mw-350'}>
                            <Label>{t("new_meal_name")}</Label>
                            <Input
                                name="newMealName"
                                type="text"
                                onChange={this.handleChangeNewMealName}
                                placeholder={t("new_meal_name")}
                                value={newMealName}
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Col>
                </Row>
            }
            footer={
                <Button disabled={!newMealName} color="success" onClick={() => this.saveAsNewMeal()}><i className="fas fa-save"></i></Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        return (
            <div className="content content-meal">
                <Prompt when={JSON.stringify(meal) !== JSON.stringify(initialMeal)} message={t("warning_message")} />
                {(hasRole('ROLE_STUDENT') && meal.approved) && <Row>
                    <Col md={12}>
                        <Alert color="warning">{t("meal_approved_warning")}</Alert>
                    </Col>
                </Row>}
                <Form onSubmit={this.handleSubmit}>
                    <Row className={'even-height-row'}>
                        <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3'}>
                            <Card className={'even-height-card'}>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("meal")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={meal.name}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("description")}</Label>
                                        <Input
                                            name="description"
                                            type="textarea"
                                            rows="4"
                                            onChange={this.handleChange}
                                            placeholder={t("description")}
                                            value={meal.description}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-350'}>
                                        {this.props.match.params.id ?
                                            <Button disabled={(JSON.stringify(meal) === JSON.stringify(initialMeal))} type="submit" color="success"> {t("update")}</Button> :
                                            <Button disabled={!disabledButton} color="success">{t("create")}</Button>}
                                        {(hasRole('ROLE_SUPER_ADMIN') && this.props.match.params.id) &&
                                            (meal.approved ? <Button type="button" color="warning" onClick={this.toggleApprovedDialog}>{t("unapprove")}</Button>
                                                : <Button type="button" color="info" onClick={this.toggleApprovedDialog}>{t("approve")}</Button>)}
                                        <Button type="button" color="info" onClick={this.toggleSaveAsDialog}> {t("save_as")}</Button>
                                    </FormGroup>
                                    {changeApprovedModal}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12} className={'col-xxl-8 col-wl-6 col-wxl-7'}>
                            <Card className={'even-height-card'}>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("dishes")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button tag={Link} to={"/admin/dish/"} color="success" size="sm">{t("create_dish")}</Button>
                                            <Button type="button" color="success" size="sm" onClick={this.toggleDishModal}>{t("add_dishes")}</Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {dishesWithAllergens.length > 0 && <div>
                                        <Alert color="warning" style={{ padding: '10px', marginBottom: '2px' }}>
                                            {dishesWithAllergens.map((dish, index) => {
                                                return <li key={index}>{dish.foodWithAllergens.length > 0 && dish.dishName + t("uses_food") +
                                                    dish.foodWithAllergens.map(food => {
                                                        return food.foodName + t("allergen_text") + food.allergens.map(a => { return a.name }).join(", ")
                                                    }).join(", ")}</li>
                                            })}
                                        </Alert>
                                    </div>}
                                    {dishesWithFodmaps.length > 0 && <div>
                                        <Alert color="light" style={{ padding: '10px', marginBottom: '2px' }}>
                                            {dishesWithFodmaps.map((dish, index) => {
                                                return <li key={index}>{dish.foodWithFodmaps.length > 0 && dish.dishName + t("uses_food") +
                                                    dish.foodWithFodmaps.map(food => {
                                                        return food.foodName + t("fodmap_text") + food.fodmaps.map(a => { return a.name }).join(", ")
                                                    }).join(", ")}</li>
                                            })}
                                        </Alert>
                                    </div>}
                                    {meal.dishes.length === 0 ? null :
                                        <PerfectScrollbar style={{ height: 'auto', maxHeight: '540px' }}>
                                            <Table borderless>
                                                <thead>
                                                    <tr>
                                                        <th width="50%">{t("name")}</th>
                                                        <th width="20%">{t("persons")}</th>
                                                        <th width="20%">{t("types")}</th>
                                                        {(hasRole('ROLE_SUPER_ADMIN') || !meal.approved) && <th width="10%">&nbsp;</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dishesItems}
                                                    {deleteDishFromList}
                                                </tbody>
                                            </Table>
                                        </PerfectScrollbar>}
                                    {dishModal}
                                    {saveModal}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className={'even-height-row'}>
                        <Col md={10} lg={12} className={'col-wl-10 col-wxl-10'}>
                            <Row className={'even-height-row'}>
                                <Col lg={6}>
                                    {showMacronutrientsChart &&
                                        <MacronutrientsCard data={macronutrientChartData} options={macronutrientChartOptions} macronutrients={macronutrients} width={160} height={100} legend={{ position: 'right' }} mealId={meal.id} handleContentDisposition={this.handleContentDisposition} macronutrientRatio={macronutrientRatio} />}
                                </Col>
                                <Col lg={6}>
                                    {showMicronutrientsChart &&
                                        <MicronutrientsChartCard data={micronutrientsChartData} options={micronutrientsChartOptions} width={160} height={130} micronutrientsTableData={micronutrients} mealId={meal.id} handleContentDisposition={this.handleContentDisposition} />}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={8} md={7} lg={6} xl={6} className={'col-xxl-6 col-wl-5 col-wxl-4'}>
                            {mineralTable &&
                                <MineralsTableCard data={minerals} />}
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

Meal.propTypes = {
    mealData: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    mealData: state.mealData,
    allergenData: state.allergenData,
    fodmapData: state.fodmapData
})

const mapActionsToProps = { createMeal, updateMeal, updateMealApproved, getDishes, getMacronutrients, getMicronutrients, getMinerals, getAllergenIssueDishes, getFodmapIssueDishes }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Meal)));