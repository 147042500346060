import React from "react";
import { Container } from "reactstrap";
import { withTranslation } from 'react-i18next';
import packageJson from '../../../package.json'

class Footer extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <footer className="footer">
        <Container fluid>
          <div className="copyright">
            © {new Date().getFullYear()} {this.props.t("made_by")}{" "}
            <a
              href="https://www.base58.hr"
              target="_blank"
              rel="noopener noreferrer"
            >
              Base58 d.o.o.
            </a>{" "} , {t("version")}: {packageJson.version}
          </div>
        </Container>
      </footer>
    );
  }
}

export default withTranslation()(Footer)
