import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup,  Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class AlcoholCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { alcoholDetail, t } = this.props;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("alcohol")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col xl={12}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("ethanol")} (g)</Label>
                                <NumberFormat className="form-control" name="ethanol" onChange={(e) => this.handleChange(e, "alcoholDetail")} value={alcoholDetail.ethanol} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

AlcoholCard.propTypes = {
    alcoholDetail: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default withTranslation()(AlcoholCard);
