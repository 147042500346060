import { SET_ALLERGENS, CREATE_ALLERGEN, DELETE_ALLERGEN, SET_ALLERGENS_ALERT, SET_ALLERGENS_ALERT_DISHES, RESET_ALERGEN_DATA, SET_ALLERGENS_ON_MENU, SET_COPY_ALLERGEN_LOADING } from '../types';

const initialState = {
    allergens: [],
    size: 10,
    totalElements: 0,
    foodWithAllergens: [],
    dishesWithAllergens: [],
    menuAllergens: [],
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ALLERGENS:
            return {
                ...state,
                allergens: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_ALLERGEN:
            return {
                ...state,
                allergens: [
                    action.payload,
                    ...state.allergens
                ]
            }
        case DELETE_ALLERGEN:
            let index = state.allergens.findIndex((allergen) => allergen.id === action.payload)
            state.allergens.splice(index, 1);
            return {
                ...state
            }
        case SET_ALLERGENS_ALERT:
            return {
                ...state,
                foodWithAllergens: action.payload
            }
        case SET_ALLERGENS_ALERT_DISHES:
            return {
                ...state,
                dishesWithAllergens: action.payload
            }
        case RESET_ALERGEN_DATA: {
            return {
                ...state,
                foodWithAllergens: [],
                dishesWithAllergens: [],
                menuAllergens: []
            }
        }
        case SET_ALLERGENS_ON_MENU:
            return {
                ...state,
                menuAllergens: action.payload
            }
        case SET_COPY_ALLERGEN_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        default:
            return state;
    }
}
