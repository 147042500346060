import React from "react";
import ReactDOM from "react-dom";
import "assets/scss/black-dashboard-react.scss";
import "react-datepicker/dist/react-datepicker.css";
import "assets/css/nucleo-icons.css";
import "assets/css/app.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "assets/css/LoginForm.css";
import './i18n';
import 'react-flags-select/css/react-flags-select.css';
import "assets/scss/override/panel.scss";
import 'react-data-table-component-extensions/dist/index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ToastProvider } from 'react-toast-notifications';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './axios';

ReactDOM.render(
    <ToastProvider placement="bottom-right">
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </ToastProvider>,
    document.getElementById('root')
);
