import { CREATE_USER, SET_USERS, SET_USER_DIETS, SET_USER_ROLES, SET_USER_DIAGNOSES, SET_STUDENTS, SET_ADMINS, SET_USER_MENUS_TABLE, SET_USER_ALLERGENS, SET_USER_FODMAPS } from '../types';

const initialState = {
    users: [],
    diets: [],
    roles: [],
    diagnoses: [],
    totalElements: 0,
    size: 10,
    students: [],
    totalElementsStudents: 0,
    sizeStudents: 10,
    admins: [],
    totalElementsAdmins: 0,
    sizeAdmins: 10,
    userMenus: [],
    totalElementsUserMenus: 0,
    sizeUserMenus: 10,
    allergens: [],
    fodmaps: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_USER:
            return {
                ...state,
                users: [
                    action.payload,
                    ...state.users
                ]
            }
        case SET_USERS:
            return {
                ...state,
                users: action.payload.content,
                totalElements: action.payload.totalElements,
                size: action.payload.size,
                isLoading: false
            }
        case SET_USER_DIETS:
            return {
                ...state,
                diets: action.payload,
                isLoading: false
            }
        case SET_USER_ROLES:
            return {
                ...state,
                roles: action.payload,
                isLoading: false
            }
        case SET_USER_DIAGNOSES:
            return {
                ...state,
                diagnoses: action.payload,
                isLoading: false
            }
        case SET_STUDENTS:
            return {
                ...state,
                students: action.payload.content,
                totalElementsStudents: action.payload.totalElements,
                sizeStudents: action.payload.size,
                isLoading: false
            }
        case SET_ADMINS:
            return {
                ...state,
                admins: action.payload.content,
                totalElementsAdmins: action.payload.totalElements,
                sizeAdmins: action.payload.size,
                isLoading: false
            }
        case SET_USER_MENUS_TABLE:
            return {
                ...state,
                userMenus: action.payload.content,
                totalElementsUserMenus: action.payload.totalElements,
                sizeUserMenus: action.payload.size,
                isLoading: false
            }
        case SET_USER_ALLERGENS:
            return {
                ...state,
                allergens: action.payload,
                isLoading: false
            }
        case SET_USER_FODMAPS:
            return {
                ...state,
                fodmaps: action.payload,
                isLoading: false
            }
        default:
            return state;
    }
}
