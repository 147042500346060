import {
    SET_FOODS,
    SET_FOOD_CATEGORIES,
    SET_UNITS_OF_MEASUREMENT,
    SET_PREPARATION_TYPES,
    CREATE_FOOD, DELETE_FOOD,
    SET_FOOD_ENUMBERS,
    SET_FOOD_LIST_PAGE,
    SET_COPY_FOOD_LOADING,
    SET_IMPORT_FOOD_LOADING
} from '../types';

const initialState = {
    foods: [],
    categories: [],
    unitsOfMeasurement: [],
    preparationTypes: [],
    sourcesOfInformation: [],
    size: 10,
    totalElements: 0,
    eNumbers: [],
    page: 0,
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false,
    importModal: false,
    updateModal : false,
    selectedFile: null,
    isLoadingImport: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FOODS:
            return {
                ...state,
                foods: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements,
                isLoading: false
            }
        case SET_FOOD_LIST_PAGE:
            return {
                ...state,
                page: action.payload.page,
                size: action.payload.size
            }
        case SET_FOOD_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case SET_FOOD_ENUMBERS:
            return {
                ...state,
                eNumbers: action.payload
            }
        case SET_UNITS_OF_MEASUREMENT:
            return {
                ...state,
                unitsOfMeasurement: action.payload,
                isLoading: false
            }
        case SET_PREPARATION_TYPES:
            return {
                ...state,
                preparationTypes: action.payload,
                isLoading: false
            }
        case CREATE_FOOD:
            return {
                ...state,
                foods: [
                    action.payload,
                    ...state.foods
                ]
            }
        case DELETE_FOOD:
            let index = state.foods.findIndex((food) => food.id === action.payload)
            state.foods.splice(index, 1);
            return {
                ...state
            }
        case SET_COPY_FOOD_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        case SET_IMPORT_FOOD_LOADING:
            return {
                ...state,
                isLoadingImport: action.payload.isLoadingImport,
                importModal: action.payload.importModal,
                selectedFile: action.payload.selectedFile,
                updateModal : action.payload.updateModal
            }
        default:
            return state;
    }
}
