import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Input, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';

class InfoCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    handleChangeENumbers = (e) => {
        this.props.handleChangeENumbers(e);
    }

    render() {

        const { eNumbersInput, t, eNumbers, sweeteners } = this.props;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("info")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6}>
                            <FormGroup className={'mw-240'}>
                                <Label>{t("e_numbers")}</Label>
                                <Typeahead
                                    selected={eNumbersInput}
                                    id="eNumbers"
                                    clearButton
                                    labelKey="eNumbers"
                                    onChange={(e) => this.handleChangeENumbers(e)}
                                    options={eNumbers}
                                    multiple
                                    placeholder={t("select_e_numbers")}
                                    paginationText={t("display_additional")}
                                    emptyLabel = {t("no_matches_found")}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("sweeteners")}</Label>
                                <Input
                                    name="sweeteners"
                                    type="textarea"
                                    rows="4"
                                    placeholder={t("sweeteners")}
                                    onChange={(e) => this.handleChange(e)}
                                    value={sweeteners || ''}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

InfoCard.propTypes = {
    eNumbersInput: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleChangeENumbers: PropTypes.func,
    eNumbers: PropTypes.array.isRequired,
    sweeteners: PropTypes.string
}

export default withTranslation()(InfoCard);
