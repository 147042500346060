import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class VitaminCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { vitaminDetail, t } = this.props;

        return (
            <Card>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("vitamin")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("retinol")} (μg)</Label>
                                <NumberFormat className="form-control" name="retinol" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.retinol} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("beta_carotene")} (μg)</Label>
                                <NumberFormat className="form-control" name="betaCarotene" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.betaCarotene} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>A (μg)</Label>
                                <NumberFormat disabled className="form-control" name="totalFat" value={vitaminDetail.a} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>D (μg)</Label>
                                <NumberFormat className="form-control" name="d" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.d} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>D3 (μg)</Label>
                                <NumberFormat className="form-control" name="d3" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.d3} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>E (mg)</Label>
                                <NumberFormat className="form-control" name="e" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.e} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("b1_thiamine")}(mg)</Label>
                                <NumberFormat className="form-control" name="b1" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b1} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("b2_riboflavin")} (mg)</Label>
                                <NumberFormat className="form-control" name="b2" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b2} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("b3_niacin")} (mg)</Label>
                                <NumberFormat className="form-control" name="b3" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b3} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("b5_pantothenic_acid")} (mg)</Label>
                                <NumberFormat className="form-control" name="b5" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b5} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>B6 (mg)</Label>
                                <NumberFormat className="form-control" name="b6" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b6} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>B7 (mg)</Label>
                                <NumberFormat className="form-control" name="b7" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b7} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("b9_folic_acid")}(μg)</Label>
                                <NumberFormat className="form-control" name="b9" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b9} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>B12 (μg)</Label>
                                <NumberFormat className="form-control" name="b12" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.b12} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>C (mg)</Label>
                                <NumberFormat className="form-control" name="c" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.c} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>K (mg)</Label>
                                <NumberFormat className="form-control" name="k" onChange={(e) => this.handleChange(e, "vitaminDetail")} value={vitaminDetail.k} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

VitaminCard.propTypes = {
    vitaminDetail: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default withTranslation()(VitaminCard)
