import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { Badge, Table, Button, UncontrolledTooltip } from "reactstrap";
import { withTranslation } from 'react-i18next';
import store from 'redux/store'
import { SET_LATEST_DIET_NAME } from '../../redux/types';
// Style
import './CalendarTable.css';

const tdCss = {
    backgroundColor: '#f5f2f2',
    cursor: 'grab'
}

class CalendarTable extends React.Component {

    isFutureDate = (date) => {
        let dateNow = moment(moment().format("YYYYMMDD"))
        let dateOnly = moment(date.format("YYYYMMDD"))
        return dateNow.isAfter(dateOnly);
    }

    handleClick = (menu, history, selectedDiet) => {
        store.dispatch({
            type: SET_LATEST_DIET_NAME,
            payload: {
                diet: selectedDiet,
                date: menu.date,
                client: this.props.selectedClient
            }
        })
        history.push("/admin/menuDetails/" + menu.id)
    }

    handlePrint = (menu) => {
        this.props.handlePrint(menu)
    }

    details = (menu) => {
        this.props.menuDetails(menu)
    }

    getAllergensIssue = (menu) => {
        this.props.getAllergensIssue(menu)
    }

    openDeleteMenuDialog = (menu) => {
        this.props.openDeleteMenuDialog(menu)
    }

    render() {
        const { menus, startDay, t, history, selectedDiet, selectedClient } = this.props

        let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        let dates = [startDay.clone().format('DD/MM'), startDay.clone().add(1, 'day').format('DD/MM'), startDay.clone().add(2, 'day').format('DD/MM'), startDay.clone().add(3, 'day').format('DD/MM'), startDay.clone().add(4, 'day').format('DD/MM'), startDay.clone().add(5, 'day').format('DD/MM'), startDay.clone().add(6, 'day').format('DD/MM')]
        let checkDates = [startDay, startDay.clone().add(1, 'day'), startDay.clone().add(2, 'day'), startDay.clone().add(3, 'day'), startDay.clone().add(4, 'day'), startDay.clone().add(5, 'day'), startDay.clone().add(6, 'day')]
        let mondayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Mon')
        let tuesdayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Tue')
        let wednesdayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Wed')
        let thursdayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Thu')
        let fridayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Fri')
        let saturdayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Sat')
        let sundayMenu = menus.find(menu => moment(menu.date).format('ddd') === 'Sun')
        let weekMenu = [mondayMenu, tuesdayMenu, wednesdayMenu, thursdayMenu, fridayMenu, saturdayMenu, sundayMenu]
        let entryType = ['BREAKFAST', 'SNACK1', 'LUNCH', 'SNACK2', 'DINNER', 'SNACK3']

        return (

            <Table className="calendar" responsive>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>{t("breakfast")}</th>
                        <th>{t("snack_1")}</th>
                        <th>{t("lunch")}</th>
                        <th>{t("snack_2")}</th>
                        <th>{t("dinner")}</th>
                        <th>{t("snack_3")}</th>
                    </tr>
                </thead>
                <tbody>
                    {days.map((day, index) => {
                        return <tr id={day} key={index}>
                            <th scope="row" className="th-style">
                                <p>{t(day)}{' '}{dates[index]}</p>
                                <p>{weekMenu[index] && menus.length > 0 && <span style={{ cursor: "pointer" }} id={"menuDetails"}>
                                    {weekMenu[index] && <Button className="btn-icon btn-round" color="info" size="xs" type="button" onClick={() => this.handleClick(weekMenu[index], history, selectedDiet)}><i className="fas fa-info" />
                                        <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"menuDetails"} className="uTooltip uTooltip-title">
                                            {t("details_menu")}
                                        </UncontrolledTooltip>
                                    </Button>}
                                </span>}
                                    {weekMenu[index] && menus.length > 0 && <span style={{ cursor: "pointer" }} id={"handlePrint"}>
                                        {weekMenu[index] && <Button onClick={() => this.handlePrint(weekMenu[index])} className="btn-icon btn-round" color="warning" size="xs" ><i className="fas fa-print" />
                                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"handlePrint"} className="uTooltip uTooltip-title">
                                                {t("export_report")}
                                            </UncontrolledTooltip>
                                        </Button>}
                                    </span>
                                    }
                                    {weekMenu[index] === undefined ? null : weekMenu[index].inConflictWithDiet && menus.length > 0 ?
                                        <span style={{ cursor: "pointer" }} id={"issueDiet"}>
                                            {weekMenu[index] && <Button onClick={() => this.details(weekMenu[index])} className="btn-icon btn-round btn-danger" size="xs" ><i className="fas fa-times" />
                                                <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"issueDiet"} className="uTooltip uTooltip-title">
                                                    {t("issue_diet")}
                                                </UncontrolledTooltip>
                                            </Button>}
                                        </span> : <span style={{ cursor: "grab" }} id={"dietIssueOk"}>
                                            <Button disabled className="btn-icon btn-round" size="xs" style={{ background: '#2dce89' }}><i className="fas fa-check" />
                                                <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"dietIssueOk"} className="uTooltip uTooltip-title">
                                                    {t("diet_issue_ok")}
                                                </UncontrolledTooltip>
                                            </Button>
                                        </span>}
                                    {weekMenu[index] === undefined ? null : (weekMenu[index].hasAllergen || weekMenu[index].hasFodmap) && menus.length > 0 ?
                                        <span style={{ cursor: "pointer" }} id={"issueAllergen"}>
                                            {weekMenu[index] && <Button onClick={() => this.getAllergensIssue(weekMenu[index])} className="btn-icon btn-round btn-danger" size="xs" ><i className="fas fa-times" />
                                                <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"issueAllergen"} className="uTooltip uTooltip-title">
                                                    {t("issue_allergen")}
                                                </UncontrolledTooltip>
                                            </Button>}
                                        </span> : <span style={{ cursor: "grab" }} id={"allergenIssueOk"}>
                                            <Button disabled className="btn-icon btn-round" size="xs" style={{ background: '#2dce89' }}><i className="fas fa-check" />
                                                <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"allergenIssueOk"} className="uTooltip uTooltip-title">
                                                    {t("allergen_issue_ok")}
                                                </UncontrolledTooltip>
                                            </Button>
                                        </span>}
                                    {weekMenu[index] && menus.length > 0 ? selectedClient.length === 0 ? <span style={{ cursor: "pointer" }} id={"targetClients6"}>
                                        {weekMenu[index] && <Button disabled color="secoundary" className="btn-icon btn-round" size="xs" >{weekMenu[index].numberOfClients}
                                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"targetClients6"} className="uTooltip uTooltip-title">
                                                {t("numbers_of_clients")}
                                            </UncontrolledTooltip>
                                        </Button>}
                                    </span> : null : null}

                                    {weekMenu[index] && menus.length > 0 ? <span style={{ cursor: "pointer" }} id={"targetCopyPerDay6"}>
                                        {weekMenu[index] && <Button onClick={() => this.props.openCopyPerDayModal(weekMenu[index])} className="btn-icon btn-round" color="success" size="xs" ><i className="fas fa-copy" />
                                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"targetCopyPerDay6"} className="uTooltip uTooltip-title">
                                                {t("copy_per_day")}
                                            </UncontrolledTooltip>
                                        </Button>}
                                    </span> : null}
                                    {weekMenu[index] && menus.length > 0 ? <span style={{ cursor: "pointer" }} id={"targetDelete6"}>
                                        {weekMenu[index] && <Button onClick={() => this.openDeleteMenuDialog(weekMenu[index])} className="btn-icon btn-round" color="danger" size="xs" ><i className="fas fa-trash" />
                                            <UncontrolledTooltip style={{ fontSize: '14px' }} placement="top" target={"targetDelete6"} className="uTooltip uTooltip-title">
                                                {t("delete_menu_week")}
                                            </UncontrolledTooltip>
                                        </Button>}
                                    </span> : null}</p>
                            </th>
                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[0]).map(breakfastEntry => breakfastEntry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[0], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[0]).map(breakfastEntry => breakfastEntry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[0], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[0], checkDates[index])}></div>
                            </td>

                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[1]).map(snack1Entry => snack1Entry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[1], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[1]).map(snack1Entry => snack1Entry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[1], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[1], checkDates[index])}></div>
                            </td>

                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[2]).map(snack1Entry => snack1Entry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[2], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[2]).map(snack1Entry => snack1Entry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[2], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[2], checkDates[index])}></div>
                            </td>

                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[3]).map(snack1Entry => snack1Entry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[3], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[3]).map(snack1Entry => snack1Entry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[3], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[3], checkDates[index])}></div>
                            </td>

                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[4]).map(snack1Entry => snack1Entry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[4], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[4]).map(snack1Entry => snack1Entry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[4], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[4], checkDates[index])}></div>
                            </td>

                            <td className="main-td1" style={this.isFutureDate(checkDates[index]) ? tdCss : null}>
                                <div>{weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[5]).map(snack1Entry => snack1Entry.meals.map((meal, mealIndex) => {
                                    return <Fragment key={mealIndex}>
                                        <div>
                                            <Badge className="meal-item meal" color="info">{meal.name}</Badge>
                                            <Button size="xs" onClick={() => this.props.openInfoModalMeal(weekMenu[index], entryType[5], mealIndex)} className="meal-item meal btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                        </div>
                                    </Fragment>
                                }))}
                                    {weekMenu[index] && weekMenu[index].calendarEntries.filter(entry => entry.entryType === entryType[5]).map(snack1Entry => snack1Entry.dishes.map((dish, dishIndex) => {
                                        return <Fragment key={dishIndex}>
                                            <div>
                                                <Badge className="meal-item dish" color="info">{dish.name}</Badge>
                                                <Button size="xs" onClick={() => this.props.openInfoModalDish(weekMenu[index], entryType[5], dishIndex)} className="meal-item dish btn-icon btn-round" color="info"><i className="fas fa-info"></i></Button>
                                            </div>
                                        </Fragment>
                                    }))}</div>
                                <div className="main-td" onClick={this.isFutureDate(checkDates[index]) ? null : () => this.props.openAddMealModal(weekMenu[index], entryType[5], checkDates[index])}></div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </Table>
        );
    }
}

CalendarTable.propTypes = {
    menus: PropTypes.array.isRequired,
    openAddMealModal: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired,
    openCopyPerDayModal: PropTypes.func.isRequired,
    menuDetails: PropTypes.func.isRequired
}

export default withTranslation()(CalendarTable)