import { SET_FODMAPS, CREATE_FODMAP, DELETE_FODMAP, SET_FODMAP_ALERT, SET_FODMAP_ALERT_DISHES, RESET_FODMAP_DATA, SET_FODMAPS_ON_MENU, SET_COPY_FODMAP_LOADING } from '../types';

const initialState = {
    fodmaps: [],
    size: 10,
    totalElements: 0,
    foodWithFodmaps: [],
    dishesWithFodmaps: [],
    menuFodmaps: [],
    copyLoading: false,
    copyDialog: false,
    copyAllDialog: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FODMAPS:
            return {
                ...state,
                fodmaps: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_FODMAP:
            return {
                ...state,
                fodmaps: [
                    action.payload,
                    ...state.fodmaps
                ]
            }
        case DELETE_FODMAP:
            let index = state.fodmaps.findIndex((fodmap) => fodmap.id === action.payload)
            state.fodmaps.splice(index, 1);
            return {
                ...state
            }
        case SET_FODMAP_ALERT:
            return {
                ...state,
                foodWithFodmaps: action.payload
            }
        case SET_FODMAP_ALERT_DISHES:
            return {
                ...state,
                dishesWithFodmaps: action.payload
            }
        case RESET_FODMAP_DATA: {
            return {
                ...state,
                foodWithFodmaps: [],
                dishesWithFodmaps: [],
                menuFodmaps: []
            }
        }
        case SET_FODMAPS_ON_MENU:
            return {
                ...state,
                menuFodmaps: action.payload
            }
        case SET_COPY_FODMAP_LOADING:
            return {
                ...state,
                copyLoading: action.payload.copyLoading,
                copyDialog: action.payload.copyDialog,
                copyAllDialog: action.payload.copyAllDialog
            }
        default:
            return state;
    }
}
