import React from "react"
import PropTypes from 'prop-types'
import { Card, CardHeader, CardBody, Row, Col, Button, Input, FormGroup, Table, Label } from "reactstrap"
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component'
import { Typeahead } from 'react-bootstrap-typeahead'
import ClipLoader from "react-spinners/ClipLoader"
import { withToastManager } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import DraggableModal from '../../components/modal/DraggableModal'
import moment from 'moment'
import PerfectScrollbar from 'react-perfect-scrollbar'
// Redux
import { connect } from 'react-redux'
import { getDiets, deleteDiet, searchDiet, copySelectedDiets, copyAllDiets } from '../../redux/actions/dietActions'
import { getCompaniesForUser } from '../../redux/actions/companyActions'
import store from '../../redux/store';
import { SET_COPY_DIET_LOADING, SET_DIET_DELETE_MODAL } from '../../redux/types'
// Util
import { hasRole } from "util/hasRole"

class DietList extends React.Component {

    emptyDiet = {
        name: '',
        description: '',
        fatFrom: 0,
        fatTo: 0,
        carbohydrateFrom: 0,
        carbohydrateTo: 0,
        proteinFrom: 0,
        proteinTo: 0,
        fiberFrom: 0,
        fiberTo: 0
    }

    constructor(props) {
        super(props);
        this.state = {
            diet: null,
            isDescriptionModalOpen: false,
            dietToDelete: this.emptyDiet,
            dietSearch: "",
            isLoading: true,
            company: null,
            dietIds: [],
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.getDiets(0, 10);
        hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
        this.setState({ isLoading: false });
    }

    handleChange = (id) => {
        let { dietIds } = this.state
        if (!dietIds.includes(id)) {
            dietIds.push(id)
        } else {
            dietIds.splice(dietIds.indexOf(id), 1);
        }
        this.setState({ dietIds: dietIds })
    }

    onChangePage = (page, size) => {
        if (this.state.dietSearch !== "") {
            this.props.searchDiet(page - 1, this.props.dietData.size, this.state.dietSearch);
        } else {
            this.props.getDiets(page - 1, this.props.dietData.size);
        }
    }

    onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        if (this.state.dietSearch !== "") {
            this.props.searchDiet(currentPage - 1, currentRowsPerPage, this.state.dietSearch);
        } else {
            this.props.getDiets(currentPage - 1, currentRowsPerPage);
        }
    }

    openDeleteDialog = (row) => {
        store.dispatch({
            type: SET_DIET_DELETE_MODAL,
            payload: true
        })
        this.setState({ dietToDelete: row, });

    }

    openDescriptionDialog = (row) => {
        this.setState({ isDescriptionModalOpen: true, diet: row, });
    }

    closeDialog = () => {
        this.props.dietData.dietInfo = []
        store.dispatch({
            type: SET_DIET_DELETE_MODAL,
            payload: false
        })
    }

    closeDescriptionModalBtn = () => {
        this.setState({ isDescriptionModalOpen: !this.state.isDescriptionModalOpen });
    }

    deleteDiet = () => {
        this.props.deleteDiet(this.toastManager, this.state.dietToDelete.id)
        if (this.props.dietData.dietInfo.length === 0) {
            store.dispatch({
                type: SET_DIET_DELETE_MODAL,
                payload: false
            })
        }
        else {
            this.setState({ dietInfoTable: true })
        }
    }

    handleDietSearch = (event) => {
        this.setState({ dietSearch: event.target.value });
    }

    copyDialog = (copyAllDialog) => {
        if (copyAllDialog === false) {
            store.dispatch({ type: SET_COPY_DIET_LOADING, payload: { copyAllDialog: true, copyDialog: true } })
        }
        else if (copyAllDialog === undefined) {
            store.dispatch({
                type: SET_COPY_DIET_LOADING, payload: { copyAllDialog: false, copyDialog: true }
            })
        }
    }

    closeCopyDialog = () => {
        store.dispatch({
            type: SET_COPY_DIET_LOADING,
            payload: {
                copyDialog: false
            }
        })
        this.setState({ company: null })
    }

    handleChangeCompany = (companyEntry) => {
        let company = { ...this.state.company };
        if (companyEntry.length > 0) {
            company = companyEntry[0];
        } else { company = null; }
        this.setState({ company: company })
    }

    copyData = () => {
        const { dietIds, company } = this.state
        const requestData = {
            ids: dietIds,
            companyId: company.id
        }
        this.props.copySelectedDiets(requestData, this.toastManager)
    }

    render() {
        const { diets, totalElements, dietInfo, copyAllDialog, copyDialog, copyLoading, deleteDietModal } = this.props.dietData
        const { companiesForUser } = this.props.companyData
        const { diet, isDescriptionModalOpen, dietToDelete, isLoading, company, dietIds, } = this.state
        const { t } = this.props

        let infoTableDiet = []
        if (dietInfo.menus !== undefined || dietInfo.users !== undefined) {
            infoTableDiet = <React.Fragment>
                {dietInfo.menus.length > 0 && <Col md={6}>
                    <span><strong>{t("menus")}</strong></span>
                    <PerfectScrollbar style={{ height: '400px' }}>
                        <Table className="tableFixHead">
                            <thead>
                                <tr >
                                    <th><strong>{t("date")}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dietInfo.menus.map((data, index1) => {
                                    return <tr key={index1}>
                                        <td>{data.date}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </PerfectScrollbar>
                </Col>}
                {dietInfo.users.length > 0 && <Col md={6}>
                    <span><strong>{t("users")}</strong></span>
                    <PerfectScrollbar style={{ height: '400px' }}>
                        <Table className="tableFixHead">
                            <thead>
                                <tr>
                                    <th><strong>{t("first_name")}</strong></th>
                                    <th><strong>{t("last_name")}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {dietInfo.users.map((data, index2) => {
                                    return <tr key={index2}>
                                        <td>{data.firstName}</td>
                                        <td>{data.lastName}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </PerfectScrollbar>
                </Col>}
            </React.Fragment>
        }

        const deleteModal = <DraggableModal
            isOpen={deleteDietModal}
            toggle={this.closeDialog}
            size={"sm"}
            title={<strong>{t("delete_diet")}</strong>}
            body={<div>
                <span><strong>{t("diet") + ": "}{dietToDelete.name}</strong></span>
                <Row>
                    <Col>
                        {t("delete_diet_message") + " ?"}
                    </Col>
                </Row>
                {dietInfo.menus !== undefined && <hr />}
                <Row>
                    {infoTableDiet}
                </Row>
            </div>
            }
            footer={
                <Button color="success" onClick={this.deleteDiet}>{t("delete")}</Button>
            }
            modalStyle={{ maxWidth: '600px' }}
        />

        const copyToOtherCompany = <DraggableModal
            isOpen={copyDialog}
            toggle={this.closeCopyDialog}
            size={"sm"}
            title={<strong>{t("copy_to_another_company")}</strong>}
            body={<div>
                <FormGroup>
                    <Typeahead
                        selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
                        id="company"
                        clearButton
                        labelKey="name"
                        onChange={this.handleChangeCompany}
                        options={companiesForUser}
                        placeholder={t("select_company")}
                        paginationText={t("display_additional")}
                        emptyLabel={t("no_matches_found")}
                    />
                </FormGroup>
            </div>}
            footer={
                <Button style={{ width: '82.64px', height: '40px' }} color="success" disabled={company === null} onClick={copyAllDialog ? () => this.props.copyAllDiets(company.id, this.toastManager) : () => this.copyData()}>{copyLoading ? <ClipLoader
                    size={20}
                    color={"#e14eca"}
                    loading={copyLoading}
                /> : t("copy")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        const descriptionModal = <DraggableModal
            isOpen={isDescriptionModalOpen}
            toggle={this.closeDescriptionModalBtn}
            size={"sm"}
            title={<strong>{t("diet_description")}</strong>}
            body={<Row>
                <Col md={12}>
                    <FormGroup>
                        <Input
                            disabled
                            name="description"
                            type="textarea"
                            rows="4"
                            value={diet && diet.description}
                        />
                    </FormGroup>
                </Col>
            </Row>}
        />

        const dietColumns = [
            {
                name: t("name"),
                selector: "name",
                sortable: true,
                width: hasRole('ROLE_SUPER_ADMIN') ? "30%" : "40%"
            },
            {
                name: t("description"),
                selector: "description",
                width: hasRole('ROLE_SUPER_ADMIN') ? "10%" : "20%",
                center: true,
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="warning" size="sm" onClick={() => this.openDescriptionDialog(row)}><i className="fas fa-info"></i></Button>
                </span>
            }
        ]

        const createdBy = {
            name: t("created_by"),
            selector: "createdBy",
            sortable: true,
            width: "15%",
            cell: row => <span>
                {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
            </span>
        }

        const createdAt = {
            name: t("created_at"),
            selector: "createdAt",
            sortable: true,
            width: "15%",
            cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
        }

        const updatedAt = {
            name: t("updated_at"),
            selector: "updatedAt",
            sortable: true,
            width: "15%",
            cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
        }

        const actions = {
            name: t("actions"),
            center: true,
            width: "10%",
            cell: row => <span>
                <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/diet/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDeleteDialog(row)}><i className="tim-icons icon-simple-remove"></i></Button>
            </span>
        }

        const copy = {
            name: t("copy"),
            width: "5%",
            cell: row =>
                <FormGroup check>
                    <Label check>
                        <Input type="checkbox" name="select" id="select" onChange={() => this.handleChange(row.id)} style={{ position: 'relative' }} checked={dietIds.some(item => item === row.id)} />
                        <span className="form-check-sign">
                            <span className="check" />
                        </span>
                    </Label>
                </FormGroup>
        }

        if (hasRole("ROLE_SUPER_ADMIN")) {
            dietColumns.push(createdBy)
            dietColumns.push(createdAt)
            dietColumns.push(updatedAt)
            dietColumns.push(actions)
            dietColumns.push(copy)
        } else {
            dietColumns.push(createdAt)
            dietColumns.push(updatedAt)
            dietColumns.push(actions)
        }

        return (
            <div className="content content-diet">
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardHeader className={'control-header'}>
                                <Row>
                                    <Col sm={12} md={4}>
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_diets")} autoComplete="off" name="dietSearch" id="dietSearch" value={this.state.dietSearch || ''} onChange={this.handleDietSearch}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    this.state.dietSearch === "" ? this.props.getDiets(0, 10) : this.props.searchDiet(0, this.props.dietData.size, this.state.dietSearch)
                                                }
                                            }} />
                                    </Col>
                                    <Col xs={1} sm={1}>
                                        <Button color="success" size="sm" type="button" onClick={this.state.dietSearch === "" ? () => this.props.getDiets(0, 10) : () => this.props.searchDiet(0, this.props.dietData.size, this.state.dietSearch)}><i className="fas fa-search"></i></Button>
                                    </Col>
                                    <Col sm={5} md={4} style={{ textAlign: 'right' }}>
                                        <Button tag={Link} to="/admin/diet" color="success">{t("create_diet")}</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={dietColumns}
                                        data={diets}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                                        paginationTotalRows={totalElements}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        onChangePage={(page, totalRows) => this.onChangePage(page, totalRows)}
                                        onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                                    />
                                )}
                            </CardBody>
                            {deleteModal}
                            {descriptionModal}
                            {copyToOtherCompany}
                        </Card>
                        <div>
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={dietIds.length === 0} onClick={() => this.copyDialog()}><i className="far fa-copy"></i> {t("copy_selected_to_another_company")}</Button>}
                            {hasRole("ROLE_SUPER_ADMIN") && <Button color="success" size="sm" type="button" disabled={diets.length === 0} onClick={() => this.copyDialog(copyAllDialog)}><i className="far fa-copy"></i> {t("copy_all_to_another_company")}</Button>}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

DietList.propTypes = {
    dietData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    dietData: state.dietData,
    companyData: state.companyData
})

const mapActionsToProps = { getDiets, deleteDiet, searchDiet, getCompaniesForUser, copySelectedDiets, copyAllDiets }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(DietList)));