import {
    SET_CALENDAR_MEALS,
    SET_MENU_BY_DIET,
    SET_MENU_DISHES,
    SET_LOADING_CALENDAR_ENTRIES,
    SET_DISH_NORMATIVES,
    SET_MEAL_NORMATIVES,
    SET_DIET_CLIENTS,
    SET_DIET_CLIENTS_COPY,
    SET_MENU_NUTRIENTS_OUT_OF_BOUNDS,
    SET_FFQ
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createMenu = (createMenuRequest, fetchMealsForInterval, toastManager) => () => {
    axios.post(`/v1/menu`, createMenuRequest)
        .then(response => {
            fetchMealsForInterval();
            toastManager.add(i18next.t("menu_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("create_menu_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}

export const getMeals = () => (dispatch) => {
    axios.get(`/v1/meal/forMenu`)
        .then(response => {
            dispatch({
                type: SET_CALENDAR_MEALS,
                payload: response.data.meals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_CALENDAR_MEALS,
                payload: []
            })
        })
}

export const getDishNormatives = (selectedDishId, selectedCalendarId, toastManager) => (dispatch => {
    axios.get(`/v1/dish/${selectedDishId}/${selectedCalendarId}/normatives`)
        .then(response => {
            dispatch({
                type: SET_DISH_NORMATIVES,
                payload: response.data.foods,
            })

            dispatch({
                type: SET_FFQ,
                ffqFactor: response.data.ffq,
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
})

export const getMealNormatives = (selectedMealId, selectedCalendarId, toastManager) => (dispatch => {
    axios.get(`/v1/meal/${selectedMealId}/${selectedCalendarId}/normatives`)
        .then(response => {
            dispatch({
                type: SET_MEAL_NORMATIVES,
                payload: response.data.dishes
            })
            dispatch({
                type: SET_FFQ,
                ffqFactor: response.data.ffq,
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
})

export const getDishes = () => (dispatch) => {
    axios.get(`/v1/dish/forMenu`)
        .then(response => {
            dispatch({
                type: SET_MENU_DISHES,
                payload: response.data.dishes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MENU_DISHES,
                payload: []
            })
        })
}

export const getClientsByDiet = (dietId) => (dispatch) => {
    axios.get(`/v1/user/byDiet?dietId=${dietId}`)
        .then(response => {
            dispatch({
                type: SET_DIET_CLIENTS,
                payload: response.data.clients
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DIET_CLIENTS,
                payload: []
            })
        })
}

export const getClientsByDietForCopyPerDay = (dietId) => (dispatch) => {
    axios.get(`/v1/user/byDiet?dietId=${dietId}`)
        .then(response => {
            dispatch({
                type: SET_DIET_CLIENTS_COPY,
                payload: response.data.clients
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DIET_CLIENTS_COPY,
                payload: []
            })
        })
}

export const getMenuByDiet = (data) => (dispatch) => {
    dispatch({ type: SET_LOADING_CALENDAR_ENTRIES })
    axios.post(`/v1/menu/byDietForInterval`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            dispatch({
                type: SET_MENU_BY_DIET,
                payload: response.data.menus
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MENU_BY_DIET,
                payload: []
            })
        })
}

export const getNutrientsOutOfBounds = (toastManager, id) => (dispatch) => {
    axios.get(`/v1/menu/${id}/issues`)
        .then(response => {
            dispatch({
                type: SET_MENU_NUTRIENTS_OUT_OF_BOUNDS,
                payload: response.data.nutrientsOutOfBounds
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("details_diet_issue_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getMenuByClient = (data) => (dispatch) => {
    dispatch({ type: SET_LOADING_CALENDAR_ENTRIES })
    axios.post(`/v1/menu/byUserForInterval`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            dispatch({
                type: SET_MENU_BY_DIET,
                payload: response.data.menus
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MENU_BY_DIET,
                payload: []
            })
        })
}

export const reloadClients = (toastManager, id, getMenuDetails) => () => {
    axios.post(`/v1/menu/${id}/reloadClients`)
        .then(response => {
            getMenuDetails();
            toastManager.add(i18next.t("reload_clients_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const createEntry = (createRequest, fetchMealsForInterval, toastManager) => () => {
    axios.post(`/v1/calendar`, createRequest)
        .then(response => {
            fetchMealsForInterval();
            toastManager.add(i18next.t("calendar_entry_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("calendar_entry_created_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}

export const updateEntry = (selectedEntryId, patchRequest, fetchMealsForInterval, toastManager) => () => {
    axios.patch(`/v1/calendar/${selectedEntryId}`, patchRequest)
        .then(response => {
            fetchMealsForInterval();
            toastManager.add(i18next.t("calendar_entry_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("calendar_entry_updated_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}

export const updateFFQFactor = (requestData, toastManager) => () => {
    axios.patch(`/v1/calendar/update-ffq`, requestData)
        .then(response => {
            toastManager.add(i18next.t("ffq_factor_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("ffq_factor_updated_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
}