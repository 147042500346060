import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Card, Form, FormGroup, Row, Col, Button, Label, Input, CardHeader, CardBody, CardTitle } from "reactstrap";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { createDiagnosis, updateDiagnosis } from '../../redux/actions/diagnosisActions';

class Diagnosis extends React.Component {

    emptyDiagnosis = {
        code: '',
        name: '',
        latinName: ''
    }

    constructor(props) {
        super(props);
        this.state = {
            diagnosis: this.emptyDiagnosis,
            initialDiagnosis: this.emptyDiagnosis
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.getDiagnosisById();
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let diagnosis = { ...this.state.diagnosis };
        diagnosis[name] = value;

        this.setState({ diagnosis: diagnosis });
    }

    async getDiagnosisById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/diagnosis/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.setState({
                        diagnosis: response.data.diagnosis,
                        initialDiagnosis: JSON.parse(JSON.stringify(response.data.diagnosis)),
                        isLoading: false
                    });
                })
                .catch(error => {
                    this.toastManager.add(this.props.t("get_diagnosis_by_id"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                    });
                })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        // To prevent router prompt from opening on submit
        this.setState({ initialDiagnosis: this.state.diagnosis });

        Promise.all([this.props.match.params.id ?
            this.props.updateDiagnosis(this.toastManager, this.props.match.params.id, this.state.diagnosis) : this.props.createDiagnosis(this.toastManager, this.state.diagnosis)])
            .then(() => {
                this.props.history.push('/admin/diagnosisList')
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    render() {

        const { diagnosis, initialDiagnosis } = this.state;
        const { isLoading } = this.props.diagnosisData;
        const { t } = this.props;

        const disabledButton = diagnosis.code && diagnosis.name && diagnosis.latinName

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="content">
                <Prompt when={JSON.stringify(diagnosis) !== JSON.stringify(initialDiagnosis)} message={t("warning_message")} />
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={8} md={7} lg={6} xl={5} className={'col-xxl-4 col-wxl-3'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("diagnosis")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("code")}</Label>
                                        <Input
                                            name="code"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("code")}
                                            value={diagnosis.code}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-280'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={diagnosis.name}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("latin_name")}</Label>
                                        <Input
                                            name="latinName"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("latin_name")}
                                            value={diagnosis.latinName}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                </CardBody>
                            </Card>
                            {
                                this.props.match.params.id ?
                                    <Button disabled={(JSON.stringify(diagnosis) === JSON.stringify(initialDiagnosis))} type="submit" color="success"> {t("update")}</Button> :
                                    <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>
                            }
                        </Col>
                    </Row>

                </Form >
            </div >
        );
    }
}

Diagnosis.propTypes = {
    diagnosisData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    diagnosisData: state.diagnosisData
})

const mapActionsToProps = { createDiagnosis, updateDiagnosis }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Diagnosis)))