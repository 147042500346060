import {
    CREATE_ALLERGEN,
    SET_ALLERGENS,
    DELETE_ALLERGEN,
    SET_ALLERGENS_ALERT,
    SET_ALLERGENS_ALERT_DISHES,
    SET_ALLERGENS_ON_MENU,
    SET_COPY_ALLERGEN_LOADING
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createAllergen = (toastManager, request) => (dispatch) => {
    axios.post('/v1/allergen', request)
        .then(response => {
            dispatch({
                type: CREATE_ALLERGEN,
                payload: response.data.allergen
            });
            toastManager.add(i18next.t("allergen_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateAllergen = (toastManager, id, request) => () => {
    axios.patch(`/v1/allergen/${id}`, request)
        .then(() => {
            toastManager.add(i18next.t("allergen_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(() => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getAllergens = (page, size) => (dispatch) => {
    axios.get(`/v1/allergen?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_ALLERGENS,
                payload: response.data.allergens
            })
        })
        .catch(error => {
            dispatch({
                type: SET_ALLERGENS,
                payload: []
            })
        })
}

export const searchAllergens = (page, size, search) => (dispatch) => {
    axios.get(`/v1/allergen/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_ALLERGENS,
                payload: response.data.allergens
            });
        })
        .catch(error => {
            dispatch({
                type: SET_ALLERGENS,
                payload: []
            })
        })
}

export const deleteAllergen = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/allergen/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_ALLERGEN,
                payload: id
            })
            toastManager.add(i18next.t("allergen_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getAllergens(0, 10))
        })
        .catch((error) => {
            if (error.response.data && error.response.data.code === 4002) {
                toastManager.add(i18next.t("delete_allergen_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const getAllergenIssue = (request, toastManager) => (dispatch) => {
    axios.post(`/v1/allergen/foodWithAllergens`, { idList: request })
        .then(response => {
            dispatch({
                type: SET_ALLERGENS_ALERT,
                payload: response.data.foodWithAllergens
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("detail_allergens_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getAllergenIssueDishes = (request, toastManager) => (dispatch) => {
    axios.post(`/v1/allergen/dishesWithAllergens`, { idList: request })
        .then(response => {
            dispatch({
                type: SET_ALLERGENS_ALERT_DISHES,
                payload: response.data.dishesWithAllergens
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("detail_allergens_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getAllergensIssue = (menuId, toastManager) => (dispatch) => {
    axios.get(`/v1/allergen/${menuId}/menu`)
        .then(response => {
            dispatch({
                type: SET_ALLERGENS_ON_MENU,
                payload: response.data.dishesWithAllergens
            })
        })
        .catch(error => {
            toastManager.add(i18next.t("allergens_issue_error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const copySelectedAllergens = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_ALLERGEN_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/allergen/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("allergens_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_ALLERGEN_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("allergens_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_ALLERGEN_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}

export const copyAllAllergens = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_ALLERGEN_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post(`/v1/allergen/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("allergens_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_ALLERGEN_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("allergens_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_ALLERGEN_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}