import React from "react";
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { Card, Form, FormGroup, Row, Col, Button, Label, Input, CardHeader, CardBody, CardTitle } from "reactstrap";
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { createFodmap, updateFodmap } from '../../redux/actions/fodmapActions';
// Util
import { Typeahead } from "react-bootstrap-typeahead";

class Fodmap extends React.Component {

    emptyFodmap = {
        name: '',
        description: '',
        foods: []
    }

    constructor(props) {
        super(props);
        this.state = {
            fodmap: this.emptyFodmap,
            initialFodmap: this.emptyFodmap,
            isLoading: true
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        Promise.all([this.getFodmapById()])
            .then(() => {
                this.setState({ isLoading: false })
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        let fodmap = { ...this.state.fodmap };
        fodmap[name] = value;

        this.setState({ fodmap: fodmap });
    }

    handleChangeFoods = (foods) => {
        let fodmap = { ...this.state.fodmap };

        if (foods.length <= 0) {
            fodmap.foods = [];
        } else {
            fodmap.foods = foods;
        }
        this.setState({ fodmap: fodmap });
    }

    async getFodmapById() {
        if (this.props.match.params.id !== undefined) {
            await axios.get(`/v1/fodmap/${this.props.match.params.id}`, {
                headers: {
                    'Accept': 'application/json'
                }
            })
                .then(response => {
                    this.setState({
                        fodmap: response.data.fodmap,
                        initialFodmap: JSON.parse(JSON.stringify(response.data.fodmap)),
                        isLoading: false
                    });
                })
                .catch(() => {
                    this.toastManager.add(this.props.t("get_fodmap_by_id"), {
                        appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000, isLoading: false
                    });
                })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { fodmap } = this.state;

        // To prevent router prompt from opening on submit
        this.setState({ initialFodmap: fodmap });

        let foods = []
        fodmap.foods.forEach(food => {
            let foodObj = {
                id: food.id,
                name: food.name,
            }
            foods.push(foodObj);
        })

        let fodmapData = {
            name: fodmap.name,
            description: fodmap.description,
            foods: foods
        }

        Promise.all([this.props.match.params.id ?
            this.props.updateFodmap(this.toastManager, this.props.match.params.id, fodmapData) : this.props.createFodmap(this.toastManager, fodmapData)])
            .then(() => {
                this.props.history.push('/admin/fodmapList')
            })
            .catch(error => {
                console.log('Promise rejected:', error)
            })
    }

    render() {

        const { fodmap, initialFodmap, isLoading } = this.state;
        const { foods } = this.props.dishData;
        const { t } = this.props;

        const disabledButton = fodmap.name && fodmap.description && fodmap.foods.length > 0

        if (isLoading) {
            return (
                <div className="content">
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className="content">
                <Prompt when={JSON.stringify(fodmap) !== JSON.stringify(initialFodmap)} message={t("warning_message")} />
                <Form onSubmit={this.handleSubmit}>
                    <Row>
                        <Col sm={8} md={8} lg={8} xl={8} className={'col-xxl-6 col-wxl-4'}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col style={{ textAlign: 'left' }}>
                                            <CardTitle tag="h3"><strong>{t("fodmap")}</strong></CardTitle>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <Button type="button" color="primary" size="sm" onClick={() => window.history.back()}><i className="fas fa-arrow-left"></i></Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("name")}</Label>
                                        <Input
                                            name="name"
                                            type="text"
                                            onChange={this.handleChange}
                                            placeholder={t("name")}
                                            value={fodmap.name}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-350'}>
                                        <Label>{t("description")}</Label>
                                        <Input
                                            name="description"
                                            type="textarea"
                                            rows="4"
                                            onChange={this.handleChange}
                                            placeholder={t("description")}
                                            value={fodmap.description}
                                            autoComplete="off"
                                        />
                                    </FormGroup>
                                    <FormGroup className={'mw-450'}>
                                        <Label>{t("foods")}</Label>
                                        <Typeahead
                                            selected={fodmap.foods}
                                            id="foods"
                                            clearButton
                                            multiple
                                            labelKey={option => `${option.name}`}
                                            onChange={(e) => this.handleChangeFoods(e)}
                                            options={foods}
                                            placeholder={t("choose_foods")}
                                            renderMenuItemChildren={(option) => (
                                                <div>
                                                    <strong>{option.name}</strong>
                                                </div>
                                            )}
                                            paginationText={t("display_additional")}
                                            emptyLabel={t("no_matches_found")}
                                        />
                                    </FormGroup>
                                </CardBody>
                            </Card>
                            {
                                this.props.match.params.id ?
                                    <Button disabled={(JSON.stringify(fodmap) === JSON.stringify(initialFodmap))} type="submit" color="success"> {t("update")}</Button> :
                                    <Button disabled={!disabledButton} type="submit" color="success">{t("create")}</Button>
                            }
                        </Col>
                    </Row>

                </Form >
            </div >
        );
    }
}

Fodmap.propTypes = {
    fodmapData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    fodmapData: state.fodmapData,
    dishData: state.dishData
})

const mapActionsToProps = { createFodmap, updateFodmap }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Fodmap)))