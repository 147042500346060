import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Row, Col, CardBody, Table, CardTitle, Button } from "reactstrap";
import { Pie } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

export class MacronutrientsCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pieChart: true,
            tableData: false
        }
    }

    handleFileSubmit = (mealId, handleContentDisposition) => {

        const canvasSave = document.getElementById('chart');
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "");
        axios.post(`/v1/report/meal/chart/macro/${mealId}`, base64Img, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        })
            .then(response => {
                handleContentDisposition(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        const { data, options, width, height, legend, t, macronutrients, macronutrientRatio } = this.props;

        let macronutrientsRatio = null;
        let macronutrientsTable = null;
        if (macronutrients) {
            macronutrientsTable =
                <Table hover className={'mt-4'}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("energy")}</th>
                            <th>{t("carbohydrate")}</th>
                            <th>{t("fat")}</th>
                            <th>{t("protein")}</th>
                            <th>{t("fibre")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><strong>{t("total")} </strong>({t("per_person")})</th>
                            <td>{macronutrients.energy && macronutrients.energy.toFixed(0)} kcal</td>
                            <td>{macronutrients.carbohydrates && macronutrients.carbohydrates.toFixed(1)} g</td>
                            <td>{macronutrients.fat && macronutrients.fat.toFixed(1)} g</td>
                            <td>{macronutrients.protein && macronutrients.protein.toFixed(1)} g</td>
                            <td>{macronutrients.fibre && macronutrients.fibre.toFixed(1)} g</td>
                        </tr>
                    </tbody>
                </Table>
        }


        if (macronutrientRatio) {
            macronutrientsRatio =
                <Table className="tableFixHead">
                    <thead>
                        <tr>
                            <th><strong>{t("macronutients")}</strong></th>
                            <th><strong>{t("value")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(macronutrientRatio).map((field, index) => {
                            return <tr key={index}>
                                <td style={field[0] === "carbohydrateRatio" ? { backgroundColor: '#FCF955' } : field[0] === "fatRatio" ? { backgroundColor: '#F76A6E' } : field[0] === "proteinRatio" ? { backgroundColor: '#68A2EB' } : null} >
                                    {field[0] === "carbohydrateRatio" ? <span>{t("monosaccharide") + " / " + t("polysaccharide")}</span> : null}
                                    {field[0] === "fatRatio" ? <span>{t("saturated") + " / " + t("unsaturated")}</span> : null}
                                    {field[0] === "proteinRatio" ? <span>{t("animal") + " / " + t("plant")}</span> : null}
                                </td>
                                <td>
                                    {field[1]}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table >
        }


        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("macronutrients")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button disabled={!this.props.mealId || this.state.tableData} type="button" color="success" size="sm" onClick={() => this.handleFileSubmit(this.props.mealId, this.props.handleContentDisposition)}><i className="fas fa-download"></i></Button>
                            {this.state.pieChart ?
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ pieChart: false, tableData: true })}>{t("details")}</Button> :
                                <Button type="button" color="success" size="sm" onClick={() => this.setState({ pieChart: true, tableData: false })}>{t("details")}</Button>}
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    {this.state.pieChart && <div><Row>
                        <Col md={12} align={'center'}>
                            <div className={'chart-container'}>
                                <Pie
                                    id="chart"
                                    data={data}
                                    options={options}
                                    legend={legend}
                                    width={width}
                                    height={height} />
                            </div>
                        </Col>
                    </Row>
                        <Row>
                            <Col md={12}>
                                {macronutrientsTable}
                            </Col>
                        </Row></div>}
                    {this.state.tableData && <Row>
                        <Col md={12}>
                            {macronutrientsRatio}
                        </Col>
                    </Row>}
                </CardBody>
            </Card>
        )
    }
}

MacronutrientsCard.propTypes = {
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    legend: PropTypes.object.isRequired,
    macronutrients: PropTypes.object.isRequired,
    mealId: PropTypes.number,
    handleContentDisposition: PropTypes.func
}

export default withTranslation()(MacronutrientsCard);
