import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Button, Card, FormGroup, Col, Table, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar'
import NumberFormat from 'react-number-format';

class NutrientIntervalsCard extends Component {

    handleChange = (index, event) => {
        this.props.handleChange(index, event)
    }

    toggleEditable = (index, nutrientInterval) => {
        this.props.toggleEditable(index, nutrientInterval);
    }

    openDialogDelete = (nutrientInterval, editableIndexes) => {
        this.props.openDialogDelete(nutrientInterval, editableIndexes);
    }

    toggleModal = () => {
        this.props.toggleModal();
    }

    render() {

        const { editableIndexes, t, diet } = this.props

        const kcalIndexes = diet.nutrientIntervals.map((nutrientInterval, index) => {
            if (nutrientInterval.nutrientType === "TOTAL_CARBOHYDRATES" || nutrientInterval.nutrientType === "TOTAL_FAT" || nutrientInterval.nutrientType === "TOTAL_PROTEIN") return index
            return null
        })

        const nutrientIntervalItems = diet.nutrientIntervals.map((nutrientInterval, index) => {
            return <tr key={index}>
                <td><Input disabled type="text" name={"nutrientType" + index} id={"nutrientType" + index} value={t(nutrientInterval.nutrientType)} /></td>
                <td><NumberFormat disabled={!editableIndexes.some(i => i === index)} className="form-control" name={"fromValue" + index} id={"fromValue" + index} onChange={(event) => this.handleChange(index, event)} value={nutrientInterval.fromValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} /></td>
                <td><NumberFormat disabled={!editableIndexes.some(i => i === index)} className="form-control" name={"toValue" + index} id={"toValue" + index} onChange={(event) => this.handleChange(index, event)} value={nutrientInterval.toValue} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} /></td>

                <td><Input disabled type="text" name={"unit" + index} id={"unit" + index} value={nutrientInterval.nutrientTypeUnit} /></td>
                {nutrientInterval.nutrientType === "TOTAL_CARBOHYDRATES" || nutrientInterval.nutrientType === "TOTAL_FAT" || nutrientInterval.nutrientType === "TOTAL_PROTEIN" ? <td></td> : <td>
                    {!editableIndexes.some(i => i === index) ? <Button className="btn-icon btn-round" type="button" color="info" size="sm" onClick={nutrientInterval.nutrientType === "KILOCALORIES" ? () => { kcalIndexes.push(index); this.toggleEditable(kcalIndexes) } : () => { this.toggleEditable([index]) }}><i className="tim-icons icon-settings-gear-63"></i></Button> : <Button /* disabled={this.checkNutrientIntervals()} */ className="btn-icon btn-round" type="button" color="success" size="sm" onClick={() => this.toggleEditable([], nutrientInterval)}><i className="tim-icons icon-check-2"></i></Button>}{' '}
                    <Button className="btn-icon btn-round" type="button" color="warning" size="sm" onClick={() => this.openDialogDelete(nutrientInterval, index)}><i className="tim-icons icon-simple-delete"></i></Button>
                </td>}
            </tr>
        });

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col style={{ textAlign: 'left' }}>
                            <CardTitle tag="h3"><strong>{t("nutrient_intervals")}</strong></CardTitle>
                        </Col>
                        <Col style={{ textAlign: 'right' }}>
                            <Button style={{ textAlign: 'right' }} type="button" color="success" size="sm" onClick={this.toggleModal}>{t("add")}</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <FormGroup row inline>
                        <Col>
                            {nutrientIntervalItems.length > 0 ?
                                <PerfectScrollbar style={{ height: 'auto', maxHeight: '480px' }}>
                                    <Table borderless className="tableFixHead">
                                        <thead>
                                            <tr>
                                                <th width="40%">{t("type")}</th>
                                                <th width="15%">{t("from")}</th>
                                                <th width="15%">{t("to")}</th>
                                                <th width="15%">{t("unit")}</th>
                                                <th width="15%">{t("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nutrientIntervalItems}
                                        </tbody>
                                    </Table>
                                </PerfectScrollbar> : null}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        )
    }
}

NutrientIntervalsCard.propTypes = {
    diet: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    toggleEditable: PropTypes.func.isRequired,
    openDialogDelete: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    editableIndexes: PropTypes.array.isRequired
}

export default withTranslation()(NutrientIntervalsCard);
