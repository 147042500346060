import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, Row, Col, CardBody, Table, CardTitle} from "reactstrap";
import { withTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar'

class MineralsTableCard extends Component {

    render() {

        const { data, t } = this.props;

        let mineralsTable = null;
        if (data.length > 0) {
            mineralsTable = <PerfectScrollbar style={{ height: 'auto', maxHeight: '480px' }}>
                <Table className="tableFixHead">
                    <thead>
                        <tr>
                            <th><strong>{t("minerals")}</strong></th>
                            <th><strong>{t("value")}</strong> ({t("per_person")})</th>
                            <th><strong>{t("unit")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((data, index) => {
                            return <tr key={index}>
                                <td>{t(data.mineralName)}</td>
                                <td>{data.value}</td>
                                <td>{data.unit}</td>
                            </tr>
                        })}
                    </tbody>
                </Table>
            </PerfectScrollbar>
        }

        return (
            <Card className={'card-minerals'}>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("minerals")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={12}>
                            {mineralsTable}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

MineralsTableCard.propTypes = {
    data: PropTypes.array.isRequired
}

export default withTranslation()(MineralsTableCard);
