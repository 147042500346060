import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardTitle, Row, Col, Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import DraggableModal from '../components/modal/DraggableModal'
import DashboardCard from "../components/dashboard/DashboardCard"
import axios from 'axios';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
// Redux
import { connect } from 'react-redux';
import { getTotalClients, getTotalMeals, getTotalDiets, getTotalDishes, getClientsWithoutDiet, getLatestClients, getLatestMeals, getUnapprovedFoods, getUnapprovedDishes, getUnapprovedDiets, getUnapprovedMeals, updateMealApproved, updateDietApproved, updateFoodApproved, updateDishApproved } from '../redux/actions/dashboardActions';
import { getDiets, updateUser } from '../redux/actions/userActions'
import { Typeahead } from "react-bootstrap-typeahead";
// Util
import { hasRole } from '../util/hasRole'

const inactiveStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#ffffff #ffffff #e3e3e3'
};

const activeStyle = {
    cursor: 'pointer',
    color: '#525f7f',
    backgroundColor: '#ffffff',
    borderColor: '#e3e3e3 #e3e3e3 #ba54f5'
};


class Dashboard extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            user: {
                username: ''
            },
            modalDietOpen: false,
            dietToAdd: [],
            activeTab: '1',
        };
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.getTotalClients()
        this.props.getTotalMeals()
        this.props.getTotalDiets()
        this.props.getTotalDishes()
        this.props.getClientsWithoutDiet(0, 7)
        this.props.getLatestClients(0, 7)
        this.props.getLatestMeals(7)
        this.props.getDiets()
        if (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) {
            this.props.getUnapprovedFoods(0, 7);
            this.props.getUnapprovedDishes(0, 7);
            this.props.getUnapprovedDiets(0, 7);
            this.props.getUnapprovedMeals(0, 7);
        }
        this.setState({ isLoading: false });
    }

    async onChangePageClientsWithoutMeal(page, size) {
        await this.props.getClientsWithoutDiet(page - 1, this.props.dashboardData.sizeClientsWithoutMeal);
    }

    async onChangePageLatestClients(page, size) {
        await this.props.getLatestClients(page - 1, this.props.dashboardData.sizeLatestClients);
    }

    async onChangePageUnapprovedFoods(page, size) {
        await this.props.getUnapprovedFoods(page - 1, this.props.dashboardData.sizeUnapprovedFoods);
    }

    async onChangePageUnapprovedDishes(page, size) {
        await this.props.getUnapprovedDishes(page - 1, this.props.dashboardData.sizeUnapprovedDishes);
    }

    async onChangePageUnapprovedDiets(page, size) {
        await this.props.getUnapprovedDiets(page - 1, this.props.dashboardData.sizeUnapprovedDiets);
    }

    async onChangePageUnapprovedMeals(page, size) {
        await this.props.getUnapprovedMeals(page - 1, this.props.dashboardData.sizeUnapprovedMeals);
    }

    toggleModalDiet = (diet) => {
        this.setState({ modalDietOpen: !this.state.modalDietOpen, user: diet });
    }

    handleChangeDiet = (diet) => {
        let dietToAdd = { ...this.state.dietToAdd };

        if (diet.length <= 0) {
            dietToAdd = [];
        } else {
            dietToAdd = diet;
        }
        this.setState({ dietToAdd: dietToAdd });
    }

    setDiets = () => {
        let { dietToAdd } = this.state;
        const data = {
            diet: dietToAdd[0]
        }

        axios.patch(`/v1/user/${this.state.user.username}`, data)
            .then(response => {
                this.toastManager.add(this.props.t("client_diet_updated_message"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                });
                this.props.getClientsWithoutDiet(0, 7);
                this.setState({ dietToAdd: [] })
            })
            .catch(error => {
                this.toastManager.add(this.props.t("error") + error.response.status + " " + error.response.statusText, {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            })

        this.toggleModalDiet();
    }

    toggleActiveTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render() {

        const { totalClients, totalMeals, totalDiets, totalDishes, clientsWithoutMeal, latestClients, latestMeals, totalElementsLatestClients, unapprovedFoods, unapprovedDishes, unapprovedDiets, unapprovedMeals, totalElementsUnapprovedFoods, totalElementsUnapprovedDishes, totalElementsUnapprovedDiets, totalElementsUnapprovedMeals } = this.props.dashboardData;
        const { isLoading, modalDietOpen, dietToAdd } = this.state;
        const { diets } = this.props.userData;
        const { t } = this.props;

        latestClients && latestClients.forEach(data => {
            data.sex = t(data.sex)
        })

        const usersColumns = [
            {
                name: t("first_name"),
                selector: "firstName"
            },
            {
                name: t("last_name"),
                selector: "lastName"
            },
            {
                name: t("sex"),
                selector: "sex"
            },
            {
                name: t("phone"),
                selector: "phone"
            },
            {
                name: "",
                center: true,
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/user/" + row.username}><i className="tim-icons icon-pencil"></i></Button>
                </span>
            }
        ]

        const mealsColumns = [
            {
                name: t("name"),
                selector: "name",
                width: "40%"
            },
            {
                name: t("description"),
                selector: "description",
                width: "30%"
            },
            {
                name: t("created_at"),
                selector: "createdAt",
                width: "20%",
                cell: row => moment.unix(row.createdAt).format('DD.MM.YYYY.')
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: "",
                width: "10%",
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="success" size="sm" tag={Link} to={"/admin/meal/" + row.id}><i className="tim-icons icon-pencil"></i></Button>
                </span>
            }

        ]

        const clientWithoutMealColumns = [
            {
                name: t("first_name"),
                selector: "firstName",
                width: "30%"
            },
            {
                name: t("last_name"),
                selector: "lastName",
                width: "30%"
            },
            {
                name: t("username"),
                selector: "username",
                width: "30%"
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: "",
                width: "10%",
                cell: row => <span>
                    <Button className="btn-icon btn-round" color="success" size="sm" onClick={() => this.toggleModalDiet(row)} ><i className="fas fa-plus"></i></Button>
                </span>
            }

        ]

        const unapprovedFoodsColumns = [
            {
                name: t("name"),
                selector: "name",
                width: "50%"
            },
            {
                name: t("updated_at"),
                selector: "updatedAt",
                width: "20%",
                cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
            },
            {
                name: t("details"),
                width: "10%",
                cell: (row) => <span>
                    <Button color="info" size="sm" tag={Link} to={"/admin/food/" + row.id}><i className="fas fa-info"></i></Button>
                </span>
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: t("approved"),
                selector: "approved",
                width: "20%",
                cell: (row) =>
                    <Button color="warning" size="sm" onClick={() => this.props.updateFoodApproved(row.id, !row.approved, this.toastManager)}><i className="fas fa-check"></i></Button>
            }

        ]

        const unapprovedDishesColumns = [
            {
                name: t("name"),
                selector: "name",
                width: "38%"
            },
            {
                name: t("type"),
                selector: "type",
                width: "20%"
            },
            {
                name: t("updated_at"),
                selector: "updatedAt",
                width: "20%",
                cell: row => moment.unix(row.updatedAt).format('DD.MM.YYYY.')
            },
            {
                name: t("details"),
                width: "10%",
                cell: (row) => <span>
                    <Button color="info" size="sm" tag={Link} to={"/admin/dish/" + row.id}><i className="fas fa-info"></i></Button>
                </span>
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: t("approved"),
                selector: "approved",
                width: "12%",
                cell: (row) =>
                    <Button color="warning" size="sm" onClick={() => this.props.updateDishApproved(row.id, !row.approved, this.toastManager)}><i className="fas fa-check"></i></Button>
            }

        ]

        const unapprovedDietsColumns = [
            {
                name: t("name"),
                selector: "name",
                width: "30%"
            },
            {
                name: t("description"),
                selector: "description",
                width: "20%"
            },
            {
                name: t("updated_at"),
                selector: "updatedAt",
                width: "20%",
                cell: row => moment(row.updatedAt).format('DD.MM.YYYY.')
            },
            {
                name: t("details"),
                width: "10%",
                cell: (row) => <span>
                    <Button color="info" size="sm" tag={Link} to={"/admin/diet/" + row.id}><i className="fas fa-info"></i></Button>
                </span>
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: t("approved"),
                selector: "approved",
                width: "20%",
                cell: (row) =>
                    <Button color="warning" size="sm" onClick={() => this.props.updateDietApproved(row.id, !row.approved, this.toastManager)} ><i className="fas fa-check"></i></Button>
            }

        ]

        const unapprovedMealsColumns = [
            {
                name: t("name"),
                selector: "name",
                width: "30%"
            },
            {
                name: t("description"),
                selector: "description",
                width: "20%"
            },
            {
                name: t("updated_at"),
                selector: "updatedAt",
                width: "20%",
                cell: row => moment(row.updatedAt).format('DD.MM.YYYY.')
            },
            {
                name: t("details"),
                width: "10%",
                cell: (row) => <span>
                    <Button color="info" size="sm" tag={Link} to={"/admin/meal/" + row.id}><i className="fas fa-info"></i></Button>
                </span>
            },
            (hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && {
                name: t("approved"),
                selector: "approved",
                width: "20%",
                cell: (row) =>
                    <Button color="warning" size="sm" onClick={() => this.props.updateMealApproved(row.id, !row.approved, this.toastManager)} ><i className="fas fa-check"></i></Button>
            }

        ]

        const disabledAddDiet = dietToAdd.length === 0;
        const modalDiet = <DraggableModal
            isOpen={modalDietOpen}
            toggle={this.toggleModalDiet}
            size={"sm"}
            title={<strong>{t("add_diet")}</strong>}
            body={<Col md={10}>
                <Typeahead
                    selected={dietToAdd}
                    id="diet"
                    clearButton
                    labelKey="name"
                    onChange={this.handleChangeDiet}
                    options={diets}
                    multiple
                    placeholder={t("choose_diet")}
                    renderMenuItemChildren={(option) => (
                        <div>
                            <strong>{option.name}</strong>
                        </div>
                    )}
                    paginationText={t("display_additional")}
                    emptyLabel={t("no_matches_found")}
                />
            </Col>}
            footer={
                <Button disabled={disabledAddDiet} color="success" onClick={this.setDiets}>{t("set")}</Button>
            }
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        return (
            <div className="content content-dashboard">
                <Row>
                    <Col sm={6} className={'col-xxl-3'}>
                        <DashboardCard id="client-card" title={t("total_clients")} total={totalClients === null ? 0 : totalClients} iconClass="fas fa-users fa-5x" footerText={t("add_new_client")} path={'/admin/user'} isSuperAdmin={hasRole('ROLE_SUPER_ADMIN')} isAdmin={hasRole('ROLE_ADMIN')} pathTotal={'/admin/userList'} />
                    </Col>
                    <Col sm={6} className={'col-xxl-3'}>
                        <DashboardCard id="meal-card" title={t("total_meals")} total={totalMeals === null ? 0 : totalMeals} iconClass="fas fa-bars fa-5x" footerText={t("create_new_meal")} path={'/admin/meal'} isSuperAdmin={hasRole('ROLE_SUPER_ADMIN')} isAdmin={hasRole('ROLE_ADMIN')} pathTotal={'/admin/mealList'} />
                    </Col>
                    <Col sm={6} className={'col-xxl-3'}>
                        <DashboardCard id="diet-card" title={t("total_diets")} total={totalDiets === null ? 0 : totalDiets} iconClass="fas fa-heartbeat fa-5x" footerText={t("create_new_diet")} path={'/admin/diet'} isSuperAdmin={hasRole('ROLE_SUPER_ADMIN')} isAdmin={hasRole('ROLE_ADMIN')} pathTotal={'/admin/dietList'} />
                    </Col>
                    <Col sm={6} className={'col-xxl-3'}>
                        <DashboardCard id="dish-card" title={t("total_dishes")} total={totalDishes === null ? 0 : totalDishes} iconClass="fas fa-utensils fa-5x" footerText={t("create_new_dish")} path={'/admin/dish'} isSuperAdmin={hasRole('ROLE_SUPER_ADMIN')} isAdmin={hasRole('ROLE_ADMIN')} pathTotal={'/admin/dishList'} />
                    </Col>
                </Row>
                <Row className={'even-height-row'}>
                    <Col xl={6}>
                        <Card className={'even-height-card'}>
                            <CardHeader>
                                <Row>
                                    <Col xs={10}>
                                        <CardTitle tag="h3"><strong>{t("last_created_clients")} </strong></CardTitle>
                                    </Col>
                                    <Col xs={2}>
                                        <div className={'text-right'} style={{ margin: '4px 10px 0 0' }}>
                                            <Link to={"/admin/userList"}>
                                                <i className="fas fa-search fa-2x"></i>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={usersColumns}
                                    data={latestClients}
                                    noHeader={true}
                                    highlightOnHover
                                    pagination
                                    paginationServer
                                    paginationPerPage={7}
                                    paginationRowsPerPageOptions={[7]}
                                    paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                    noDataComponent={t("no_records")}
                                    paginationTotalRows={totalElementsLatestClients}
                                    onChangePage={(page, totalRows) => this.onChangePageLatestClients(page, totalRows)}

                                />
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xl={6}>
                        <Card className={'even-height-card'}>
                            <CardHeader>
                                <CardTitle tag="h3"><strong>{t("client_without_diet")} </strong></CardTitle>
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <ClipLoader
                                            size={60}
                                            color={"#e14eca"}
                                            loading={isLoading}
                                        />
                                    </div>
                                ) : (
                                    <DataTable
                                        columns={clientWithoutMealColumns}
                                        data={clientsWithoutMeal}
                                        noHeader={true}
                                        highlightOnHover
                                        pagination
                                        paginationServer
                                        paginationPerPage={7}
                                        paginationRowsPerPageOptions={[7]}
                                        paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                        noDataComponent={t("no_records")}
                                        paginationTotalRows={this.props.dashboardData.totalElementsClientsWithoutMeal}
                                        onChangePage={(page, totalRows) => this.onChangePageClientsWithoutMeal(page, totalRows)}
                                    />
                                )}
                            </CardBody>
                        </Card>
                        {modalDiet}
                    </Col>
                </Row>

                <Row className={'even-height-row'}>
                    <Col xl={6}>
                        <Card className={'even-height-card'}>
                            <CardHeader>
                                <Row>
                                    <Col xs={10}>
                                        <CardTitle tag="h3"><strong>{t("last_created_meals")} </strong></CardTitle>
                                    </Col>
                                    <Col xs={2}>
                                        <div className={'text-right'} style={{ margin: '4px 10px 0 0' }}>
                                            <Link to={"/admin/mealList"}>
                                                <i className="fas fa-search fa-2x"></i>
                                            </Link>
                                        </div>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={mealsColumns}
                                    data={latestMeals}
                                    noHeader={true}
                                    highlightOnHover
                                />
                            </CardBody>
                        </Card>
                    </Col>
                    {(hasRole('ROLE_SUPER_ADMIN') || hasRole('ROLE_ADMIN')) && <Col xl={6}>
                        <Card className={'even-height-card'}>
                            <CardHeader>
                                <CardTitle tag="h3"><strong>{t("awaiting_approval")}</strong></CardTitle>
                            </CardHeader>
                            <CardBody className={'approval'}>
                                <Nav tabs className={'nav-tabs-custom'}>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '1'}
                                            style={this.state.activeTab === "1" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('1')}>
                                            {t("food")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '2'}
                                            style={this.state.activeTab === "2" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('2')}>
                                            {t("dish")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '3'}
                                            style={this.state.activeTab === "3" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('3')}>
                                            {t("diet")}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            active={this.state.activeTab === '4'}
                                            style={this.state.activeTab === "4" ? activeStyle : inactiveStyle}
                                            onClick={() => this.toggleActiveTab('4')}>
                                            {t("meal")}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <DataTable
                                            columns={unapprovedFoodsColumns}
                                            data={unapprovedFoods}
                                            noHeader={true}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationPerPage={7}
                                            paginationRowsPerPageOptions={[7]}
                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                            noDataComponent={t("no_records")}
                                            paginationTotalRows={totalElementsUnapprovedFoods}
                                            onChangePage={(page, totalRows) => this.onChangePageUnapprovedFoods(page, totalRows)}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <DataTable
                                            columns={unapprovedDishesColumns}
                                            data={unapprovedDishes}
                                            noHeader={true}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationPerPage={7}
                                            paginationRowsPerPageOptions={[7]}
                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                            noDataComponent={t("no_records")}
                                            paginationTotalRows={totalElementsUnapprovedDishes}
                                            onChangePage={(page, totalRows) => this.onChangePageUnapprovedDishes(page, totalRows)}
                                        />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <DataTable
                                            columns={unapprovedDietsColumns}
                                            data={unapprovedDiets}
                                            noHeader={true}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationPerPage={7}
                                            paginationRowsPerPageOptions={[7]}
                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                            noDataComponent={t("no_records")}
                                            paginationTotalRows={totalElementsUnapprovedDiets}
                                            onChangePage={(page, totalRows) => this.onChangePageUnapprovedDiets(page, totalRows)}
                                        />
                                    </TabPane>
                                    <TabPane tabId="4">
                                        <DataTable
                                            columns={unapprovedMealsColumns}
                                            data={unapprovedMeals}
                                            noHeader={true}
                                            highlightOnHover
                                            pagination
                                            paginationServer
                                            paginationPerPage={7}
                                            paginationRowsPerPageOptions={[7]}
                                            paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                                            noDataComponent={t("no_records")}
                                            paginationTotalRows={totalElementsUnapprovedMeals}
                                            onChangePage={(page, totalRows) => this.onChangePageUnapprovedMeals(page, totalRows)}
                                        />
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>}

                </Row>
            </div>
        );
    }
}

Dashboard.propTypes = {
    dashboardData: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    dashboardData: state.dashboardData,
    auth: state.auth,
    userData: state.userData
})

const mapActionsToProps = { getTotalClients, getTotalMeals, getTotalDiets, getTotalDishes, getClientsWithoutDiet, getLatestClients, getLatestMeals, updateUser, getUnapprovedFoods, getUnapprovedDishes, getUnapprovedDiets, getUnapprovedMeals, updateMealApproved, updateDietApproved, updateFoodApproved, updateDishApproved, getDiets }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(Dashboard)))