import React from "react";
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody, DropdownItem, Row, Col, Button, Label, Input, UncontrolledDropdown, DropdownMenu, DropdownToggle, FormGroup } from "reactstrap";
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import DraggableModal from '../../components/modal/DraggableModal'
import DataTableExtensions from "react-data-table-component-extensions";
// Redux
import { connect } from 'react-redux';
import { getFoods, getFoodsByCategory, deleteFood, searchFood, getCategories, searchFoodsByCategory, copySelectedFoods, copyAllFoods, handleFileSubmit, handleUpdateFileSubmit } from '../../redux/actions/foodActions';
import { getCompaniesForUser } from '../../redux/actions/companyActions';
import store from '../../redux/store'
import { SET_FOOD_LIST_PAGE, SET_COPY_FOOD_LOADING, SET_IMPORT_FOOD_LOADING } from '../../redux/types';
// Util
import { hasRole } from '../../util/hasRole';

class FoodList extends React.Component {

  emptyFood = {
    name: '',
    unitOfMeasurement: '',
    ediblePart: null,
    waterContent: null,
    energyDetail: null,
    carbohydrateDetail: null,
    mineralDetail: null,
    fatDetail: null,
    proteinDetail: null,
    vitaminDetail: null
  }

  constructor(props) {
    super(props);
    this.state = {
      energyModal: false,
      food: null,
      carbohydrateModal: false,
      mineralModal: false,
      proteinModal: false,
      fatModal: false,
      vitaminModal: false,
      deleteFoodModal: false,
      foodToDelete: this.emptyFood,
      isLoadingCopy: false,
      sheet: 'kaić-rak',
      foodSearch: "",
      selectedCategory: this.props.t('all_status'),
      filterDropdown: false,
      isLoading: true,
      categoryEnum: "ALL",
      company: null,
      foodIds: []
    };
    this.toastManager = props.toastManager;
  }

  async componentDidMount() {
    this.props.getFoods(this.props.foodData.page, this.props.foodData.size)
    this.props.getCategories()
    hasRole("ROLE_SUPER_ADMIN") && this.props.getCompaniesForUser()
    this.setState({ isLoading: false })
  }

  handleChange = (id) => {
    let { foodIds } = this.state
    if (!foodIds.includes(id)) {
      foodIds.push(id)
    } else {
      foodIds.splice(foodIds.indexOf(id), 1)
    }
    this.setState({ foodIds: foodIds })
  }

  onChangePage = (selectedPage) => {
    const { foodSearch, selectedCategory, categoryEnum } = this.state
    store.dispatch({
      type: SET_FOOD_LIST_PAGE,
      payload: {
        page: selectedPage - 1
      }
    })

    if (foodSearch !== "") {
      if (categoryEnum === "ALL" && selectedCategory === this.props.t('all_status')) {
        this.props.searchFood(selectedPage - 1, this.props.foodData.size, foodSearch)
      } else if (foodSearch !== "" && categoryEnum !== "ALL" && selectedCategory !== this.props.t('all_status')) {
        this.props.searchFoodsByCategory(selectedPage - 1, this.props.foodData.size, foodSearch, categoryEnum)
      }
    }
    else if (categoryEnum !== "ALL" && selectedCategory !== this.props.t('all_status')) {
      if (foodSearch !== "") {
        if (categoryEnum === "ALL") {
          this.props.searchFood(selectedPage - 1, this.props.foodData.size, foodSearch)
        }
        else {
          this.props.searchFoodsByCategory(selectedPage - 1, this.props.foodData.size, foodSearch, categoryEnum)
        }
      }
      else {
        this.props.getFoodsByCategory(selectedPage - 1, this.props.foodData.size, categoryEnum)
      }
    }
    else {
      this.props.getFoods(selectedPage - 1, this.props.foodData.size)
    }
  }

  onChangeRowsPerPage(currentRowsPerPage, currentPage) {
    store.dispatch({
      type: SET_FOOD_LIST_PAGE,
      payload: {
        size: currentRowsPerPage,
        page: currentPage - 1
      }
    })
    const { foodSearch, categoryEnum, selectedCategory } = this.state
    if (foodSearch !== "" && categoryEnum === "ALL" && selectedCategory === this.props.t('all_status')) {
      this.props.searchFood(currentPage - 1, currentRowsPerPage, foodSearch)
    }
    else if (categoryEnum !== "ALL" && selectedCategory !== this.props.t('all_status')) {
      if (foodSearch !== "") {
        this.props.searchFoodsByCategory(currentPage - 1, currentRowsPerPage, foodSearch, categoryEnum)
      }
      else {
        this.props.getFoodsByCategory(currentPage - 1, currentRowsPerPage, categoryEnum);
      }
    }
    else {
      this.props.getFoods(currentPage - 1, currentRowsPerPage)
    }
  }

  openDialogDelete = (food) => {
    this.setState({ deleteFoodModal: !this.state.deleteFoodModal, foodToDelete: food, })
  }

  openDialogEnergy = (food) => {
    this.setState({ energyModal: !this.state.energyModal, food: food })
  }

  openDialogCarbohydrate = (food) => {
    this.setState({ carbohydrateModal: !this.state.carbohydrateModal, food: food })
  }

  openDialogMineral = (food) => {
    this.setState({ mineralModal: !this.state.mineralModal, food: food })
  }

  openDialogProtein = (food) => {
    this.setState({ proteinModal: !this.state.proteinModal, food: food })
  }

  openDialogFat = (food) => {
    this.setState({ fatModal: !this.state.fatModal, food: food })
  }

  openDialogVitamin = (food) => {
    this.setState({ vitaminModal: !this.state.vitaminModal, food: food })
  }

  closeDeleteFoodDialog = () => {
    this.setState({ deleteFoodModal: !this.state.deleteFoodModal })
  }

  closeFileDialog = () => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        importModal: false,
        selectedFile: null
      }
    })
  }

  closeUpdateFileDialog = () => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        updateModal: false,
        selectedFile: null
      }
    })
  }

  closeCarbohydrateDialog = () => {
    this.setState({ carbohydrateModal: !this.state.carbohydrateModal })
  }

  closeEnergyDialog = () => {
    this.setState({ energyModal: !this.state.energyModal })
  }

  closeMineralDialog = () => {
    this.setState({ mineralModal: !this.state.mineralModal })
  }

  closeProteinDialog = () => {
    this.setState({ proteinModal: !this.state.proteinModal })
  }

  closeFatDialog = () => {
    this.setState({ fatModal: !this.state.fatModal })
  }

  closeVitaminDialog = () => {
    this.setState({ vitaminModal: !this.state.vitaminModal })
  }

  deleteFood = () => {
    this.props.deleteFood(this.toastManager, this.state.foodToDelete.id)
    this.setState({ deleteFoodModal: false })
  }

  openDialogImport = () => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        importModal: true
      }
    })
  }

  openDialogUpdatedImport = () => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        updateModal: true
      }
    })
  }

  toggleFilterDropdown = () => {
    this.setState({ filterDropdown: !this.state.filterDropdown })
  }

  copyDialog = (copyAllDialog) => {
    if (copyAllDialog === false) {
      store.dispatch({ type: SET_COPY_FOOD_LOADING, payload: { copyAllDialog: true, copyDialog: true } })
    }
    else if (copyAllDialog === undefined) {
      store.dispatch({
        type: SET_COPY_FOOD_LOADING, payload: { copyAllDialog: false, copyDialog: true }
      })
    }
  }

  closeDialog = () => {
    store.dispatch({
      type: SET_COPY_FOOD_LOADING,
      payload: {
        copyDialog: false
      }
    })
    this.setState({ company: null })
  }

  handleChangeFilterDropdown = (category, categoryEnum) => {
    this.setState({ selectedCategory: category, categoryEnum: categoryEnum });
    const { foodSearch } = this.state
    if (categoryEnum === "ALL") {
      if (foodSearch !== "") {
        this.props.searchFood(0, this.props.foodData.size, foodSearch)
      }
      else {
        this.props.getFoods(0, this.props.foodData.size);
      }
    } else {
      if (foodSearch !== "") {
        this.props.searchFoodsByCategory(0, this.props.foodData.size, foodSearch, categoryEnum)
      } else if (categoryEnum !== "ALL") {
        this.props.getFoodsByCategory(0, this.props.foodData.size, categoryEnum);
      } else {
        this.props.getFoods(0, this.props.foodData.size);
      }
    }
  }

  handleFileUpload = (event) => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        importModal: true,
        selectedFile: event.target.files[0]
      }
    })
  }

  handleFileUpdateUpload = (event) => {
    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        updateModal: true,
        selectedFile: event.target.files[0]
      }
    })
  }

  handleFoodSearch = (event) => {
    this.setState({ foodSearch: event.target.value });
  }

  getFoodSearch = () => {
    const { foodSearch, selectedCategory, categoryEnum } = this.state
    if (categoryEnum !== "ALL" && selectedCategory !== this.props.t('all_status')) {
      if (foodSearch !== "") {
        this.props.searchFoodsByCategory(0, this.props.foodData.size, foodSearch, categoryEnum)
      } else {
        this.props.searchFoodsByCategory(0, this.props.foodData.size, foodSearch, categoryEnum)
      }
    }
    else {
      this.props.searchFood(0, this.props.foodData.size, foodSearch);
    }
  }

  /* shouldHideEditButton = (row) => {
    if (hasRole('ROLE_STUDENT') && row.approved) {
      return true;
    }
    return false;
  } */

  handleChangeSheet = (e) => {
    const target = e.target;
    const value = target.value;
    let sheet = { ...this.state.sheet };
    sheet = value;
    this.setState({ sheet: sheet });
  }

  handleFileSubmit = () => {
    const data = new FormData()
    data.append('foodExcel', this.props.foodData.selectedFile)
    data.append('sheet', this.state.sheet)

    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        importModal: true,
        isLoadingImport: true
      }
    })

    this.props.handleFileSubmit(this.toastManager, data)
  }

  handleUpdateFileSubmit = () => {
    const data = new FormData()
    data.append('foodExcel', this.props.foodData.selectedFile)
    data.append('sheet', this.state.sheet)

    store.dispatch({
      type: SET_IMPORT_FOOD_LOADING,
      payload: {
        updateModal: true,
        isLoadingImport: true
      }
    })

    this.props.handleUpdateFileSubmit(this.toastManager, data)
  }

  handleChangeCompany = (companyEntry) => {
    let company = { ...this.state.company };

    if (companyEntry.length > 0) {
      company = companyEntry[0];
    } else {
      company = null;
    }
    this.setState({ company: company })
  }

  copyData = () => {
    const { foodIds, company } = this.state
    const requestData = {
      ids: foodIds,
      companyId: company.id
    }
    this.props.copySelectedFoods(requestData, this.toastManager)
  }


  render() {
    const { foods, totalElements, categories, copyDialog, copyLoading, copyAllDialog, importModal, isLoadingImport, selectedFile, updateModal } = this.props.foodData;
    const { companiesForUser } = this.props.companyData
    const { energyModal, food, carbohydrateModal, mineralModal, fatModal, vitaminModal, proteinModal, deleteFoodModal, foodToDelete, isLoading, sheet, company, foodIds } = this.state;
    const { t } = this.props;
    const { credentials } = this.props.auth

    const disabledImportButton = selectedFile !== undefined && selectedFile && sheet !== ''

    let categoryFilterOptions;
    categoryFilterOptions = categories.map((category, index) => {
      return <DropdownItem value={category} onClick={(e) => { this.handleChangeFilterDropdown(t(category), category) }} key={index}>{t(category)}</DropdownItem>
    })

    const deleteModal = <DraggableModal
      isOpen={deleteFoodModal}
      toggle={this.closeDeleteFoodDialog}
      size={"sm"}
      title={<strong>{t("delete_food")}</strong>}
      body={<div>
        {t("delete_message_food")}{' '}<strong>{foodToDelete.name}</strong>{' ?'}
      </div>}
      footer={
        <Button color="success" onClick={this.deleteFood}>{t("delete")}</Button>
      }
      footerStyle={{ justifyContent: 'flex-end' }}
    />

    const copyToOtherCompany = <DraggableModal
      isOpen={copyDialog}
      toggle={this.closeDialog}
      size={"sm"}
      title={<strong>{t("copy_to_another_company")}</strong>}
      body={<div>
        <FormGroup>
          <Typeahead
            selected={company === null ? [] : companiesForUser.filter(c => c.name === company.name)}
            id="company"
            clearButton
            labelKey="name"
            onChange={this.handleChangeCompany}
            options={companiesForUser}
            placeholder={t("select_company")}
            paginationText={t("display_additional")}
            emptyLabel={t("no_matches_found")}
          />
        </FormGroup>
      </div>}
      footer={
        <Button style={{ width: '82.64px', height: '40px' }} color="success" disabled={company === null} onClick={copyAllDialog ? () => this.props.copyAllFoods(company.id, this.toastManager) : () => this.copyData()}>{copyLoading ? <ClipLoader
          size={20}
          color={"#e14eca"}
          loading={copyLoading}
        /> : t("copy")}</Button>
      }
      footerStyle={{ justifyContent: 'flex-end' }}
    />

    const energyDetailsModal = <DraggableModal
      isOpen={energyModal}
      toggle={this.closeEnergyDialog}
      size={"sm"}
      title={<div>
        {t("energy_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      body={<Row>
        <Col md={6}>
          <Label>{t("kilojoules")} (kJ)</Label>
          <Input disabled name="kilojoules" value={food && food.energyDetail.kilojoules.toFixed(0)} />
        </Col>
        <Col md={6}>
          <Label>{t("kilocalories")} (kcal)</Label>
          <Input disabled name="kcal" value={food && food.energyDetail.kcal.toFixed(0)} />
        </Col>
      </Row>}
    />

    const importFileModal = <DraggableModal
      isOpen={importModal}
      toggle={this.closeFileDialog}
      size={"sm"}
      title={<strong>{t("import_excel_file")}</strong>}
      body={<Row>
        <Col md={12}>
          <Input type="file" id="file" onChange={this.handleFileUpload} accept=".xlsx, .xls"></Input><br />
          <Row>
            <Col md={3}>
              <Label>{t("sheet")}</Label>
            </Col>
            <Col md={5}>
              <Input name="sheet" type="test" onChange={this.handleChangeSheet} value={this.state.sheet} autoComplete="off" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button style={{ width: '131px', height: '40px' }} className="button" disabled={!disabledImportButton || isLoadingImport} color="success" type="button" onClick={this.handleFileSubmit}>
                {isLoadingImport ? <ClipLoader
                  size={20}
                  color={"#e14eca"}
                  loading={isLoadingImport}
                /> : t("upload")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>}
    />

    const updateFileModal = <DraggableModal
      isOpen={updateModal}
      toggle={this.closeUpdateFileDialog}
      size={"sm"}
      title={<strong>{t("import_excel_file")}</strong>}
      body={<Row>
        <Col md={12}>
          <Input type="file" id="file" onChange={this.handleFileUpdateUpload} accept=".xlsx, .xls"></Input><br />
          <Row>
            <Col md={3}>
              <Label>{t("sheet")}</Label>
            </Col>
            <Col md={5}>
              <Input name="sheet" type="test" onChange={this.handleChangeSheet} value={this.state.sheet} autoComplete="off" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button style={{ width: '131px', height: '40px' }} className="button" disabled={!disabledImportButton || isLoadingImport} color="success" type="button" onClick={this.handleUpdateFileSubmit}>
                {isLoadingImport ? <ClipLoader
                  size={20}
                  color={"#e14eca"}
                  loading={isLoadingImport}
                /> : t("upload")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>}
    />

    const carbohydrateDetailModal = <DraggableModal
      isOpen={carbohydrateModal}
      toggle={this.closeCarbohydrateDialog}
      size={"sm"}
      title={<div>
        {t("carbohydrate_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      modalStyle={{ maxWidth: '450px' }}
      body={<div>
        <Row>
          <Col md={6}>
            <Label>{t("monosaccharide")} (g)</Label>
            <Input name="monosaccharide" disabled value={food && food.carbohydrateDetail.monosaccharide.toFixed(1)} />
          </Col>
          <Col md={6}>
            <Label>{t("polysaccharide")} (g)</Label>
            <Input name="polysaccharide" disabled value={food && food.carbohydrateDetail.polysaccharide.toFixed(1)} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Label>{t("fibre")} (g)</Label>
            <Input name="fibre" disabled value={food && food.carbohydrateDetail.fibre.toFixed(1)} />
          </Col>
          <Col md={6}>
            <Label>{t("total_carbohydrate")} (g)</Label>
            <Input name="totalCarbohydrate" disabled value={food && food.carbohydrateDetail.totalCarbohydrate.toFixed(1)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>{t("lactose")} (g)</Label>
            <Input name="lactose" disabled value={food && food.carbohydrateDetail.lactose.toFixed(1)} />
          </Col>
        </Row>
      </div>}
    />

    const mineralDetailsModal = <DraggableModal
      isOpen={mineralModal}
      toggle={this.closeMineralDialog}
      size={"sm"}
      title={<div>
        {t("mineral_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      modalStyle={{ maxWidth: '620px' }}
      body={<div><Row>
        <Col md={4}>
          <Label>Na (mg)</Label>
          <Input name="na" disabled value={food && food.mineralDetail.na.toFixed(0)} />
        </Col>
        <Col md={4}>
          <Label>K (mg)</Label>
          <Input name="k" disabled value={food && food.mineralDetail.k.toFixed(0)} />
        </Col>
        <Col md={4}>
          <Label>Ca (mg)</Label>
          <Input name="ca" disabled value={food && food.mineralDetail.ca.toFixed(0)} />
        </Col>
      </Row>
        <Row>
          <Col md={4}>
            <Label>Mg (mg)</Label>
            <Input name="mg" disabled value={food && food.mineralDetail.mg.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>P (mg)</Label>
            <Input name="p" disabled value={food && food.mineralDetail.p.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>Fe (mg)</Label>
            <Input name="fe" disabled value={food && food.mineralDetail.fe.toFixed(1)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>Zn (mg)</Label>
            <Input name="zn" disabled value={food && food.mineralDetail.zn.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>Cu (mg)</Label>
            <Input name="cu" disabled value={food && food.mineralDetail.cu.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>Cl (mg)</Label>
            <Input name="cl" disabled value={food && food.mineralDetail.cl.toFixed(2)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>Mn (μg)</Label>
            <Input name="mn" disabled value={food && food.mineralDetail.mn.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>Cr (μg)</Label>
            <Input name="cr" disabled value={food && food.mineralDetail.cr.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>Mo (μg)</Label>
            <Input name="mo" disabled value={food && food.mineralDetail.mo.toFixed(2)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>F (mg)</Label>
            <Input name="f" disabled value={food && food.mineralDetail.f.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("SO42_sulphates")}(mg)</Label>
            <Input name="sulphates" disabled value={food && food.mineralDetail.sulphates.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("HC03_carbonates")}(mg)</Label>
            <Input name="carbonates" disabled value={food && food.mineralDetail.carbonates.toFixed(2)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>Se (mg)</Label>
            <Input name="se" disabled value={food && food.mineralDetail.se.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>I (mg)</Label>
            <Input name="i" disabled value={food && food.mineralDetail.i.toFixed(2)} />
          </Col>
        </Row></div>}
    />

    const fatDetailsModal = <DraggableModal
      isOpen={fatModal}
      toggle={this.closeFatDialog}
      size={"sm"}
      title={<div>
        {t("fat_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      modalStyle={{ maxWidth: '650px' }}
      body={<div><Row>
        <Col md={4}>
          <Label>{t("saturated")} (g)</Label>
          <Input name="saturated" disabled value={food && food.fatDetail.saturated.toFixed(1)} />
        </Col>
        <Col md={4}>
          <Label>{t("monounsaturated")} (g)</Label>
          <Input name="monounsaturated" disabled value={food && food.fatDetail.monounsaturated.toFixed(1)} />
        </Col>
        <Col md={4}>
          <Label>{t("polyunsaturated")} (g)</Label>
          <Input name="polyunsaturated" disabled value={food && food.fatDetail.polyunsaturated.toFixed(1)} />
        </Col>
      </Row>
        <Row>
          <Col md={4}>
            <Label>{t("linoleic_acid")} (g)</Label>
            <Input name="linoleicAcid" disabled value={food && food.fatDetail.linoleicAcid.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>{t("cholesterol")} (g)</Label>
            <Input name="cholesterol" disabled value={food && food.fatDetail.cholesterol.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>{t("total_fat")} (g)</Label>
            <Input name="totalFat" disabled value={food && food.fatDetail.totalFat.toFixed(1)} />
          </Col>
        </Row>

      </div>}
    />

    const proteinDetailsModal = <DraggableModal
      isOpen={proteinModal}
      toggle={this.closeProteinDialog}
      size={"sm"}
      title={<div>
        {t("protein_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      modalStyle={{ maxWidth: '550px' }}
      body={<div><Row>
        <Col md={4}>
          <Label>{t("plant")} (g)</Label>
          <Input name="plant" disabled value={food && food.proteinDetail.plant.toFixed(1)} />
        </Col>
        <Col md={4}>
          <Label>{t("animal")} (g)</Label>
          <Input name="animal" disabled value={food && food.proteinDetail.animal.toFixed(1)} />
        </Col>
        <Col md={4}>
          <Label>{t("phenylalanine")} (g)</Label>
          <Input name="phenylalanine" disabled value={food && food.proteinDetail.phenylalanine.toFixed(1)} />
        </Col>
      </Row>
        <Row>
          <Col md={4}>
            <Label>{t("purine")} (g)</Label>
            <Input name="purine" disabled value={food && food.proteinDetail.purine.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("histamine")} (g)</Label>
            <Input name="histamine" disabled value={food && food.proteinDetail.histamine.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("total_protein")} (g)</Label>
            <Input name="totalProtein" disabled value={food && food.proteinDetail.totalProtein.toFixed(2)} />
          </Col>
        </Row></div>}
    />

    const vitaminDetailsModal = <DraggableModal
      isOpen={vitaminModal}
      toggle={this.closeVitaminDialog}
      size={"sm"}
      title={<div>
        {t("vitamin_detail")}{' '}{t("for")}{': '}<strong>{food && food.name}</strong>
      </div>}
      modalStyle={{ maxWidth: '800px' }}
      body={<div>
        <Row>
          <Col md={4}>
            <Label>A (μg)</Label>
            <Input name="a" disabled value={food && food.vitaminDetail.a.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>D (μg)</Label>
            <Input name="d" disabled value={food && food.vitaminDetail.d.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>D3 (μg)</Label>
            <Input name="d3" disabled value={food && food.vitaminDetail.d3.toFixed(2)} />
          </Col>

        </Row>
        <Row>
          <Col md={4}>
            <Label>E (mg)</Label>
            <Input name="e" disabled value={food && food.vitaminDetail.e.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("b1_thiamine")}(mg)</Label>
            <Input name="b1" disabled value={food && food.vitaminDetail.b1.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("b2_riboflavin")}(mg)</Label>
            <Input name="b2" disabled value={food && food.vitaminDetail.b2.toFixed(2)} />
          </Col>

        </Row>
        <Row><Col md={4}>
          <Label>{t("b3_niacin")}  (mg)</Label>
          <Input name="b3" disabled value={food && food.vitaminDetail.b3.toFixed(1)} />
        </Col>
          <Col md={4}>
            <Label>{t("b5_pantothenic_acid")} (mg)</Label>
            <Input name="b5" disabled value={food && food.vitaminDetail.b5.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>B6 (mg)</Label>
            <Input name="b6" disabled value={food && food.vitaminDetail.b6.toFixed(2)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>B7 (mg)</Label>
            <Input name="b7" disabled value={food && food.vitaminDetail.b7.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>{t("b9_folic_acid")} (mg)</Label>
            <Input name="b9" disabled value={food && food.vitaminDetail.b9.toFixed(2)} />
          </Col>
          <Col md={4}>
            <Label>B12 (μg)</Label>
            <Input name="b12" disabled value={food && food.vitaminDetail.b12.toFixed(2)} />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Label>C (mg)</Label>
            <Input name="c" disabled value={food && food.vitaminDetail.c.toFixed(0)} />
          </Col>
          <Col md={4}>
            <Label>K (mg)</Label>
            <Input name="k" disabled value={food && food.vitaminDetail.k.toFixed(2)} />
          </Col>
        </Row></div>}
    />

    const foodColumns = [
      {
        name: t("name"),
        selector: "name",
        sortable: true,
        width: hasRole('ROLE_SUPER_ADMIN') ? "25%" : "26%",
        cell: row =>
          <span>
            {row.name}
          </span>
      },
      {
        name: t("food_category"),
        width: hasRole('ROLE_SUPER_ADMIN') ? "10%" : "12%",
        cell: row =>
          <span>
            {t(row.category)}
          </span>
      },
      {
        name: t("energy_detail") + " (kcal)",
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <span className={'info-link'} style={{ marginRight: '5px' }} onClick={() => this.openDialogEnergy(row)}>{(row.energyDetail.kcal).toFixed(0)}</span>
        </span>
      },
      {
        name: t("carbohydrate_detail") + " (g)",
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <span className={'info-link'} style={{ marginRight: '5px' }} onClick={() => this.openDialogCarbohydrate(row)}>{(row.carbohydrateDetail.totalCarbohydrate).toFixed(1)}</span>{" "}
        </span>
      },
      {
        name: t("fat_detail") + " (g)",
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <span className={'info-link'} style={{ marginRight: '5px' }} onClick={() => this.openDialogFat(row)}>{(row.fatDetail.totalFat).toFixed(1)}</span>{" "}
        </span>
      },

      {
        name: t("protein_detail") + " (g)",
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <span className={'info-link'} style={{ marginRight: '5px' }} onClick={() => this.openDialogProtein(row)}>{(row.proteinDetail.totalProtein).toFixed(1)}</span>{" "}
        </span>
      },
      {
        name: t("vitamin_detail"),
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <Button className="btn-icon btn-round" color="warning" size="sm" onClick={() => this.openDialogVitamin(row)} ><i className="fas fa-info"></i></Button>
        </span>
      },
      {
        name: t("mineral_detail"),
        width: hasRole('ROLE_SUPER_ADMIN') ? "7%" : "9%",
        center: true,
        cell: row => <span>
          <Button className="btn-icon btn-round" color="warning" size="sm" onClick={() => this.openDialogMineral(row)} ><i className="fas fa-info"></i></Button>
        </span>
      }
    ]

    const createdBy = {
      name: t("created_by"),
      selector: "createdBy",
      sortable: true,
      width: "10%",
      cell: row => <span>
        {row.createdBy ? `${row.createdBy.firstName} ${row.createdBy.lastName !== null ? row.createdBy.lastName : ""}` : ''}
      </span>
    }

    const actions = {
      name: t("actions"),
      width: "8%",
      center: true,
      cell: row => <span>
        {(row.createdBy && row.createdBy.username === credentials.username) || hasRole("ROLE_SUPER_ADMIN") ? <div>
          <Button className="btn-icon btn-round" color="success" size="sm" onClick={() => this.props.history.push("/admin/food/" + row.id)}><i className="tim-icons icon-pencil"></i></Button>
          <Button className="btn-icon btn-round" color="danger" size="sm" onClick={() => this.openDialogDelete(row)}><i className="tim-icons icon-simple-remove"></i></Button></div> : null}
      </span>
    }

    const copy = {
      name: t("copy"),
      width: '5%',
      cell: row =>
        <FormGroup check>
          <Label check>
            <Input type="checkbox" name="select" id="select" onChange={() => this.handleChange(row.id)} style={{ position: 'relative' }} checked={foodIds.some(item => item === row.id)} />
            <span className="form-check-sign">
              <span className="check" />
            </span>
          </Label>
        </FormGroup>
    }

    if (hasRole("ROLE_SUPER_ADMIN")) {
      foodColumns.push(createdBy)
      foodColumns.push(actions)
      foodColumns.push(copy)
    } else {
      foodColumns.push(actions)
    }

    const tableData = {
      columns: foodColumns,
      data: foods,
      filterHidden: false,
      filter: false,
      export: hasRole("ROLE_SUPER_ADMIN") ? true : false,
      exportHeaders: hasRole("ROLE_SUPER_ADMIN") ? true : false,
      print: hasRole("ROLE_SUPER_ADMIN") ? true : false
    };

    return (
      <div className="content content-food">
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader className={'control-header'}>
                <Row>
                  <Col xs={4} sm={4}>
                    <UncontrolledDropdown isOpen={this.state.filterDropdown} toggle={this.toggleFilterDropdown}>
                      <DropdownToggle caret data-toggle="dropdown" color="success" style={{ marginLeft: '10px' }}>
                        {this.state.selectedCategory}
                      </DropdownToggle>
                      <DropdownMenu style={{ maxHeight: "450px", overflowY: 'scroll' }}>
                        <DropdownItem id={t("all_status")} name={t("all_status")} onClick={() => this.handleChangeFilterDropdown(t("all_status"), "ALL")} value={t("all_status")}>{t("all_status")}</DropdownItem>
                        {categoryFilterOptions}
                      </DropdownMenu>
                    </UncontrolledDropdown >
                  </Col>
                  <Col xs={7} sm={3}>
                    <Input style={{ cursor: 'pointer' }} type="text" placeholder={t("search_foods")} autoComplete="off" name="foodSearch" id="foodSearch" value={this.state.foodSearch || ''} onChange={this.handleFoodSearch}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.getFoodSearch()
                        }
                      }} />
                  </Col>
                  <Col xs={1} sm={1}>
                    <Button color="success" size="sm" type="button" onClick={() => this.getFoodSearch()}><i className="fas fa-search"></i></Button>
                  </Col>
                  <Col sm={4} style={{ textAlign: 'right' }}>
                    {(hasRole("ROLE_SUPER_ADMIN") || hasRole("ROLE_ADMIN")) && <Button tag={Link} to="/admin/food" color="success">{t("create_food")}</Button>}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <div style={{ textAlign: 'center' }}>
                    <ClipLoader
                      size={60}
                      color={"#e14eca"}
                      loading={isLoading}
                    />
                  </div>
                ) : (
                  <DataTableExtensions {...tableData}>
                    <DataTable
                      columns={foodColumns}
                      data={foods}
                      noHeader={true}
                      highlightOnHover
                      pagination
                      paginationServer
                      paginationRowsPerPageOptions={[5, 10, 15, 20]}
                      paginationPerPage={this.props.foodData.size}
                      paginationDefaultPage={this.props.foodData.page + 1}
                      paginationTotalRows={totalElements}
                      paginationComponentOptions={{ rowsPerPageText: t("rows_per_page") }}
                      noDataComponent={t("no_records")}
                      onChangePage={(page) => this.onChangePage(page)}
                      onChangeRowsPerPage={(currentRowsPerPage, currentPage) => this.onChangeRowsPerPage(currentRowsPerPage, currentPage)}
                    />
                  </DataTableExtensions>
                )
                }
              </CardBody>
              {deleteModal}
              {importFileModal}
              {energyDetailsModal}
              {carbohydrateDetailModal}
              {mineralDetailsModal}
              {fatDetailsModal}
              {proteinDetailsModal}
              {vitaminDetailsModal}
              {updateFileModal}
              {copyToOtherCompany}
            </Card>
            {hasRole("ROLE_SUPER_ADMIN") && <div>
              <Button color="warning" size="sm" type="button" onClick={() => this.openDialogImport()}><i className="tim-icons icon-cloud-upload-94"></i> {t("import_excel_file")}</Button>
              <Button color="warning" size="sm" type="button" onClick={() => this.openDialogUpdatedImport()}><i className="tim-icons icon-cloud-upload-94"></i> {t("update_import")}</Button>
              <Button color="success" size="sm" type="button" disabled={foodIds.length === 0} onClick={() => this.copyDialog()}><i className="far fa-copy"></i> {t("copy_selected_to_another_company")}</Button>
              <Button color="success" size="sm" type="button" disabled={foods.length === 0} onClick={() => this.copyDialog(copyAllDialog)}><i className="far fa-copy"></i> {t("copy_all_to_another_company")}</Button>
            </div>}
          </Col>
        </Row>
      </div>
    )
  }
}

FoodList.propTypes = {
  foodData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  foodData: state.foodData,
  companyData: state.companyData,
  auth: state.auth
})

const mapActionsToProps = { getFoods, getFoodsByCategory, deleteFood, searchFood, getCategories, searchFoodsByCategory, getCompaniesForUser, copySelectedFoods, copyAllFoods, handleFileSubmit, handleUpdateFileSubmit }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(FoodList)));