import {
    SET_DISHES,
    SET_DISH_TYPES,
    SET_PREPARATION_TYPES,
    SET_DISH_FOODS,
    SET_MACRONUTRIENTS,
    SET_MICRONUTRIENTS,
    CREATE_DISH,
    DELETE_DISH,
    SET_MINERALS,
    SET_COPY_DISH_LOADING,
    SET_IMPORT_LOADING
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createDish = (toastManager, newDish, history) => (dispatch) => {
    axios.post('/v1/dish', newDish)
        .then(response => {
            dispatch({
                type: CREATE_DISH,
                payload: response.data.dish
            });
            history.push('/admin/dishList')
            toastManager.add(i18next.t("dish_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateDish = (toastManager, id, updatedDish, history) => (dispatch) => {
    axios.patch(`/v1/dish/${id}`, updatedDish)
        .then(response => {
            history.push('/admin/dishList')
            toastManager.add(i18next.t("dish_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            } else {
                toastManager.add(i18next.t("unable_perform_action"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
        })
}

export const updateDishApproved = (id, approved, toastManager) => () => {
    axios.put(`/v1/dish/${id}?approved=${approved}`)
        .then(response => {
            toastManager.add(i18next.t("dish_approved_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getDishes = (page, size) => (dispatch) => {
    axios.get(`/v1/dish?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_DISHES,
                payload: response.data.dishes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DISHES,
                payload: []
            })
        })
}

export const getDishesByType = (page, size, type) => (dispatch) => {
    axios.get(`/v1/dish/type?type=${type}&page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_DISHES,
                payload: response.data.dishes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DISHES,
                payload: []
            })
        })
}

export const deleteDish = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/dish/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_DISH,
                payload: id
            })
            toastManager.add(i18next.t("dish_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getDishes(0, 10))
        })
        .catch(error => {
            if (error.response.data.code === 400) {
                toastManager.add(i18next.t("error_detele_dish"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 5000
                })
            }
        })
}

export const getDishTypes = () => (dispatch) => {
    axios.get(`/v1/dish/types`)
        .then(response => {
            dispatch({
                type: SET_DISH_TYPES,
                payload: response.data.types
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DISH_TYPES,
                payload: []
            })
        })
}

export const getPreparationTypes = () => (dispatch) => {
    axios.get(`/v1/dish/preparationTypes`)
        .then(response => {
            dispatch({
                type: SET_PREPARATION_TYPES,
                payload: response.data.preparationTypes
            })
        })
        .catch(error => {
            dispatch({
                type: SET_PREPARATION_TYPES,
                payload: []
            })
        })
}

export const searchDish = (page, size, search) => (dispatch) => {
    axios.get(`/v1/dish/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_DISHES,
                payload: response.data.dishes
            });
        })
        .catch(error => {
            dispatch({
                type: SET_DISHES,
                payload: []
            })
        })
}

export const searchDishesByType = (page, size, search, type) => (dispatch) => {
    axios.get(`/v1/dish/searchByType?page=${page}&size=${size}&search=${search}&type=${type}`)
        .then(response => {
            dispatch({
                type: SET_DISHES,
                payload: response.data.dishes
            });
        })
        .catch(error => {
            dispatch({
                type: SET_DISHES,
                payload: []
            })
        })
}

export const getFoods = () => (dispatch) => {
    axios.get(`/v1/food/forDish`)
        .then(response => {
            dispatch({
                type: SET_DISH_FOODS,
                payload: response.data.foods
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DISH_FOODS,
                payload: []
            })
        })
}

export const getMicronutrients = (request) => (dispatch) => {
    axios.post(`/v1/dish/vitamins`, request)
        .then(response => {
            dispatch({
                type: SET_MICRONUTRIENTS,
                payload: response.data.vitamins
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MICRONUTRIENTS,
                payload: []
            })
        })
}

export const getMinerals = (request) => (dispatch) => {
    axios.post(`/v1/dish/minerals`, request)
        .then(response => {
            dispatch({
                type: SET_MINERALS,
                payload: response.data.minerals
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MINERALS,
                payload: []
            })
        })
}

export const importData = (toastManager, data) => (dispatch) => {
    axios.post('/v1/dish/import', data)
        .then(response => {
            toastManager.add(i18next.t("dish_imported_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_IMPORT_LOADING,
                payload: {
                    isLoadingImport: false,
                    importModal: false,
                    selectedFiles: []
                }
            })
            dispatch(getDishes(0, 10))
        })
        .catch(error => {
            toastManager.add(i18next.t("error_dish_import"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getMacronutrients = (dishData) => (dispatch) => {
    axios.post(`/v1/dish/macronutrients`, dishData)
        .then(response => {
            const nutrientSum = response.data.macronutrients.carbohydratesKcal + response.data.macronutrients.fatKcal + response.data.macronutrients.proteinKcal;
            const macronutrientData = {
                macronutrientTableData: { carbohydrates: response.data.macronutrients.carbohydrates, fat: response.data.macronutrients.fat, protein: response.data.macronutrients.protein, energy: response.data.macronutrients.energy, fibre: response.data.macronutrients.fibre },
                macronutrientRatio: { carbohydrateRatio: response.data.macronutrients.carbohydrateRatio, fatRatio: response.data.macronutrients.fatRatio, proteinRatio: response.data.macronutrients.proteinRatio },
                macronutrientChartData: {
                    labels: [
                        i18next.t('carbohydrate'),
                        i18next.t('fat'),
                        i18next.t('protein')
                    ],
                    datasets: [{
                        data: [
                            response.data.macronutrients.carbohydratesKcal,
                            response.data.macronutrients.fatKcal,
                            response.data.macronutrients.proteinKcal,
                        ],
                        backgroundColor: [
                            '#FCF955',
                            '#F76A6E',
                            '#68A2EB',
                        ],
                        hoverBackgroundColor: [
                            '#FCF955',
                            '#F76A6E',
                            '#68A2EB',
                        ]
                    }]
                },
                macronutrientBarChart: {

                    labels: [
                        i18next.t('ratio')
                    ],
                    datasets: [{
                        label: i18next.t("monosaccharide") + " / " + i18next.t("polysaccharide"),
                        backgroundColor: '#FCF955',
                        borderColor: ['#FCF955'],
                        data: [response.data.macronutrients.carbohydrateRatio]
                    }, {
                        label: i18next.t("saturated") + " / " + i18next.t("unsaturated") + " " + i18next.t("fatty_acid"),
                        backgroundColor: '#F76A6E',
                        borderColor: ['#F76A6E'],
                        data: [response.data.macronutrients.fatRatio]
                    }, {
                        label: i18next.t("animal") + " / " + i18next.t("plant") + " " + i18next.t("proteins"),
                        backgroundColor: '#68A2EB',
                        borderColor: ['#68A2EB'],
                        data: [response.data.macronutrients.proteinRatio]
                    }],
                    options: {
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                fontColor: "green",
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                },
                macronutrientBarChartOptions: {
                    options: {
                        legend: {
                            display: true,
                            position: 'top',
                            labels: {
                                fontColor: "green",
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                },
                macronutrientChartOptions: {
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function (tooltipItem, data) {
                                var dataset = data['datasets'][0];
                                var percent = ((dataset['data'][tooltipItem['index']] / nutrientSum) * 100).toFixed(1);

                                return `${i18next.t("value")}: ${data['datasets'][0]['data'][tooltipItem['index']]} kcal (${percent}%)`;
                            }
                        },
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        titleFontSize: 16,
                        titleFontColor: '#fff',
                        bodyFontColor: '#eaeaea',
                        bodyFontSize: 14,
                        displayColors: false
                    }
                }
            }
            dispatch({
                type: SET_MACRONUTRIENTS,
                payload: macronutrientData
            })
        })
        .catch(error => {
            dispatch({
                type: SET_MACRONUTRIENTS,
                payload: {
                    macronutrientChartData: [],
                    macronutrientChartOptions: null,
                    macronutrientTableData: null
                }
            })
        })
}

export const copySelectedDishes = (requestData, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_DISH_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post('/v1/dish/copy', requestData)
        .then(response => {
            toastManager.add(i18next.t("dishes_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_DISH_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("dishes_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_DISH_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}

export const copyAllDishes = (companyId, toastManager) => (dispatch) => {
    dispatch({
        type: SET_COPY_DISH_LOADING,
        payload: {
            copyLoading: true,
            copyDialog: true
        }
    })
    axios.post(`/v1/dish/copyAll?companyId=${companyId}`)
        .then(response => {
            toastManager.add(i18next.t("dishes_copied_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch({
                type: SET_COPY_DISH_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: false
                }
            })
        })
        .catch(error => {
            if (error.response.data.code === 1000) {
                toastManager.add(i18next.t("already_exists"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            else {
                toastManager.add(i18next.t("dishes_copied_error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            }
            dispatch({
                type: SET_COPY_DISH_LOADING,
                payload: {
                    copyLoading: false,
                    copyDialog: true
                }
            })
        })
}
