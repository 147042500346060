import { SET_AUDIT_LOGS, SET_AUDIT_LOG_DETAILS } from '../types';
import axios from 'axios';

export const getAuditLogs = (page, size) => (dispatch) => {
    axios.get(`/v1/audit?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_AUDIT_LOGS,
                payload: response.data
            })
        })
        .catch(error => {
            dispatch({
                type: SET_AUDIT_LOGS,
                payload: []
            })
        })
}

export const getAuditLogDetails = (id) => (dispatch) => {
    return axios.get(`/v1/audit/${id}`)
        .then(response => {
            dispatch({
                type: SET_AUDIT_LOG_DETAILS,
                payload: response.data.descriptionObject
            })
        })
        .catch(error => {
            dispatch({
                type: SET_AUDIT_LOG_DETAILS,
                payload: null
            })
        })
}