import { SET_DIAGNOSES, CREATE_DIAGNOSIS, DELETE_DIAGNOSIS } from '../types';

const initialState = {
    diagnoses: [],
    size: 10,
    totalElements: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DIAGNOSES:
            return {
                ...state,
                diagnoses: action.payload.content,
                size: action.payload.size,
                totalElements: action.payload.totalElements
            }
        case CREATE_DIAGNOSIS:
            return {
                ...state,
                diagnoses: [
                    action.payload,
                    ...state.diagnoses
                ]
            }
        case DELETE_DIAGNOSIS:
            let index = state.diagnoses.findIndex((diagnosis) => diagnosis.id === action.payload)
            state.diagnoses.splice(index, 1);
            return {
                ...state
            }
        default:
            return state;
    }
}
