import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, FormGroup, Row, Col, Label, CardHeader, CardBody, CardTitle } from "reactstrap";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

class MineralCard extends Component {

    handleChange = (e, objectName) => {
        this.props.handleChange(e, objectName);
    }

    render() {

        const { mineralDetail, t } = this.props;

        return (
            <Card className={'even-height-card'}>
                <CardHeader>
                    <CardTitle tag="h3"><strong>{t("mineral")}</strong></CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Na (mg)</Label>
                                <NumberFormat className="form-control" name="na" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.na} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>K (mg)</Label>
                                <NumberFormat className="form-control" name="k" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.k} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Ca (mg)</Label>
                                <NumberFormat className="form-control" name="ca" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.ca} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Mg (mg)</Label>
                                <NumberFormat className="form-control" name="mg" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.mg} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>P (mg)</Label>
                                <NumberFormat className="form-control" name="p" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.p} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={0} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Fe (mg)</Label>
                                <NumberFormat className="form-control" name="fe" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.fe} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={1} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Zn (mg)</Label>
                                <NumberFormat className="form-control" name="zn" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.zn} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Cu (mg)</Label>
                                <NumberFormat className="form-control" name="cu" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.cu} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} />
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Cl (mg)</Label>
                                <NumberFormat className="form-control" name="cl" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.cl} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Mn (μg)</Label>
                                <NumberFormat className="form-control" name="mn" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.mn} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Cr (μg)</Label>
                                <NumberFormat className="form-control" name="cr" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.cr} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Mo (μg)</Label>
                                <NumberFormat className="form-control" name="mo" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.mo} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>F (mg)</Label>
                                <NumberFormat className="form-control" name="f" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.f} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("SO42_sulphates")}(mg)</Label>
                                <NumberFormat className="form-control" name="sulphates" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.sulphates} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>{t("HC03_carbonates")} (mg)</Label>
                                <NumberFormat className="form-control" name="carbonates" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.carbonates} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>Se (mg)</Label>
                                <NumberFormat className="form-control" name="se" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.se} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                        <Col md={6} className={'col-xxl-4'}>
                            <FormGroup className={'mw-180'}>
                                <Label>I (mg)</Label>
                                <NumberFormat className="form-control" name="i" onChange={(e) => this.handleChange(e, "mineralDetail")} value={mineralDetail.i} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

MineralCard.propTypes = {
    mineralDetail: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired
}

export default withTranslation()(MineralCard)
