import React, { Component } from 'react';
import { BrowserRouter as Switch } from 'react-router-dom';
// Redux
import { getLoggedUser } from './redux/actions/authActions';
import { connect } from 'react-redux';
// Layouts
import AuthRoute from './util/AuthRoute';
import AdminLayout from "layouts/admin/Admin.js";
import LoginForm from "layouts/LoginForm.js";
import LoginFormSuperAdmin from "layouts/LoginFormSuperAdmin.js"

class App extends Component {

  componentDidMount() {
    this.props.getLoggedUser()
  }

  render() {
    return (

      <Switch>
        {this.props.auth && this.props.auth.authenticated ?
          <React.Fragment>
            <AuthRoute path="/admin" protectedRoute={true} component={props => <AdminLayout {...props} />} />
          </React.Fragment>
          : <React.Fragment>
            <AuthRoute exact path="/login" protectedRoute={false} component={LoginFormSuperAdmin} />
            <AuthRoute exact path="/" protectedRoute={false} component={LoginForm} />
          </React.Fragment>
        }
      </Switch>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { getLoggedUser })(App);