import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from 'axios';
import { Input, Card, CardHeader, CardBody, CardFooter, FormGroup, Row, Col, Button, Label, Badge, Table, UncontrolledTooltip, Alert } from "reactstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import DatePicker from 'react-datepicker';
import ClipLoader from "react-spinners/ClipLoader";
import { withToastManager } from 'react-toast-notifications';
import DraggableModal from '../../components/modal/DraggableModal'
import { withTranslation } from 'react-i18next';
import { Bar, Pie } from 'react-chartjs-2';
// Redux
import { connect } from 'react-redux';
import { getMeals, createMenu, createEntry, updateEntry, getMenuByDiet, getDishes, getDishNormatives, getMealNormatives, getClientsByDiet, getMenuByClient, getClientsByDietForCopyPerDay, getNutrientsOutOfBounds, updateFFQFactor } from '../../redux/actions/menuEditorActions';
import { getAllergensIssue } from '../../redux/actions/allergenActions'
import { getFodmapsIssue } from '../../redux/actions/fodmapActions'
import { getDiets } from '../../redux/actions/userActions';
import { exportDailyDishData } from '../../redux/actions/reportsActions';
import store from 'redux/store'
import { SET_LATEST_DIET_NAME, SET_MODAL_ISSUE } from '../../redux/types';
// Components
import CalendarTable from '../../components/calendar/CalendarTable';
import CalendarButton from '../../components/calendar/CalendarButton';
// Style
import '../../components/calendar/CalendarTable.css';
import NumberFormat from "react-number-format";

class MenuEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            calendarEntries: [],
            addMealModalOpen: false,
            copyWeekModalOpen: false,
            startDay: moment().startOf('isoweek'),
            endDay: moment().endOf('isoweek'),
            selectedDateForPicker: moment(),
            selectedDateForCopy: moment(),
            selectedDateForCopyPerDay: moment(),
            startDayForCopy: moment().startOf('isoweek'),
            endDayForCopy: moment().endOf('isoweek'),
            selectedDate: null,
            selectedMenuId: null,
            selectedEntryId: null,
            selectedEntryType: '',
            selectedEntryMeals: [],
            selectedEntryDishes: [],
            isLoading: false,
            selectedDiet: '',
            selectedDietForCopy: '',
            copyPerDayModalOpen: false,
            menuStartDate: null,
            openDialog: false,
            openDialogDelete: false,
            menuDeleteId: null,
            infoModalDish: false,
            infoModalMeal: false,
            selectedDishId: null,
            selectedMealId: null,
            menuDate: null,
            selectedClient: [],
            selectedClientForCopyPerDay: [],
            macronutrientData: {
                macronutrientChartData: {},
                macronutrientBarChart: {}
            },
            client: [],
            ffqFactor: 1,
            selectedCalendarEntryId: null,
            modalAllergen: false
        }
        this.toastManager = props.toastManager;
    }

    async componentDidMount() {
        this.props.menuEditorData.menuByDiet = []
        if (this.props.menuEditorData.diet !== '') {
            if (this.props.menuEditorData.client !== []) {
                if (this.props.menuEditorData.client.length === 0) {
                    this.state.selectedDiet = this.props.menuEditorData.diet
                    this.state.selectedDate = this.props.menuEditorData.date
                    this.getMenuByDiet()
                }
                else if (this.props.menuEditorData.client !== [] && this.props.auth.usingClientSpecificMenus) {
                    this.state.selectedDiet = this.props.menuEditorData.diet
                    this.state.selectedDate = this.props.menuEditorData.date
                    this.state.selectedClient = this.props.menuEditorData.client
                    this.getMenuByClient()
                } else {
                    this.state.selectedDiet = this.props.menuEditorData.diet
                    this.state.selectedDate = this.props.menuEditorData.date
                    this.getMenuByDiet()
                }
            }
        }
        this.props.getMeals()
        this.props.getDiets()
        this.props.getDishes()
    }

    handleChangeMeal = (meals) => {
        this.setState({ selectedEntryMeals: meals })
    }

    handleChangeDishes = (dishes) => {
        this.setState({ selectedEntryDishes: dishes })
    }

    handleChangeDate = (date) => {
        if (date !== null) {
            this.setState({
                selectedDateForPicker: date,
                startDay: date.clone().startOf('isoweek'),
                endDay: date.clone().endOf('isoweek'),
                selectedDateForCopy: date,
                startDayForCopy: date.clone().startOf('isoweek'),
                endDayForCopy: date.clone().endOf('isoweek')
            }, this.state.selectedClient.length === 0 ? () => this.getMenuByDiet() : () => this.getMenuByClient());
        }
    }

    handleChangeDateForCopy = (date) => {
        if (date !== null) {
            this.setState({
                selectedDateForCopy: date,
                startDayForCopy: date.clone().startOf('isoweek'),
                endDayForCopy: date.clone().endOf('isoweek')
            });
        }
    }

    handleChangeDateForCopyPerDay = (date) => {
        this.setState({ selectedDateForCopyPerDay: date })
    }

    handlePreviousWeek = () => {
        let startOfPrevWeek = this.state.startDay.clone().subtract(1, 'day').startOf('isoweek');
        let endOfPrevWeek = this.state.startDay.clone().subtract(1, 'day').endOf('isoweek');

        this.setState({
            startDay: startOfPrevWeek,
            endDay: endOfPrevWeek,
            startDayForCopy: startOfPrevWeek,
            endDayForCopy: endOfPrevWeek
        }, this.state.selectedClient.length === 0 ? () => this.getMenuByDiet() : () => this.getMenuByClient());
    }

    handleNextWeek = () => {
        let startOfNextWeek = this.state.endDay.clone().add(1, 'day').startOf('isoweek');
        let endOfNextWeek = this.state.endDay.clone().add(1, 'day').endOf('isoweek');

        this.setState({
            startDay: startOfNextWeek,
            endDay: endOfNextWeek,
            startDayForCopy: startOfNextWeek,
            endDayForCopy: endOfNextWeek
        }, this.state.selectedClient.length === 0 ? () => this.getMenuByDiet() : () => this.getMenuByClient());
    }

    getMenuByClient = () => {
        const timeInterval = {
            startDay: this.state.startDay.format('YYYY-MM-DD'),
            endDay: this.state.endDay.format('YYYY-MM-DD')
        }
        let data = {
            username: this.state.selectedClient.username,
            timeInterval: timeInterval
        }
        this.props.getMenuByClient(data)
    }

    getMenuByDiet = () => {
        const timeInterval = {
            startDay: this.state.startDay.format('YYYY-MM-DD'),
            endDay: this.state.endDay.format('YYYY-MM-DD')
        }
        let data = {
            diet: this.state.selectedDiet.id,
            timeInterval: timeInterval
        }
        this.props.getMenuByDiet(data)
    }

    openInfoModalDish = (menu, entryType, index) => {
        if (menu.calendarEntries.find(entry => entry.entryType === entryType) !== undefined) {
            let selectedEntryDishes = menu.calendarEntries.find(entry => entry.entryType === entryType).dishes
            let selectedDishId = selectedEntryDishes[index].id
            let selectedCalendarEntryId = menu.calendarEntries.find(entry => entry.entryType === entryType).id
            this.setState({
                selectedDishId: selectedDishId,
                selectedCalendarEntryId: selectedCalendarEntryId
            }, () => this.props.getDishNormatives(selectedDishId, selectedCalendarEntryId, this.toastManager))
        }
        this.setState({ infoModalDish: true, menuDate: menu.date })
    }

    closeInfoModalDish = () => {
        this.setState({ infoModalDish: false, selectedDishId: null })
    }

    openInfoModalMeal = (menu, entryType, index) => {
        if (menu.calendarEntries.find(entry => entry.entryType === entryType) !== undefined) {
            let selectedEntryMeals = menu.calendarEntries.find(entry => entry.entryType === entryType).meals
            let selectedMealId = selectedEntryMeals[index].id
            let selectedCalendarEntryId = menu.calendarEntries.find(entry => entry.entryType === entryType).id

            this.setState({
                selectedMealId: selectedMealId,
                selectedCalendarEntryId: selectedCalendarEntryId
            }, () => this.props.getMealNormatives(selectedMealId, selectedCalendarEntryId, this.toastManager))
        }
        this.setState({ infoModalMeal: true, menuDate: menu.date })
    }

    closeInfoModalMeal = () => {
        this.setState({ infoModalMeal: false, selectedMealId: null })
    }

    openAddMealModal = (menu, entryType, date) => {

        this.setState({ addMealModalOpen: true })

        if (menu !== undefined) {
            if (menu.calendarEntries.find(entry => entry.entryType === entryType) !== undefined) {
                this.setState({
                    selectedMenuId: menu.id,
                    selectedEntryId: menu.calendarEntries.find(entry => entry.entryType === entryType).id,
                    selectedEntryMeals: menu.calendarEntries.find(entry => entry.entryType === entryType).meals,
                    selectedEntryDishes: menu.calendarEntries.find(entry => entry.entryType === entryType).dishes
                });
            } else {
                this.setState({
                    selectedMenuId: menu.id,
                    selectedEntryId: null,
                    selectedEntryMeals: [],
                    selectedEntryDishes: []
                });
            }
        } else {
            this.setState({
                selectedMenuId: null,
                selectedEntryId: null,
                selectedEntryMeals: [],
                selectedEntryDishes: []
            });
        }
        this.setState({
            selectedEntryType: entryType,
            selectedDate: date
        });
    }

    openCopyWeekModal = () => {
        this.setState({ copyWeekModalOpen: true })
    }

    openCopyPerDayModal = (menuStartDate) => {
        this.setState({ copyPerDayModalOpen: true, menuStartDate: menuStartDate.date, selectedDateForCopyPerDay: moment(menuStartDate.date) })
    }

    closeCopyWeekModal = () => {
        this.setState({
            copyWeekModalOpen: false,
            selectedDietForCopy: '',
            selectedDateForPicker: moment(),
            selectedClientForCopyPerDay: []
        })
    }

    closeCopyPerDayModal = () => {
        this.setState({
            copyPerDayModalOpen: false, selectedDietForCopy: '', menuStartDate: null, selectedDateForCopyPerDay: moment(), selectedClientForCopyPerDay: []
        })
    }

    closeAddMealModal = () => {
        this.setState({
            addMealModalOpen: false, selectedEntryId: null, selectedEntryMeals: [], selectedEntryDishes: [], selectedEntryType: '', selectedDate: null
        })
    }

    handleChangeDiet = (diet) => {
        let selectedDiet = { ...this.state.selectedDiet }
        let { selectedClient } = this.state;
        if (diet.length > 0) {
            selectedDiet = diet[0]
            this.props.menuEditorData.diet = diet[0]
            this.props.menuEditorData.client = []
            this.props.auth.usingClientSpecificMenus && this.props.getClientsByDiet(diet[0].id)
        } else {
            selectedDiet = ''
            selectedClient = []
            this.props.menuEditorData.diet = []
            this.props.menuEditorData.menuByDiet = []
            this.setState({ selectedClient: selectedClient })
        }
        if (diet.length > 0) {
            this.setState({ selectedDiet: selectedDiet }, () => this.getMenuByDiet())
        }

        this.setState({ selectedDiet: selectedDiet })
    }

    handleChangeClient = (client) => {
        let selectedClient = { ...this.state.selectedClient }
        if (client.length > 0) {
            selectedClient = client[0]
            this.props.menuEditorData.client = client[0]
            this.setState({ selectedClient: selectedClient }, () => this.getMenuByClient())
        } else {
            selectedClient = [];
            this.props.menuEditorData.client = []
            this.props.menuEditorData.menuByDiet = []
            this.setState({ selectedClient: selectedClient }, () => this.getMenuByDiet())
        }
    }

    handleChangeClientForCopyPerDay = (client) => {
        let selectedClientForCopyPerDay = { ...this.state.selectedClientForCopyPerDay }
        if (client.length > 0) { selectedClientForCopyPerDay = client[0] }
        else { selectedClientForCopyPerDay = [] }
        this.setState({ selectedClientForCopyPerDay: selectedClientForCopyPerDay })
    }

    handleChangeDietForCopy = (diet) => {
        let selectedDietForCopy = { ...this.state.selectedDietForCopy };
        if (diet.length > 0) {
            selectedDietForCopy = diet[0];
            this.props.getClientsByDietForCopyPerDay(diet[0].id)
        } else {
            selectedDietForCopy = '';
        }
        this.setState({ selectedDietForCopy: selectedDietForCopy })
    }

    handlePrint = (menu) => {
        const macronutrientData = {
            macronutrientRatio: {
                carbohydrateRatio: menu.macronutrients && menu.macronutrients.carbohydrateRatio,
                fatRatio: menu.macronutrients && menu.macronutrients.fatRatio,
                proteinRatio: menu.macronutrients && menu.macronutrients.proteinRatio
            },
            macronutrientChartData: {
                labels: [
                    this.props.t('carbohydrate'),
                    this.props.t('fat'),
                    this.props.t('protein')
                ],
                datasets: [{
                    data: [
                        menu.macronutrients && menu.macronutrients.carbohydratesKcal,
                        menu.macronutrients && menu.macronutrients.fatKcal,
                        menu.macronutrients && menu.macronutrients.proteinKcal,
                    ],
                    backgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ],
                    hoverBackgroundColor: [
                        '#FCF955',
                        '#F76A6E',
                        '#68A2EB',
                    ]
                }]
            },
            macronutrientBarChart: {
                labels: [
                    this.props.t('ratio')
                ],
                datasets: [{
                    label: this.props.t("monosaccharide") + " / " + this.props.t("polysaccharide") + ", " + this.props.t("value") + ": " + menu.macronutrients.carbohydrateRatio,
                    backgroundColor: '#FCF955',
                    borderColor: ['#FCF955'],
                    data: [menu.macronutrients.carbohydrateRatio]
                }, {
                    label: this.props.t("saturated") + " / " + this.props.t("unsaturated") + " " + this.props.t("fatty_acid") + ", " + this.props.t("value") + ": " + menu.macronutrients.fatRatio,
                    backgroundColor: '#F76A6E',
                    borderColor: ['#F76A6E'],
                    data: [menu.macronutrients.fatRatio]
                }, {
                    label: this.props.t("animal") + " / " + this.props.t("plant") + " " + this.props.t("proteins") + ", " + this.props.t("value") + ": " + menu.macronutrients.proteinRatio,
                    backgroundColor: '#68A2EB',
                    borderColor: ['#68A2EB'],
                    data: [menu.macronutrients.proteinRatio]
                }],
                options: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            fontColor: "green",
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            }
        }
        this.setState({ macronutrientData: macronutrientData }, () => {
            setTimeout(() => { this.print(menu.id, this.state.selectedClient) }, 1000)
        })
    }

    print = (menuId, selectedClient) => {
        const canvasSave = document.getElementById('chart')
        const img64 = canvasSave.toDataURL('image/png')
        const base64Img = img64.replace("data:image/png;base64,", "")
        const canvasSaveBar = document.getElementById('bar')
        const img64Bar = canvasSaveBar.toDataURL('image/png')
        const base64ImgBar = img64Bar.replace("data:image/png;base64,", "")

        const request = {
            menuId: menuId,
            username: selectedClient === [] ? null : selectedClient.username,
            image: base64Img,
            imageBarChart: base64ImgBar
        }
        this.props.exportDailyDishData(request, this.toastManager)
    }

    openDialog = () => {
        this.setState({ openDialog: true })
    }

    closeModalDialog = () => {
        this.setState({ openDialog: false })
    }

    handleCopyWeek = () => {
        const request = {
            oldDate: this.state.startDay.format('YYYY-MM-DD'),
            newDate: this.state.startDayForCopy.format('YYYY-MM-DD'),
            oldDiet: this.state.selectedDiet.id,
            newDiet: this.state.selectedDietForCopy.id,
            newUsername: this.state.selectedClientForCopyPerDay === [] ? null : this.state.selectedClientForCopyPerDay.username,
            oldUsername: this.state.selectedClient.username
        }
        axios.post(`/v1/menu/copyWeek`, request)
            .then(response => {
                this.toastManager.add(this.props.t("copy_week_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                })
                this.closeModalDialog()
                this.closeCopyWeekModal()
            })
            .catch(error => {
                this.toastManager.add(this.props.t("copy_week_fail"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            })
    }

    handleCopyPerDay = () => {
        const request = {
            oldDate: this.state.menuStartDate,
            newDate: this.state.selectedDateForCopyPerDay.format('YYYY-MM-DD'),
            oldDiet: this.state.selectedDiet.id,
            newDiet: this.state.selectedDietForCopy.id,
            newUsername: this.state.selectedClientForCopyPerDay === [] ? null : this.state.selectedClientForCopyPerDay.username,
            oldUsername: this.state.selectedClient.username
        }

        axios.post(`/v1/menu/copy`, request)
            .then(response => {
                this.toastManager.add(this.props.t("copy_day_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                })
                this.closeCopyPerDayModal()
                this.state.selectedClient.length === 0 ? this.getMenuByDiet() : this.getMenuByClient()
            })
            .catch(error => {
                this.toastManager.add(this.props.t("copy_day_fail"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            })
    }

    openModalIssue = () => {
        store.dispatch({
            type: SET_MODAL_ISSUE,
            payload: true
        })
    }

    closeModalIssue = () => {
        store.dispatch({
            type: SET_MODAL_ISSUE,
            payload: false
        })
    }

    menuDetails = (menu) => {
        this.props.getNutrientsOutOfBounds(this.toastManager, menu.id)
        this.openModalIssue()
    }

    getAllergensIssue = (menu) => {
        this.props.getAllergensIssue(menu.id, this.toastManager)
        this.props.getFodmapsIssue(menu.id, this.toastManager)
        this.toggleModalAllergen()
    }

    toggleModalAllergen = () => {
        this.setState({ modalAllergen: !this.state.modalAllergen })
    }

    async handleSubmit() {
        const { selectedMenuId, selectedEntryId, selectedEntryMeals, selectedEntryType, selectedDate, selectedDiet, selectedEntryDishes, selectedClient } = this.state;
        if (selectedMenuId === null) {
            const createMenuRequest = {
                username: selectedClient === [] ? null : selectedClient.username,
                date: selectedDate.format('YYYY-MM-DD'),
                diet: selectedDiet.id,
                calendarEntry: {
                    meals: selectedEntryMeals,
                    dishes: selectedEntryDishes,
                    entryType: selectedEntryType
                }
            }
            this.props.createMenu(createMenuRequest, selectedClient.length === 0 ? this.getMenuByDiet : this.getMenuByClient, this.toastManager)
        } else {
            if (selectedEntryId === null) {
                const createEntryRequest = {
                    meals: selectedEntryMeals,
                    entryType: selectedEntryType,
                    menuId: selectedMenuId,
                    dishes: selectedEntryDishes
                }
                this.props.createEntry(createEntryRequest, selectedClient.length === 0 ? this.getMenuByDiet : this.getMenuByClient, this.toastManager)
            } else {
                const patchEntryRequest = {
                    meals: selectedEntryMeals,
                    dishes: selectedEntryDishes
                }
                this.props.updateEntry(selectedEntryId, patchEntryRequest, selectedClient.length === 0 ? this.getMenuByDiet : this.getMenuByClient, this.toastManager)
            }
        }
        this.closeAddMealModal()
    }

    openDeleteMenuDialog = (menu) => {
        this.setState({ openDialogDelete: true, menuDeleteId: menu.id })
    }

    closeDialogDelete = () => {
        this.setState({ openDialogDelete: false })
    }

    deleteMenu = () => {
        axios.delete(`/v1/menu/${this.state.menuDeleteId}`)
            .then(response => {
                this.toastManager.add(this.props.t("menu_deleted_success"), {
                    appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
                })
                this.closeDialogDelete()
                this.state.selectedClient.length === 0 ? this.getMenuByDiet() : this.getMenuByClient()
            })
            .catch(error => {
                this.toastManager.add(this.props.t("error"), {
                    appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
                })
            })
    }

    openMealWithId = () => {
        store.dispatch({
            type: SET_LATEST_DIET_NAME,
            payload: {
                diet: this.state.selectedDiet,
                date: this.state.menuDate,
                client: this.state.selectedClient
            }
        })
        this.props.history.push("/admin/meal/" + this.state.selectedMealId)
    }

    openDishWithId = () => {
        store.dispatch({
            type: SET_LATEST_DIET_NAME,
            payload: {
                diet: this.state.selectedDiet,
                date: this.state.menuDate,
                client: this.state.selectedClient
            }
        })
        this.props.history.push("/admin/dish/" + this.state.selectedDishId)
    }

    handleChangeFFQFactor = (e) => {
        const target = e.target;
        const value = target.value;



        let ffqFactor = { ...this.state.ffqFactor };
        ffqFactor = value
        this.setState({ ffqFactor: ffqFactor })
        this.props.menuEditorData.ffqFactor = ffqFactor;
    }

    updateFFQ = () => {
        let requestObject = {
            calendarEntryId: this.state.selectedCalendarEntryId,
            mealId: this.state.infoModalMeal ? this.state.selectedMealId : null,
            dishId: this.state.infoModalDish ? this.state.selectedDishId : null,
            ffqFactor: Number(this.state.ffqFactor)
        }
        this.props.updateFFQFactor(requestObject, this.toastManager)
        this.state.infoModalMeal ? this.closeInfoModalMeal() : this.closeInfoModalDish()
        this.setState({ ffqFactor: 1 })
    }

    render() {

        const { selectedEntryMeals, startDay, endDay, addMealModalOpen, copyWeekModalOpen, selectedDateForPicker, isLoading, selectedDiet, selectedDietForCopy, selectedEntryDishes, copyPerDayModalOpen, selectedDateForCopyPerDay, openDialog, openDialogDelete, infoModalDish, infoModalMeal, client, selectedClient, macronutrientData, selectedClientForCopyPerDay, selectedDateForCopy } = this.state;
        const { meals, menuByDiet, dishes, ffqFactor, diet, dishNormatives, mealNormatives, clients, clientsForCopy, modalIssue, nutrientsOutOfBounds } = this.props.menuEditorData;
        const { diets } = this.props.userData;
        const { usingClientSpecificMenus } = this.props.auth;
        const { t } = this.props;
        const { menuAllergens } = this.props.allergenData;
        const { menuFodmaps } = this.props.fodmapData;



        let addMealModal = <DraggableModal
            isOpen={addMealModalOpen}
            toggle={this.closeAddMealModal}
            size={"lg"}
            title={<strong>{t("set_meal")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("meals")}</Label>
                            <Typeahead
                                options={meals}
                                selected={selectedEntryMeals.length > 0 ? selectedEntryMeals : []}
                                onChange={this.handleChangeMeal}
                                id="meal"
                                labelKey="name"
                                placeholder={t("choose_a_meal")}
                                clearButton
                                multiple
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("dishes")}</Label>
                            <Typeahead
                                options={dishes}
                                selected={selectedEntryDishes.length > 0 ? selectedEntryDishes : []}
                                onChange={this.handleChangeDishes}
                                id="dish"
                                labelKey="name"
                                placeholder={t("choose_a_dish")}
                                clearButton
                                multiple
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row></div>}
            footer={<Button color="success" onClick={() => this.handleSubmit()}>{t("set")}</Button>}
            footerStyle={{ justifyContent: 'flex-end' }}
        />

        let infoDetailsDish = null
        if (dishNormatives.length > 0) {
            infoDetailsDish = <React.Fragment>
                <Table>
                    <thead>
                        <tr>
                            <th><strong>{t("food")}</strong></th>
                            <th><strong>{t("quantity")}</strong></th>
                            <th><strong>{t("unit")}</strong></th>
                            <th><strong>{t("grams_by_piece")}</strong></th>
                            <th><strong>{t("preparation_type")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {dishNormatives.map((data, index) => {
                            return <tr key={index}>
                                <td>
                                    {data.foodName}
                                </td>
                                <td>
                                    {data.quantity}
                                </td>
                                <td>
                                    {t(data.unitOfMeasurementWithSymbol.unitOfMeasurement)}
                                </td>
                                <td>
                                    {data.gramsByPiece}
                                </td>
                                <td>
                                    {t(data.preparationType)}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table >
            </React.Fragment>
        }

        const withValueCap = (inputObj) => {
            const { value } = inputObj;
            if (value <= 10) return true;
            return false;
        };

        const modalInfoDish = <DraggableModal
            isOpen={infoModalDish}
            toggle={this.closeInfoModalDish}
            size={"md"}
            title={<strong>{t("details_dish")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {infoDetailsDish}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{ float: 'right' }} size="sm" className="btn-icon btn-round" color="success" onClick={() => this.openDishWithId()}><i className="fas fa-pencil-alt"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={6}>
                        <FormGroup className={'mw-250'}>
                            <Label>{t("FFQ Factor")}</Label>
                            <div style={{ display: 'inline-flex' }}>
                                <NumberFormat className="form-control" name="ffqFactor" onChange={this.handleChangeFFQFactor} value={ffqFactor} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} isAllowed={withValueCap} />
                                <Button style={{ marginLeft: '10px' }} size="sm" className="btn-icon btn-round" color="success" onClick={() => this.updateFFQ()}><i className="fas fa-save"></i></Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </div>}
            modalStyle={{ maxWidth: '800px' }}
        />

        let infoMealData = null
        if (mealNormatives.length > 0) {
            infoMealData = <React.Fragment>
                {mealNormatives.map((meal, index) => {
                    return <div key={index}><span>{t("dish")}{": "}<strong>{meal.name}</strong></span>
                        <Table>
                            <thead>
                                <tr>
                                    <th><strong>{t("food")}</strong></th>
                                    <th><strong>{t("quantity")}</strong></th>
                                    <th><strong>{t("unit")}</strong></th>
                                    <th><strong>{t("grams_by_piece")}</strong></th>
                                    <th><strong>{t("preparation_type")}</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                                {meal.foods.map((data, index) => {
                                    return <tr key={index}>
                                        <td>
                                            {data.foodName}
                                        </td>
                                        <td>
                                            {data.quantity}
                                        </td>
                                        <td>
                                            {t(data.unitOfMeasurementWithSymbol.unitOfMeasurement)}
                                        </td>
                                        <td>
                                            {data.gramsByPiece}
                                        </td>
                                        <td>
                                            {t(data.preparationType)}
                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                        <hr /></div>
                })}
            </React.Fragment>
        }

        const modalInfoMeal = <DraggableModal
            isOpen={infoModalMeal}
            toggle={this.closeInfoModalMeal}
            size={"md"}
            title={<strong>{t("details_meal")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {infoMealData}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button style={{ float: 'right' }} size="sm" className="btn-icon btn-round" color="success" onClick={() => this.openMealWithId()}><i className="fas fa-pencil-alt"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={6}>
                        <FormGroup className={'mw-250'}>
                            <Label>{t("FFQ Factor")}</Label>
                            <div style={{ display: 'inline-flex' }}>
                                <NumberFormat className="form-control" name="ffqFactor" onChange={this.handleChangeFFQFactor} value={ffqFactor} autoComplete="off" allowedDecimalSeparators={[",", "."]} allowNegative={false} decimalScale={2} isAllowed={withValueCap} />
                                <Button style={{ marginLeft: '10px' }} size="sm" className="btn-icon btn-round" color="success" onClick={() => this.updateFFQ()}><i className="fas fa-save"></i></Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '800px' }}
        />

        let copyWeekModal = <DraggableModal
            isOpen={copyWeekModalOpen}
            toggle={this.closeCopyWeekModal}
            size={"sm"}
            title={<strong>{t("copy_meal_per_week")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("select_diet")}</Label>
                            <Typeahead
                                selected={diets && diets.filter(d => d.name === selectedDietForCopy.name)}
                                id="diet"
                                labelKey="name"
                                clearButton
                                onChange={this.handleChangeDietForCopy}
                                options={diets}
                                placeholder={t("select_diet")}
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {selectedDietForCopy && usingClientSpecificMenus && <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("select_client")}</Label>
                            <Typeahead
                                selected={clientsForCopy && clientsForCopy.filter(c => c.username === selectedClientForCopyPerDay.username)}
                                id="client"
                                clearButton
                                labelKey={option => `${option.firstName} ${option.lastName}`}
                                onChange={this.handleChangeClientForCopyPerDay}
                                options={clientsForCopy}
                                placeholder={t("select_client")}
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col className={'mw-180'}>
                        <FormGroup>
                            <Label>{t("start_date")}</Label>
                            <Input type="text" value={this.state.startDayForCopy.format('DD.MM.YYYY')} readOnly></Input>
                        </FormGroup>
                    </Col>
                    <Col className={'mw-100'}>
                        <Label>{t("date")}</Label>
                        <FormGroup className={'datepicker-wrapper'}>
                            <DatePicker
                                selected={selectedDateForCopy}
                                onChange={this.handleChangeDateForCopy}
                                dateFormat="DD.MM.yyyy."
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                placeholderText={t("date")}
                                customInput={
                                    <Button className="btn-icon btn-round" color="info" size="sm">
                                        <i className="fas fa-calendar" />
                                    </Button>}
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup className={'mw-150'}>
                            <Label>{t("end_date")}</Label>
                            <Input type="text" value={this.state.endDayForCopy.format('DD.MM.YYYY')} readOnly></Input>
                        </FormGroup>
                    </Col>
                </Row></div>}
            footer={<Button disabled={selectedDietForCopy === '' || this.state.startDayForCopy === null || this.state.startDayForCopy === undefined} color="info" size="sm" onClick={() => this.openDialog()}>{t("copy_week")}</Button>}
        />

        const openAreYouSureModal = <DraggableModal
            isOpen={openDialog}
            toggle={this.closeModalDialog}
            size={"sm"}
            title={<strong>{t("copy_meal_per_week")}</strong>}
            body={t("message_copy_week")}
            footer={
                <div>
                    <Button color="warning" onClick={this.closeModalDialog}>{t("cancel")}</Button>{' '}
                    <Button color="success" onClick={this.handleCopyWeek}>{t("yes")}</Button>
                </div>
            }
            className={this.props.className}
        />


        let disabledCopyPerDayButton = selectedDateForCopyPerDay && selectedDietForCopy === ''
        let copyPerDayModal = <DraggableModal
            isOpen={copyPerDayModalOpen}
            toggle={this.closeCopyPerDayModal}
            size={"sm"}
            title={<strong>{t("copy_meal_per_day")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("select_diet")}</Label>
                            <Typeahead
                                selected={diets && diets.filter(d => d.name === selectedDietForCopy.name)}
                                id="diet"
                                labelKey="name"
                                clearButton
                                onChange={this.handleChangeDietForCopy}
                                options={diets}
                                placeholder={t("select_diet")}
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {selectedDietForCopy && usingClientSpecificMenus && <Row>
                    <Col>
                        <FormGroup>
                            <Label>{t("select_client")}</Label>
                            <Typeahead
                                selected={clientsForCopy&& clientsForCopy.filter(c => c.username === selectedClientForCopyPerDay.username)}
                                id="client"
                                clearButton
                                labelKey={option => `${option.firstName} ${option.lastName}`}
                                onChange={this.handleChangeClientForCopyPerDay}
                                options={clientsForCopy}
                                placeholder={t("select_client")}
                                paginationText={t("display_additional")}
                                emptyLabel={t("no_matches_found")}
                            />
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <FormGroup className={'datepicker-wrapper mw-150'}>
                            <Label>{t("date")}</Label>
                            <DatePicker
                                className={"form-control"}
                                selected={selectedDateForCopyPerDay}
                                onChange={this.handleChangeDateForCopyPerDay}
                                dateFormat="DD.MM.yyyy."
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </FormGroup>
                    </Col>
                </Row></div>}
            footer={<Button disabled={disabledCopyPerDayButton} color="info" size="sm" onClick={() => this.handleCopyPerDay()}>{t("copy_day")}</Button>}
        />

        let nutrientsOutOfBoundsTable = null
        if (nutrientsOutOfBounds.length > 0) {
            nutrientsOutOfBoundsTable = <React.Fragment>
                <Table>
                    <thead>
                        <tr>
                            <th><strong>{t("nutrient_type")}</strong></th>
                            <th><strong>{t("value_now")}</strong></th>
                            <th><strong>{t("diet_from")}</strong></th>
                            <th><strong>{t("diet_to")}</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {nutrientsOutOfBounds.map((data, index) => {
                            return <tr key={index}>
                                <td>
                                    {t(data.nutrientTypeWithUnit.nutrientType) + " (" + data.nutrientTypeWithUnit.unit + ")"}
                                </td>
                                <td>
                                    {data.value}
                                </td>
                                <td>
                                    {data.dietFrom}
                                </td>
                                <td>
                                    {data.dietTo}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table >
            </React.Fragment>
        }

        let issueModal = <DraggableModal
            isOpen={modalIssue}
            toggle={this.closeModalIssue}
            size={"sm"}
            title={<strong>{t("issue_diet")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {nutrientsOutOfBoundsTable}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        const openModalDelete = <DraggableModal
            isOpen={openDialogDelete}
            toggle={this.closeDialogDelete}
            size={"sm"}
            title={<strong>{t("delete_menu")}</strong>}
            body={t("menu_message_delete")}
            footer={
                <div>
                    <Button color="warning" onClick={this.closeDialogDelete}>{t("cancel")}</Button>{' '}
                    <Button color="success" onClick={() => this.deleteMenu()}>{t("yes")}</Button>
                </div>
            }
            className={this.props.className}
        />

        let issueAllergenModal = <DraggableModal
            isOpen={this.state.modalAllergen}
            toggle={this.toggleModalAllergen}
            size={"sm"}
            title={<strong>{t("issue_allergen")}</strong>}
            body={<div>
                <Row>
                    <Col>
                        {menuAllergens.length > 0 && <Alert color="warning" style={{ padding: '10px', marginBottom: '2px' }}>
                            {menuAllergens.map((dish, index) => {
                                return dish.foodWithAllergens.length > 0 && <li key={index}>{dish.dishName + t("uses_food") +
                                    dish.foodWithAllergens.map(food => {
                                        return food.foodName + t("allergen_text") + food.allergens.map(a => { return a.name }).join(", ")
                                    }).join(", ")} </li>
                            })}
                        </Alert>}
                        {menuFodmaps.length > 0 && <Alert color="light" style={{ padding: '10px', marginBottom: '2px' }}>
                            {menuFodmaps.map((dish, index) => {
                                return dish.foodWithFodmaps.length > 0 && <li key={index}>{dish.dishName + t("uses_food") +
                                    dish.foodWithFodmaps.map(food => {
                                        return food.foodName + t("fodmap_text") + food.fodmaps.map(a => { return a.name }).join(", ")
                                    }).join(", ")} </li>
                            })}
                        </Alert>}
                    </Col>
                </Row></div>}
            modalStyle={{ maxWidth: '650px' }}
        />

        return (
            <div className="content content-calendar">
                {isLoading ? (
                    <div className="loading-position">
                        <ClipLoader
                            size={60}
                            color={"#e14eca"}
                            loading={isLoading}
                        />
                    </div>
                ) : (
                    <div>
                        <Row>
                            <Col sm={10} md={8} lg={7} xl={6} className={'col-xxl-4 col-wxl-3'}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{t("select_diet")}</Label>
                                                    <span style={{ cursor: "pointer" }} id={"target1"}>
                                                        <Typeahead
                                                            selected={diet !== [] ? selectedDiet === [] ? '' : diets.filter(d => d.name === selectedDiet.name) : diets.filter(d => d === diet)}
                                                            id="diet"
                                                            labelKey="name"
                                                            clearButton
                                                            onChange={this.handleChangeDiet}
                                                            options={diets}
                                                            placeholder={t("select_diet")}
                                                            paginationText={t("display_additional")}
                                                            emptyLabel={t("no_matches_found")}
                                                        />
                                                        {selectedDiet && <UncontrolledTooltip style={{ fontSize: '14px' }} placement="bottom" target={"target1"} className="uTooltip uTooltip-title">
                                                            {selectedDiet.name}
                                                        </UncontrolledTooltip>}</span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            {selectedDiet && usingClientSpecificMenus && <Col sm={10} md={8} lg={7} xl={6} className={'col-xxl-4 col-wxl-3'}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label>{t("select_client")}</Label>
                                                    <Typeahead
                                                        selected={client !== [] ? selectedClient === [] ? '' : clients.filter(c => c.username === selectedClient.username) : clients.filter(c => c.username === client.username)}
                                                        id="client"
                                                        clearButton
                                                        labelKey={option => `${option.firstName} ${option.lastName}`}
                                                        onChange={this.handleChangeClient}
                                                        options={clients}
                                                        placeholder={t("select_client")}
                                                        paginationText={t("display_additional")}
                                                        emptyLabel={t("no_matches_found")}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader tag="h3" style={{ textAlign: 'center' }}>
                                        <Row>
                                            <Col xs={3} sm={2} style={{ textAlign: 'left' }} className="date-picker-wrapper">
                                                <DatePicker
                                                    selected={selectedDateForPicker}
                                                    onChange={this.handleChangeDate}
                                                    dateFormat="DD/MM/YY"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    placeholderText={t("select_a_date")}
                                                    customInput={<CalendarButton selectedDiet={selectedDiet.name} />}
                                                    popperPlacement='right'
                                                />
                                            </Col>
                                            <Col xs={9} sm={8} className={'date-period'}>
                                                <Button disabled={!selectedDiet} onClick={this.handlePreviousWeek} className="btn-icon btn-round" color="info" size="sm" style={{ marginRight: '20px' }}><i className="fas fa-arrow-left" /></Button>
                                                <span>{startDay.format('DD/MM/YY') + " - " + endDay.format('DD/MM/YY')}</span>
                                                <Button disabled={!selectedDiet} onClick={this.handleNextWeek} className="btn-icon btn-round" color="info" size="sm" style={{ marginLeft: '20px' }}><i className="fas fa-arrow-right" /></Button>
                                            </Col>
                                            {menuByDiet.length > 0 && <Col sm={2} style={{ textAlign: 'right' }}>
                                                <Button size="sm" color="info" onClick={() => this.openCopyWeekModal()}>{t('copy_week')}</Button>
                                            </Col>}
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col xs={12}>
                                                <CalendarTable
                                                    menus={menuByDiet}
                                                    startDay={startDay}
                                                    openAddMealModal={selectedDiet ? this.openAddMealModal : () => { }}
                                                    openCopyPerDayModal={this.openCopyPerDayModal}
                                                    menuDetails={this.menuDetails}
                                                    openInfoModalDish={this.openInfoModalDish}
                                                    openInfoModalMeal={this.openInfoModalMeal}
                                                    openDeleteMenuDialog={this.openDeleteMenuDialog}
                                                    history={this.props.history}
                                                    selectedDiet={selectedDiet}
                                                    selectedClient={selectedClient}
                                                    handlePrint={this.handlePrint}
                                                    getAllergensIssue={this.getAllergensIssue}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    {menuByDiet.length > 0 && <CardFooter>
                                        <Col xs={12}>
                                            <Badge style={{ backgroundColor: '#267cadd5', fontSize: '12px' }} pill color="info">{t("meals")}</Badge>
                                            <Badge style={{ backgroundColor: '#fd985dc7', fontSize: '12px' }} pill color="info">{t("dishes")}</Badge>
                                        </Col>
                                    </CardFooter>}
                                    {addMealModal}
                                    {copyWeekModal}
                                    {copyPerDayModal}
                                    {issueModal}
                                    {openAreYouSureModal}
                                    {modalInfoDish}
                                    {modalInfoMeal}
                                    {issueAllergenModal}
                                </Card>
                                {openModalDelete}
                            </Col>
                        </Row>
                        <div style={{ height: '0', overflow: 'hidden', position: 'absolute', zIndex: '-5000', top: '0', left: '0' }}>
                            <Row>
                                <Col align={'center'}>
                                    <div className={'chart-container'}>
                                        <Pie
                                            id="chart"
                                            data={macronutrientData.macronutrientChartData}
                                            width={500} height={350} legend={{ position: 'bottom' }} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style={{ height: '0', overflow: 'hidden', position: 'absolute', zIndex: '-5000', top: '0', left: '0' }}>
                            <Row>
                                <Col align={'center'}>
                                    <div className={'chart-container'} >
                                        <Bar
                                            id="bar"
                                            data={macronutrientData && macronutrientData.macronutrientBarChart}
                                            width={500} height={350} legend={{ position: 'bottom' }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </div >
        );
    }
}

MenuEditor.propTypes = {
    menuEditorData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    menuEditorData: state.menuEditorData,
    userData: state.userData,
    auth: state.auth,
    allergenData: state.allergenData,
    fodmapData: state.fodmapData
})

const mapActionsToProps = { getMeals, createMenu, createEntry, updateEntry, getDiets, getMenuByDiet, getDishes, exportDailyDishData, getDishNormatives, getMealNormatives, getClientsByDiet, getMenuByClient, getClientsByDietForCopyPerDay, getNutrientsOutOfBounds, updateFFQFactor, getAllergensIssue, getFodmapsIssue }

export default connect(mapStateToProps, mapActionsToProps)(withToastManager(withTranslation()(MenuEditor)))