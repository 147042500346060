import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardTitle, CardFooter, Row, Col } from "reactstrap";

class DashboardCard extends Component {

    render() {

        const { id, title, total, iconClass, footerText, path, isAdmin, isSuperAdmin, pathTotal } = this.props;

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            <div className="icon-wrapper" style={{ width: '90px' }}>
                                <i className={iconClass}></i>
                            </div>
                        </Col>
                        <Col style={{ textAlign: 'right', paddingTop: '15px' }}>
                            <h5 className="card-category">{title}</h5>
                            <CardTitle tag="h2">
                                <Link to={pathTotal}
                                >{total}</Link>

                            </CardTitle>
                        </Col>
                    </Row>
                </CardHeader>
                <CardFooter style={{ background: '#f7f7f7' }}>
                    <Row>
                        <Col xs={9} md={9}>
                            <div className="custom-card-footer">
                                <span>{footerText}</span>
                            </div>
                        </Col>
                        <Col xs={3} md={3}>
                            <div style={{ textAlign: 'right', margin: '4px 10px 0 0' }}>
                                {(id === 'client-card' && !isSuperAdmin && !isAdmin) ? (
                                    <i className="fas fa-arrow-circle-right fa-2x"></i>
                                ) : (
                                    <Link to={path}>
                                        <i className="fas fa-arrow-circle-right fa-2x"></i>
                                    </Link>
                                )}
                            </div>
                        </Col>
                    </Row>
                </CardFooter>
            </Card>
        )
    }
}

DashboardCard.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    pathTotal: PropTypes.string.isRequired,
    iconClass: PropTypes.string.isRequired,
    footerText: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSuperAdmin: PropTypes.bool.isRequired
}


export default DashboardCard;