import {
    SET_DIAGNOSES,
    CREATE_DIAGNOSIS,
    DELETE_DIAGNOSIS
} from '../types';
import axios from 'axios';
import i18next from 'i18next';

export const createDiagnosis = (toastManager, newDiagnosis) => (dispatch) => {
    axios.post('/v1/diagnosis', newDiagnosis)
        .then(response => {
            dispatch({
                type: CREATE_DIAGNOSIS,
                payload: response.data.diagnosis
            });
            toastManager.add(i18next.t("diagnosis_created_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const updateDiagnosis = (toastManager, id, updatedDiagnosis) => (dispatch) => {
    axios.patch(`/v1/diagnosis/${id}`, updatedDiagnosis)
        .then(response => {
            toastManager.add(i18next.t("diagnosis_updated_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            });
        })
        .catch(error => {
            toastManager.add(i18next.t("unable_perform_action"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}

export const getDiagnoses = (page, size) => (dispatch) => {
    axios.get(`/v1/diagnosis?page=${page}&size=${size}`)
        .then(response => {
            dispatch({
                type: SET_DIAGNOSES,
                payload: response.data.diagnoses
            })
        })
        .catch(error => {
            dispatch({
                type: SET_DIAGNOSES,
                payload: []
            })
        })
}

export const searchDiagnosis = (page, size, search) => (dispatch) => {
    axios.get(`/v1/diagnosis/search?page=${page}&size=${size}&search=${search}`)
        .then(response => {
            dispatch({
                type: SET_DIAGNOSES,
                payload: response.data.diagnoses
            });
        })
        .catch(error => {
            dispatch({
                type: SET_DIAGNOSES,
                payload: []
            })
        })
}

export const deleteDiagnosis = (toastManager, id) => (dispatch) => {
    axios.delete(`v1/diagnosis/${id}`)
        .then(() => {
            dispatch({
                type: DELETE_DIAGNOSIS,
                payload: id
            })
            toastManager.add(i18next.t("diagnosis_deleted_success"), {
                appearance: 'success', autoDismiss: true, autoDismissTimeout: 3000
            })
            dispatch(getDiagnoses(0, 10))
        })
        .catch(error => {
            toastManager.add(i18next.t("error"), {
                appearance: 'error', autoDismiss: true, autoDismissTimeout: 3000
            })
        })
}