import { SET_TOTAL_CLIENTS, SET_TOTAL_MEALS, SET_TOTAL_DIETS, SET_TOTAL_DISHES, SET_CLIENTS_WITHOUT_MEAL, SET_LATEST_CLIENTS, SET_LATEST_MEALS, SET_UNAPPROVED_FOODS, SET_UNAPPROVED_DISHES, SET_UNAPPROVED_DIETS, SET_UNAPPROVED_MEALS } from '../types';

const initialState = {
    totalClients: null,
    totalMeals: null,
    totalDiets: null,
    totalDishes: null,
    clientsWithoutMeal: [],
    sizeClientsWithoutMeal: 0,
    totalElementsClientsWithoutMeal: 0,
    latestClients: [],
    latestMeals: [],
    totalElementsLatestClients: 0,
    sizeLatestClients: 0,
    unapprovedFoods: [],
    unapprovedDishes: [],
    unapprovedDiets: [],
    unapprovedMeals: [],
    sizeUnapprovedFoods: 0,
    totalElementsUnapprovedFoods: 0,
    sizeUnapprovedDishes: 0,
    totalElementsUnapprovedDishes: 0,
    sizeUnapprovedDiets: 0,
    totalElementsUnapprovedDiets: 0,
    sizeUnapprovedMeals: 0,
    totalElementsUnapprovedMeals: 0
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_TOTAL_CLIENTS:
            return {
                ...state,
                totalClients: action.payload
            }
        case SET_TOTAL_MEALS:
            return {
                ...state,
                totalMeals: action.payload
            }
        case SET_TOTAL_DIETS:
            return {
                ...state,
                totalDiets: action.payload
            }
        case SET_TOTAL_DISHES:
            return {
                ...state,
                totalDishes: action.payload
            }
        case SET_CLIENTS_WITHOUT_MEAL:
            return {
                ...state,
                clientsWithoutMeal: action.payload.content,
                sizeClientsWithoutMeal: action.payload.size,
                totalElementsClientsWithoutMeal: action.payload.totalElements
            }
        case SET_LATEST_CLIENTS:
            return {
                ...state,
                latestClients: action.payload.content,
                sizeLatestClients: action.payload.size,
                totalElementsLatestClients: action.payload.totalElements
            }
        case SET_UNAPPROVED_FOODS:
            return {
                ...state,
                unapprovedFoods: action.payload.content,
                sizeUnapprovedFoods: action.payload.size,
                totalElementsUnapprovedFoods: action.payload.totalElements
            }
        case SET_UNAPPROVED_DISHES:
            return {
                ...state,
                unapprovedDishes: action.payload.content,
                sizeUnapprovedDishes: action.payload.size,
                totalElementsUnapprovedDishes: action.payload.totalElements
            }
        case SET_UNAPPROVED_DIETS:
            return {
                ...state,
                unapprovedDiets: action.payload.content,
                sizeUnapprovedDiets: action.payload.size,
                totalElementsUnapprovedDiets: action.payload.totalElements
            }
        case SET_UNAPPROVED_MEALS:
            return {
                ...state,
                unapprovedMeals: action.payload.content,
                sizeUnapprovedMeals: action.payload.size,
                totalElementsUnapprovedMeals: action.payload.totalElements
            }
        case SET_LATEST_MEALS:
            return {
                ...state,
                latestMeals: action.payload
            }
        default:
            return state;
    }
}
